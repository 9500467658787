import React, { useContext } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import BlockUserList from "../block-user-list/BlockUserList";
import DataBundleLog from "../data-bundle-log/DataBundleLog";
import Home from "../home/Home";
import AddUser from "../manage-access/AddUser";
import ViewUser from "../manage-access/ViewUser";
import CreateRoles from "../manage-roles/CreateRoles";
import ViewRoles from "../manage-roles/ViewRoles";
import KYBRequest from "../onboarding-request/kyb-request/KYBRequest";
import KYCRequest from "../onboarding-request/kyc-request/KYCRequest";
import StoreManagement from "../store-management/StoreManagement";
import FIBRequests from "../banking-log/FIBRequests";
import Tickets from "../tickets/Tickets";
import Transactions from "../transactions/Transactions";
import ForgotPassword from "../user-forms/ForgotPassword";
import Login from "../user-forms/Login";
import ResetPassword from "../user-forms/ResetPassword";
import Verify from "../user-forms/Verify";
import Users from "../users/Users";
import { AuthContext } from "../../context/AuthContext";
import CreateTicket from "../tickets/CreateTicket";
import Ticketdetail from "../tickets/Ticketdetail";
import UserDetail from "../users/UserDetail";
import TransactionDetail from "../transactions/TransactionDetail";
import UpdateStoreDetails from "../store-management/UpdateStoreDetails";
import StoreDetail from "../store-management/StoreDetail";
import KYBDetail from "../onboarding-request/kyb-request/KYBDetail";
import EKYCParcialRequest from "../onboarding-request/eKyc-request/EKYCParcialRequest";
import EKYCDetail from "../onboarding-request/eKyc-request/EKYCDetail";
import EKYCPendingRequest from "../onboarding-request/eKyc-request/EKYCPendingRequest";
import EKYCDeclinedRequest from "../onboarding-request/eKyc-request/EKYCDeclinedRequest";
import EKYCApprovedRequest from "../onboarding-request/eKyc-request/EKYCApprovedRequest";
import EKYCDigitalRequest from "../onboarding-request/eKyc-request/EKYCDigitalRequest";
import UserList from "../users/UserList";
import NoMatch from "../NoMatch";
import KYCDetail from "../onboarding-request/kyc-request/KYCDetail";
import UpdateRole from "../manage-roles/UpdateRole";
import UserLogDetail from "../manage-access/UserLogDetail";
import Test from "../Test";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PulseLoader from "react-spinners/PulseLoader";

import { makeStyles } from "@mui/styles";
import EKYCAgentVerifiedRequest from "../onboarding-request/eKyc-request/EKYCAgentVerifiedRequest";
import AdjustmentList from "../adjustment/AdjustmentList";
import ZichargeAgentList from "../zicharge/ZichargeAgentList";
import PhysicalDelinedKYC from "../onboarding-request/eKyc-request/PhysicalDelinedKYC";
import CustomerReport from "../users/CustomerReport";
import MerchantList from "../merchant-onboarding/MerchantList";
import CreateMerchant from "../merchant-onboarding/CreateMerchant";
import POSList from "../merchant-onboarding/POSList";
import SendNotification from "../banking-log/SendNotification";
import NotificationList from "../banking-log/NotificationList";
import UpdateMerchant from "../merchant-onboarding/UpdateMerchant";
const useStyles = makeStyles((theme) => ({
  dialogStyle: {
    // backgroundColor: "red",
    "& .MuiDialog-paper": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    // transparent
  },
}));

function PrivateRoute({ children }) {
  const { fastpay_support_panel_user } = useContext(AuthContext);

  return fastpay_support_panel_user.token ? children : <Navigate to="/" />;
}
function RedirectToHome({ children }) {
  const { fastpay_support_panel_user } = useContext(AuthContext);

  return !fastpay_support_panel_user.token ? children : <Navigate to="/home" />;
}
const Navigation = ({
  rollPermission,
  setRollPermission,
  openLoadingDialog,
  setOpenLoadingDialog,
}) => {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpenLoadingDialog(true);
  };

  const handleClose = () => {
    setOpenLoadingDialog(false);
  };
  return (
    <div>
      <Dialog
        open={openLoadingDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogStyle}
      >
        <DialogContent>
          <PulseLoader color={"black"} size={10} speedMultiplier={0.5} />{" "}
        </DialogContent>
      </Dialog>
      <Routes>
        {/* <Route path="/test" element={<Test />} /> */}
        <Route
          path="/"
          element={
            <RedirectToHome>
              <Login />
            </RedirectToHome>
          }
        />
        <Route
          path="verify"
          element={
            <RedirectToHome>
              <Verify setRollPermission={setRollPermission} />
            </RedirectToHome>
          }
        />
        <Route
          path="home"
          element={
            <PrivateRoute>
              <Home rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route
          path="change-password"
          element={
            <PrivateRoute>
              <ResetPassword rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="tickets"
          element={
            <PrivateRoute>
              <Tickets rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="tickets/:id"
          element={
            <PrivateRoute>
              <Ticketdetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="create-ticket"
          element={
            <PrivateRoute>
              <CreateTicket rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="create-ticket/:slug"
          element={
            <PrivateRoute>
              <CreateTicket rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="customer-report"
          element={
            <PrivateRoute>
              <CustomerReport rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="search-user"
          element={
            <PrivateRoute>
              <Users rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="user/:slug"
          element={
            <PrivateRoute>
              <UserDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="user-list"
          element={
            <PrivateRoute>
              <UserList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />

        <Route
          path="block-user-list"
          element={
            <PrivateRoute>
              <BlockUserList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="transactions"
          element={
            <PrivateRoute>
              <Transactions rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="transactions/:slug"
          element={
            <PrivateRoute>
              <TransactionDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="data-bundle-log"
          element={
            <PrivateRoute>
              <DataBundleLog rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="kyc-request"
          element={
            <PrivateRoute>
              <KYCRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}
        {/* ====================Ekyc Routes Start ============================= */}

        {/* <Route
          path="kyc-partial-request"
          element={
            <PrivateRoute>
              <EKYCParcialRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="kyc-manual-request"
          element={
            <PrivateRoute>
              <EKYCPendingRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="kyc-declined-request"
          element={
            <PrivateRoute>
              <EKYCDeclinedRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="kyc-Approved-request"
          element={
            <PrivateRoute>
              <EKYCApprovedRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="kyc-digital-request"
          element={
            <PrivateRoute>
              <EKYCDigitalRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="kyc-agent-verified-request"
          element={
            <PrivateRoute>
              <EKYCAgentVerifiedRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="declined-physical-kyc"
          element={
            <PrivateRoute>
              <PhysicalDelinedKYC rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />

        <Route
          path="kyc-details/:slug"
          element={
            <PrivateRoute>
              <EKYCDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}

        {/* ====================Ekyc Routes End ============================= */}
        {/* <Route
          path="kyc-detail/:slug"
          element={
            <PrivateRoute>
              <KYCDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}

        {/* <Route
          path="kyb-request"
          element={
            <PrivateRoute>
              <KYBRequest rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="kyb-details/:slug"
          element={
            <PrivateRoute>
              <KYBDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="create-role"
          element={
            <PrivateRoute>
              <CreateRoles rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="update-role/:slug"
          element={
            <PrivateRoute>
              <UpdateRole rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="view-role"
          element={
            <PrivateRoute>
              <ViewRoles rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="add-user"
          element={
            <PrivateRoute>
              <AddUser rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="view-user"
          element={
            <PrivateRoute>
              <ViewUser rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="create-merchant"
          element={
            <PrivateRoute>
              <CreateMerchant rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="update-merchant/:id"
          element={
            <PrivateRoute>
              <UpdateMerchant rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="merchant-list"
          element={
            <PrivateRoute>
              <MerchantList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="pos-list/:id"
          element={
            <PrivateRoute>
              <POSList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="view-log-detail/:slug"
          element={
            <PrivateRoute>
              <UserLogDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="store-management"
          element={
            <PrivateRoute>
              <StoreManagement rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="fib-requests"
          element={
            <PrivateRoute>
              <FIBRequests rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="send-message"
          element={
            <PrivateRoute>
              <SendNotification rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />

        <Route
          path="notification-list"
          element={
            <PrivateRoute>
              <NotificationList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="update-store-detail/:slug"
          element={
            <PrivateRoute>
              <UpdateStoreDetails rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        <Route
          path="store-detail/:slug"
          element={
            <PrivateRoute>
              <StoreDetail rollPermission={rollPermission} />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="adjustment-list"
          element={
            <PrivateRoute>
              <AdjustmentList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="zicharge-agent-list"
          element={
            <PrivateRoute>
              <ZichargeAgentList rollPermission={rollPermission} />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="*"
          element={
            !fastpay_support_panel_user.token ? (
              <Navigate to="/" />
            ) : (
              <NoMatch />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default Navigation;
