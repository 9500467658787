import React, { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthContext } from "../../context/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:nth-child(-n+3)": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td:nth-child(-n+3)": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
  tableHeadStyle2: {
    // background: "#2B335E",
    "& th": {
      // color: "#fff",
      fontSize: "20px",
      borderBottom: "2px solid #353b48",
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const RefundSection = ({
  rollPermission,
  allTransactions,
  row,
  index,
  setRefresh,
  refresh,
}) => {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [senderData, setSenderData] = useState({});

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefund = async () => {
    setLoading(true);
    try {
      let data = {
        transaction_id: senderData.id,
      };
      let response = await axios({
        url: "api/refund-transaction",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        handleSnakbarOpen(response.data.messages.toString(), "success");
        let newRow = { ...row, is_refunded: 1, show_refund_button: 0 };
        allTransactions[index] = newRow;
        setOpen(false);
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <>
      {rollPermission?.includes("Refund transaction") && (
        <Button
          variant="contained"
          disableElevation
          fullWidth
          style={{ minWidth: "120px" }}
          onClick={() => {
            setSenderData({
              id: row.transaction_id,
              sender: row.transaction_id,
            });
            setOpen(true);
          }}
        >
          Refund
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <div>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to refund this transaction#{" "}
              <span
                style={{
                  background: "#dbdbdb",
                  padding: "5px 10px",
                  display: "inline-block",
                  borderRadius: "10px",
                }}
              >
                <b>{senderData.sender}</b>
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ paddingRight: "24px" }}>
            <Button onClick={handleClose}>cancel</Button>
            <Button
              variant="contained"
              disableElevation
              disabled={loading}
              onClick={handleRefund}
            >
              {loading === false && "Confirm"}
              <PulseLoader
                color={"#00848A"}
                loading={loading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
            </Button>
          </DialogActions>{" "}
        </div>
      </Dialog>
    </>
  );
};

export default RefundSection;
