import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import TablePagination from "@mui/material/TablePagination";
import { getDataWithToken } from "../../../services/GetDataService";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CalendarPicker from "@mui/lab/CalendarPicker";
import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableHeadStyle: {
    // background: "#00848A",
    "& th": {
      // color: "#fff",
      fontSize: "16px",
      fontWeight: "600 !important",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tableBodyStyle: {
    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
}));

export default function KYCBasicInformation({
  basicInformation,
  loading,
  userStatus,
  setUserStatus,
  level,
  levelId,
  setLevelId,
  userInfoMessage,
  rollPermission,
  // blockFeatureList,
  // setblockFeatureList,
}) {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [statusLoading, setStatusLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [levelLoading, setLevelLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [unblockListLoading, setUnlockListLoading] = useState(false);
  const [unblockLoading, setUnblockLoading] = useState("");
  const [blockHistory, setBlockHistory] = useState([]);
  const [open, setOpen] = useState(false);
  const [levelDialog, setLevelDialog] = useState(false);
  const [blockDialog, setBlockDialog] = useState(false);
  const [unblockDialog, setUnblockDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage] = useState("");
  const [blockedDate, setBlockedDate] = useState(new Date());
  const [unblockedDate, setUnblockedDate] = useState(new Date());
  const [temporaryLevelId, setTemporaryLevelId] = useState("");
  const [blockFeatureList, setblockFeatureList] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    setReason("");
  };
  const handleLevelClose = () => {
    setLevelDialog(false);
  };
  const handleBlockClose = () => {
    setBlockDialog(false);
  };
  const handleUnBlockClose = () => {
    setUnblockDialog(false);
    setPage(0);
  };

  const handleBlockFeatureChange = (event) => {
    let newBlockFeatureList = blockFeatureList.map((item) => {
      if (event.target.value == item.id) {
        return { ...item, checked: event.target.checked };
      } else {
        return item;
      }
    });
    setblockFeatureList(newBlockFeatureList);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const unblockTableLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const onLevelChange = async (e) => {
    setLevelLoading(true);
    try {
      let data = {
        user_id: basicInformation.user_id,
        level: temporaryLevelId,
      };
      let response = await axios({
        url: "api/customer-level-update",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      handleSnakbarOpen(response.data.messages.toString(), "success");
      if (response.data.code === 200) {
        setLevelId(temporaryLevelId);
      }
      setLevelDialog(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setLevelLoading(false);
    }
    setLevelLoading(false);
  };
  const onBlockSubmit = async (e) => {
    setBlockLoading(true);
    try {
      let newBlockedAt = moment(blockedDate).format("YYYY-MM-DD 00:00:00");
      let newUnlockedAt = moment(unblockedDate).format("YYYY-MM-DD 23:59:59");

      let allFeatureId = [];

      blockFeatureList.map((item) => {
        if (item.checked) {
          allFeatureId.push(item.id);
        }
      });
      if (allFeatureId.length < 1) {
        handleSnakbarOpen("Please select block features", "error");
      } else {
        let data = {
          mobile_number: basicInformation.mobile_number,
          feature_id: JSON.stringify(allFeatureId),
          blocked_at: newBlockedAt,
          unblock_at: newUnlockedAt,
        };
        let response = await axios({
          url: "api/block-user",
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        handleSnakbarOpen(response.data.messages.toString(), "success");
        if (response.data.code === 200) {
          setBlockDialog(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setBlockLoading(false);
    }
    setBlockLoading(false);
  };
  const onStatusChange = async (e) => {
    setStatusLoading(true);
    try {
      let statusValue = 1;
      if (userStatus) {
        statusValue = 0;
      }

      let data = {
        user_id: basicInformation.user_id,
        status: statusValue,
        remarks: reason,
      };

      let response = await axios({
        url: "api/customer-status-update",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      handleSnakbarOpen(response.data.messages.toString(), "success");
      if (response.data.code === 200) {
        setUserStatus(!userStatus);
      }
      setOpen(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setStatusLoading(false);
    }
    setStatusLoading(false);
  };
  const getBlockHistory = async (pageNO) => {
    setUnblockDialog(true);
    setUnlockListLoading(true);

    let newPageNO;

    if (pageNO) {
      newPageNO = pageNO;
    } else {
      newPageNO = 1;
    }

    let allBlockHistory = await getDataWithToken(
      `api/block-history-by-user?mobile_number=${encodeURIComponent(
        basicInformation.mobile_number
      )}&page=${newPageNO}`
    );

    if (allBlockHistory.data.code === 200) {
      setBlockHistory(allBlockHistory.data.data.data);
      setTotalData(allBlockHistory.data.data.total);
      if (allBlockHistory.data.data.data.length < 1) {
        setMessage("No data found");
      }
    } else {
      handleSnakbarOpen(allBlockHistory.data.messages.toString(), "error");
      setUnlockListLoading(false);
    }

    if (allBlockHistory.status !== 200) {
      setMessage(allBlockHistory.data.message);
    }

    setUnlockListLoading(false);
  };

  const onUnblockSubmit = async (row, indexNo) => {
    let id = parseInt(row.id);
    setUnblockLoading(id);
    try {
      let data = {
        blocklist_id: id,
      };
      let response = await axios({
        url: "api/unblock-user",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      handleSnakbarOpen(response.data.messages.toString(), "success");
      if (response.data.code === 200) {
        let newObj = { ...row, deleted_at: new Date() };
        blockHistory[indexNo] = newObj;
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUnblockLoading("");
    }
    setUnblockLoading("");
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getBlockHistory(pageNo);
    setPage(newPage);
  };
  const getBlockList = async () => {
    setLoading2(true);
    let blockFeatureResponse = await getDataWithToken(
      `api/user-block-features`
    );

    if (blockFeatureResponse.status === 200) {
      let newList = [];
      blockFeatureResponse.data.data.map((item) =>
        newList.push({ ...item, checked: false })
      );
      setblockFeatureList(newList);
    } else {
      handleSnakbarOpen(blockFeatureResponse.data.messages.toString(), "error");
      setLoading2(false);
    }
    setLoading2(false);
  };
  const pageLoading2 = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <div key={i} style={{ marginBottom: "15px" }}>
          <Skeleton></Skeleton>
        </div>
      );
    }
    return content;
  };
  const showBlockUnblockButton = () => {
    if (
      rollPermission?.includes("Customer block operation") ||
      rollPermission?.includes("Customer unblock operation")
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {userInfoMessage.length > 0 && (
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 600, textAlign: "center" }}
          component="div"
        >
          {userInfoMessage}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            aria-label="simple table"
            style={{ border: "1px solid #dcdde1" }}
          >
            <TableBody className={classes.tableBodyStyle}>
              {loading && pageLoading()}

              {!loading &&
                basicInformation &&
                Object.entries(basicInformation).length !== 0 && (
                  <>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        colSpan={2}
                        style={{ border: 0, padding: "6px 16px 0" }}
                      >
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                            margin: 0,
                          }}
                          gutterBottom
                          component="div"
                          color="primary"
                        >
                          Personal Information
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        {basicInformation?.photo && (
                          <img
                            src={basicInformation?.photo}
                            alt=""
                            style={{
                              maxHeight: "120px",
                              maxWidth: "200px",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography
                          // variant="subtitle1"
                          style={{ fontSize: "20px" }}
                          gutterBottom
                          component="div"
                          color="primary"
                        >
                          {basicInformation?.first_name}{" "}
                          {basicInformation?.last_name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          component="div"
                          color="primary"
                        >
                          {basicInformation?.mobile_number}
                        </Typography>
                        {rollPermission?.includes("View email address") && (
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                            color="primary"
                          >
                            {basicInformation?.email}
                            &nbsp;
                            {basicInformation?.email_verified === 1 ? (
                              <span style={{ color: "#4caf50" }}>
                                ( Verified )
                              </span>
                            ) : (
                              <span style={{ color: "#f50057" }}>
                                ( Unverified )
                              </span>
                            )}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>Type</TableCell>
                      <TableCell>{basicInformation?.type}</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>Level</TableCell>
                      <TableCell>
                        {rollPermission?.includes("Customer level update") ? (
                          <FormControl
                            variant="outlined"
                            size="small"
                            className={classes.centerSelectStyle}
                          >
                            <Select
                              labelId="demo-store-category-outlined-label"
                              // id="demo-store-category-outlined"
                              value={levelId}
                              onChange={(e) => {
                                setLevelDialog(true);

                                setTemporaryLevelId(e.target.value);
                              }}
                            >
                              {level?.map((item, i) => (
                                <MenuItem value={item.id} key={i}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <>{basicInformation.level}</>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>Status</TableCell>
                      <TableCell>{basicInformation?.status}</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>Is KYC Verified</TableCell>
                      <TableCell>
                        <img
                          src={
                            basicInformation?.is_kyc_verified === 1
                              ? "/image/verified.svg"
                              : "/image/unverified.svg"
                          }
                          style={{
                            width: "20px",
                            position: "relative",
                            top: 5,
                          }}
                          alt=""
                        />{" "}
                        &nbsp;
                        {basicInformation?.is_kyc_verified === 1
                          ? "Verified"
                          : "Unverified"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>Is User Active</TableCell>
                      <TableCell>
                        {rollPermission?.includes("Customer status update") ? (
                          <Switch
                            color="success"
                            checked={userStatus}
                            onChange={() => setOpen(true)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        ) : (
                          <>
                            <img
                              src={
                                userStatus
                                  ? "/image/verified.svg"
                                  : "/image/unverified.svg"
                              }
                              style={{
                                width: "20px",
                                position: "relative",
                                top: 5,
                              }}
                              alt=""
                            />{" "}
                            &nbsp;
                            {userStatus ? "Active" : "Inactive"}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    {showBlockUnblockButton() === true && (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>Block/Unblock User</TableCell>
                        <TableCell>
                          {rollPermission?.includes(
                            "Customer block operation"
                          ) && (
                            <>
                              <Button
                                variant="contained"
                                color="error"
                                disableElevation
                                onClick={() => {
                                  setBlockDialog(true);
                                  getBlockList();
                                }}
                              >
                                Block
                              </Button>{" "}
                              &nbsp;&nbsp;
                            </>
                          )}
                          {rollPermission?.includes(
                            "Customer unblock operation"
                          ) && (
                            <Button
                              variant="contained"
                              color="success"
                              disableElevation
                              onClick={() => getBlockHistory(1)}
                            >
                              Unblock
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
            </TableBody>
          </Table>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ padding: "10px", minWidth: "350px" }}>
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You want to change user status
              </DialogContentText>
              <TextField
                id="outlined-multiline-static"
                placeholder="Type the reason"
                multiline
                rows={4}
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>cancel</Button>
              <Button
                variant="contained"
                disabled={statusLoading}
                onClick={onStatusChange}
                style={{ minWidth: "100px" }}
                autoFocus
              >
                <PulseLoader
                  color={"#00848A"}
                  loading={statusLoading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
                {statusLoading === false && "Confirm"}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={levelDialog}
          onClose={handleLevelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ padding: "10px" }}>
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You want to change user level
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLevelClose}>cancel</Button>
              <Button
                variant="contained"
                disabled={levelLoading}
                onClick={onLevelChange}
                style={{ minWidth: "100px" }}
                autoFocus
              >
                <PulseLoader
                  color={"#00848A"}
                  loading={levelLoading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
                {levelLoading === false && "Confirm"}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={blockDialog}
          onClose={handleBlockClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle id="alert-dialog-title">{"Block User"}</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography style={{ fontSize: "18px" }} gutterBottom>
                  Block Features :
                </Typography>
                {loading2 && pageLoading2()}
                {!loading2 &&
                  blockFeatureList?.map((item, i) => (
                    <div key={i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            value={item.id}
                            onChange={handleBlockFeatureChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={item.name}
                      />
                    </div>
                  ))}
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <br />
                  <Typography
                    gutterBottom
                    style={{
                      fontSize: "18px",
                      width: "320px",
                      margin: "auto",
                      paddingLeft: "24px",
                      boxSizing: "border-box",
                    }}
                  >
                    Blocked At :
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ margin: 0, background: "red" }}
                  >
                    <CalendarPicker
                      minDate={new Date()}
                      date={blockedDate}
                      onChange={(newDate) => setBlockedDate(newDate)}
                    />
                  </LocalizationProvider>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <br />
                  <Typography
                    gutterBottom
                    style={{
                      fontSize: "18px",

                      width: "320px",
                      margin: "auto",
                      paddingLeft: "24px",
                      boxSizing: "border-box",
                    }}
                  >
                    Unblocked At :
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ margin: 0 }}
                  >
                    <CalendarPicker
                      minDate={new Date()}
                      date={unblockedDate}
                      onChange={(newDate) => setUnblockedDate(newDate)}
                    />
                  </LocalizationProvider>
                </Paper>{" "}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ paddingBottom: "16px", paddingRight: "24px" }}
          >
            <Button onClick={handleBlockClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={blockLoading}
              onClick={onBlockSubmit}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={blockLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {blockLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={unblockDialog}
          onClose={handleUnBlockClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"lg"}
        >
          <DialogTitle id="alert-dialog-title">
            {" "}
            {"Unblock User"}{" "}
            <IconButton
              onClick={handleUnBlockClose}
              style={{ position: "absolute", right: 20 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell>Features</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Blocked By</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!unblockListLoading &&
                  blockHistory.length > 0 &&
                  blockHistory.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.action}</TableCell>
                      <TableCell>
                        {row.blocked_at} - {row.unblock_at}
                      </TableCell>
                      <TableCell>{row.remarks}</TableCell>

                      <TableCell align="center">
                        {" "}
                        {row.deleted_at === null ? (
                          <Button
                            variant="contained"
                            color="success"
                            disabled={row.id === unblockLoading}
                            onClick={() => onUnblockSubmit(row, i)}
                            style={{ minWidth: "120px" }}
                          >
                            {row.id === unblockLoading ? (
                              <PulseLoader
                                color={"#00848A"}
                                loading={true}
                                size={10}
                                speedMultiplier={0.5}
                              />
                            ) : (
                              "Unblock"
                            )}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            disabled={true}
                            style={{ minWidth: "120px" }}
                            autoFocus
                          >
                            Unblocked
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                {!unblockListLoading && blockHistory.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {unblockListLoading && unblockTableLoading()}
                {blockHistory.length > 0 && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[]}
                      // count={rows.length}
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}
