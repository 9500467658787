import React, { useState, useEffect } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 6px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const Tickets = ({ rollPermission }) => {
  const classes = useStyles();
  const location = useLocation();
  const [mobileNumber, setMobileNumber] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [isAssigned, setIsAssigned] = useState("");
  const [priority, setPriority] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState("");
  const [allTickets, setAllTickets] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [categoryMessage, setCategoryMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell colSpan={2}>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setTicketId("");
    setIsAssigned("");
    setStatus("");
    setPriority("");
    setMobileNumber("");
    setCategory("");
    setCustomerType("");
    setPage(0);
    let newUrl = `api/tickets?id=&is_assigned=&status=&priority=&mobile_number=&issue_category=&customer_type=&page=1`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newIsAssigned = isAssigned;
        let newStatus = status;
        let newPriority = priority;
        let newCustomerType = customerType;
        let newCategory = category;
        if (isAssigned === "None") {
          newIsAssigned = "";
        }
        if (status === "None") {
          newStatus = "";
        }
        if (priority === "None") {
          newPriority = "";
        }
        if (customerType === "None") {
          newCustomerType = "";
        }
        if (category === "None") {
          newCategory = "";
        }

        url = `api/tickets?id=${ticketId.trim()}&is_assigned=${newIsAssigned}&status=${newStatus}&priority=${newPriority}&mobile_number=${encodeURIComponent(
          mobileNumber.trim()
        )}&issue_category=${newCategory}&customer_type=${newCustomerType}&page=${newPageNO}`;
      }
      let allTickets = await getDataWithToken(url);
      if (allTickets.status === 200) {
        setAllTickets(allTickets.data.data.data);
        setTotalData(allTickets.data.data.total);
        setRowsPerPage(allTickets.data.data.per_page);
        if (allTickets.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(allTickets.data.messages.toString());
        if (allTickets.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setMessage(error.response.data.message.toString());
      setLoading(false);
    }
    setLoading(false);
  };

  const getDropdownData = async () => {
    let allStatus = await getDataWithToken(`api/ticket-statuses`);
    if (allStatus.status === 200) {
      setStatusList(allStatus.data.data);
    } else {
      setStatusMessage(allStatus.data.messages.toString());
      if (allStatus.data.messages.length < 1) {
        setStatusMessage("Something went wrong");
      }
    }
    let allIssueCategories = await getDataWithToken(`api/issue-categories`);
    if (allIssueCategories.status === 200) {
      setCategoryList(allIssueCategories.data.data);
    } else {
      // handleSnakbarOpen(allIssueCategories.data.messages.toString(), "error");
      setCategoryMessage(allIssueCategories.data.messages.toString());
      if (allIssueCategories.data.messages.length < 1) {
        setCategoryMessage("Something went wrong");
      }
    }
    return allStatus.data.data;
  };
  useEffect(async () => {
    setLoading(true);
    let statusArray = await getDropdownData();

    if (location.state === null) {
      getData();
    } else {
      let newStatus = statusArray?.filter((res) => res.name === location.state);

      let url = `api/tickets`;
      if (newStatus.length > 0) {
        url = `api/tickets?status=${newStatus[0].id}`;
        setStatus(newStatus[0].id);
      }
      getData("", url);
    }
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={6} xl={6}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            Tickets
          </Typography>
        </Grid>
        <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
          {rollPermission?.includes("Create ticket") && (
            <Button
              disableElevation
              variant="outlined"
              size="large"
              color="info"
              startIcon={<AddIcon />}
              component={Link}
              to="/create-ticket"
            >
              Create New
            </Button>
          )}
          &nbsp; &nbsp; &nbsp;
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            // startIcon={<FilterListIcon />}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  id="mobile-no"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No."
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="ticketId"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Ticket Id"
                  value={ticketId}
                  onChange={(e) => setTicketId(e.target.value)}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-is-assigned-outlined-label">
                    Is Assigned
                  </InputLabel>
                  <Select
                    labelId="demo-is-assigned-outlined-label"
                    id="demo-is-assigned-outlined"
                    value={isAssigned}
                    onChange={(e) => setIsAssigned(e.target.value)}
                    label="Is Assigned"
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value={0}>Assigned</MenuItem>
                    <MenuItem value={1}>Not Assigned</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="priority-outlined-label">Priority</InputLabel>
                  <Select
                    labelId="priority-outlined-label"
                    id="priority-outlined"
                    label="Priority"
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-customer-type-outlined-label">
                    Customer Type
                  </InputLabel>
                  <Select
                    labelId="demo-customer-type-outlined-label"
                    id="demo-customer-type-outlined"
                    label="Customer Type"
                    value={customerType}
                    onChange={(e) => setCustomerType(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Personal">Personal</MenuItem>
                    <MenuItem value="Merchant">Merchant</MenuItem>
                    <MenuItem value="Agent">Agent</MenuItem>
                    <MenuItem value="SR">SR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">
                      {statusMessage.length > 0 ? <>{statusMessage}</> : "None"}
                    </MenuItem>
                    {statusList?.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <MenuItem value="None">
                      {categoryMessage.length > 0 ? (
                        <>{categoryMessage}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>
                    {categoryList?.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        {item.issue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={{ lg: 6, xl: 3 }}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell colSpan={2} style={{ minWidth: "230px" }}>
                  Basic Info
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Customer Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Ticket Id
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Phone No
                </TableCell>
                <TableCell align="center">Priority</TableCell>
                <TableCell align="center" style={{ minWidth: "95px" }}>
                  Time
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {" "}
                  Status
                </TableCell>
                <TableCell align="center" style={{ minWidth: "90px" }}>
                  {" "}
                  View Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                allTickets.length > 0 &&
                allTickets.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row.customer_photo}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.customer_name}
                      </Typography>
                      {row.issue_category}
                    </TableCell>
                    <TableCell align="center">{row.customer_type}</TableCell>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">
                      {row.customer_mobile_number}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: "120px",
                        // color: "#fff",
                        color:
                          row.priority === "Medium"
                            ? "rgba(236, 187, 66 ,1)"
                            : row.priority === "Low"
                            ? "rgba(129,199,132,1)"
                            : "rgba(211, 47, 47,1)",
                      }}
                    >
                      {row.priority}
                      {/* <Chip
                      label={row.priority}
                      // variant="outlined"
                     
                    /> */}
                    </TableCell>
                    <TableCell align="center">{row.time}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: "120px",
                        // color: "#fff",
                        color:
                          row.status === "Escalated"
                            ? "rgba(77,208,225,1)"
                            : row.status === "Solved"
                            ? "#1b5e20"
                            : row.status === "On Hold"
                            ? "#696e70"
                            : row.status === "In Progress"
                            ? "#4caf50"
                            : "#1976d2",
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton component={Link} to={`/tickets/${row.id}`}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && allTickets.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {allTickets.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              // count={rows.length}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </TableContainer>
  );
};

export default Tickets;
