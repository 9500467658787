import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthContext } from "../../context/AuthContext";
import { getDataWithToken } from "../../services/GetDataService";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    // textAlign: "center",
    // width: "600px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  titleBox: {
    background: "#eaecf3",
    fontSize: "1.5rem",
    // fontWeight: 600,
    textAlign: "center",
    padding: "5px",
    borderRadius: "8px",
    width: "50%",
    margin: "0px auto 30px",
    fontFamily: "'Roboto', sans-serif",
  },
  merchantListBox: {
    height: "200px",
    background: "#f1f1f1",
    padding: " 10px",
    overflowY: "auto",
  },
  merchantListStyle: {
    padding: "5px 15px",
    background: "#ddd",
    marginBottom: "5px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const SendNotification = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [loading, setLoading] = useState(false);
  const [merchantMessage, setMerchantMessage] = useState("");
  const [radioButtonValue, setRadioButtonValue] = React.useState("female");
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [personName, setPersonName] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  const handleRadioButton = (event) => {
    if (event.target.value === "All Merchant") {
      setSelectedMerchant(merchantList);
    } else {
      setSelectedMerchant([]);
    }
    setRadioButtonValue(event.target.value);
  };
  const cancelMerchant = (id) => {
    setSelectedMerchant(selectedMerchant.filter((res) => res.id !== id));
  };
  const validation = () => {
    let isError = false;
    // if (!merchantId) {
    //   handleSnakbarOpen("Please select merchant", "error");
    //   return (isError = true);
    // }
    if (!title.trim()) {
      handleSnakbarOpen("Please enter title", "error");
      document.getElementById("title").focus();
      return (isError = true);
    }
    if (!subtitle.trim()) {
      handleSnakbarOpen("Please enter subtitle", "error");
      document.getElementById("subtitle").focus();
      return (isError = true);
    }

    if (!message.trim()) {
      handleSnakbarOpen("Please enter user message", "error");
      document.getElementById("message").focus();
      return (isError = true);
    }
    return isError;
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let merchantIds = selectedMerchant.map((item) => item.id);

    if (merchantIds.length < 1) {
      return handleSnakbarOpen("Please select merchant", "error");
    }

    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let data = {
          message,
          merchant_id: merchantIds,
          title,
          subtitle,
        };
        let response = await axios({
          url: "api/merchant-messages",
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        if (response.data.code === 200) {
          handleSnakbarOpen(response.data.messages.toString(), "success");
          setMessage("");
          setMerchantId("");
          setTitle("");
          setSubtitle("");
          navigate("/notification-list");
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        if (error.response.data.messages.length < 1) {
          handleSnakbarOpen("Something went wrong", "error");
        }
      }
      setLoading(false);
    }
  };
  const getDropdownData = async () => {
    let allRoles = await getDataWithToken(`api/merchant-user-list`);
    if (allRoles.status === 200) {
      let newList = [];
      allRoles.data.data.map((item, i) => {
        let newLabel = item.name.concat(" (", item.mobile_no, ")");
        newList.push({ ...item, label: newLabel });
      });
      setMerchantList(newList);
    } else {
      setMerchantMessage(allRoles.data.messages.toString());
      if (allRoles.data.messages.length < 1) {
        setMerchantMessage("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "80vh" }}
      >
        <div className={classes.form}>
          <div className={classes.titleBox}>Send Notification</div>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <div component="div" style={{ marginBottom: "10px" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  style={{ flexDirection: "row" }}
                  value={radioButtonValue}
                  onChange={handleRadioButton}
                >
                  <FormControlLabel
                    value="All Merchant"
                    control={<Radio />}
                    label="All Merchant"
                  />
                  <FormControlLabel
                    value="Selected Merchant"
                    control={<Radio />}
                    label="Selected Merchant"
                  />
                </RadioGroup>
              </div>

              <Collapse
                in={radioButtonValue === "Selected Merchant"}
                timeout="auto"
                unmountOnExit
                style={{ background: "#fff" }}
              >
                <Autocomplete
                  style={{ marginBottom: "20px" }}
                  disablePortal
                  value={value}
                  blurOnSelect={true}
                  onChange={(event, newValue) => {
                    setSelectedMerchant([...selectedMerchant, newValue]);
                    // setValue();
                  }}
                  // inputValue={inputValue}
                  // onInputChange={(event, newInputValue) => {
                  //   setInputValue({});
                  // }}
                  id="combo-box-demo"
                  options={merchantList}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Merchant" />
                  )}
                />
              </Collapse>
              <div className={classes.merchantListBox}>
                {selectedMerchant.length < 1 && (
                  <Typography variant="body2" style={{ color: "#676767" }}>
                    No merchant Selected
                  </Typography>
                )}
                {selectedMerchant?.map((item, i) => (
                  <div className={classes.merchantListStyle}>
                    <Typography variant="subtitle1" style={{ margin: "0px" }}>
                      {item.label}{" "}
                    </Typography>
                    <div>
                      <IconButton onClick={() => cancelMerchant(item.id)}>
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
              {selectedMerchant.length > 0 && (
                <Typography
                  variant="body2"
                  style={{
                    color: "#676767",
                    marginTop: "7px",
                    textAlign: "right",
                  }}
                >
                  ({selectedMerchant.length}&nbsp;
                  {selectedMerchant.length > 1 ? "Merchants" : "Merchant"}
                  &nbsp;)
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="title"
                label="Enter title"
                fullWidth
                size="small"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ marginBottom: "30px" }}
              />
              <TextField
                id="subtitle"
                label="Enter subtitle"
                fullWidth
                size="small"
                variant="outlined"
                value={subtitle}
                multiline
                rows={2}
                onChange={(e) => setSubtitle(e.target.value)}
                style={{ marginBottom: "30px" }}
              />
              <TextField
                id="message"
                label="Enter message"
                fullWidth
                size="small"
                variant="outlined"
                value={message}
                multiline
                rows={6}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            disableElevation
            fullWidth
            style={{ marginBottom: "30px", marginTop: "30px" }}
            disabled={loading}
            onClick={onSubmit}
          >
            {loading === false && "Send Notification"}
            <PulseLoader
              color={"#00848A"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default SendNotification;
