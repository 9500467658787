import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getDataWithToken } from "../../services/GetDataService";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ImageUpload from "./ImageUpload";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    // textAlign: "center",
    width: "900px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
}));
const CreateTicket = ({ reportData, handleDetailDialogClose }) => {
  const classes = useStyles();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [mobileNumber, setMobileNumber] = useState(slug);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryMessage, setCategoryMessage] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [allFiles, setAllFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const validation = () => {
    let isError = false;

    if (!mobileNumber) {
      handleSnakbarOpen("Please enter mobile number", "error");
      document.getElementById("mobile-number").focus();
      return (isError = true);
    }
    if (!category) {
      handleSnakbarOpen("Please select a issue", "error");
      return (isError = true);
    }
    if (!summary) {
      handleSnakbarOpen("Please enter summary", "error");
      document.getElementById("summary").focus();
      return (isError = true);
    }
    if (!description) {
      handleSnakbarOpen("Please enter description", "error");
      document.getElementById("description").focus();
      return (isError = true);
    }
    if (!priority) {
      handleSnakbarOpen("Please select priority", "error");
      return (isError = true);
    }
    if (!customerType) {
      handleSnakbarOpen("Please select customer type", "error");
      return (isError = true);
    }
    // if (!allFiles) {
    //   handleSnakbarOpen("Please attach a attachment", "error");
    //   return (isError = true);
    // }

    return isError;
  };
  const reset = () => {
    setMobileNumber("");
    setCategory("");
    setSummary("");
    setDescription("");
    setUser("");
    setPriority("");
    setCustomerType("");
    setAllFiles(null);
  };
  const onSubmit = async (addnew) => {
    let err = validation();

    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        const formdata = new FormData();
        if (reportData !== undefined) {
          formdata.append("customer_report_id", reportData.id);
        }

        if (allFiles) {
          formdata.append("attachments[0]", allFiles);
        }
        formdata.append("mobile_number", mobileNumber);
        formdata.append("issue_category_id", category);
        formdata.append("summery", summary);
        formdata.append("description", description);
        formdata.append("assigned_to", user);
        formdata.append("priority", priority);
        formdata.append("customer_type", customerType);
        let response = await axios({
          url: "api/ticket",
          method: "post",
          data: formdata,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        handleSnakbarOpen(response.data.messages.toString(), "success");
        reset();
        if (addnew === false) {
          if (reportData !== undefined) {
            handleDetailDialogClose();
          } else {
            navigate("/tickets");
          }
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");

        setLoading(false);
      }
      setLoading(false);
    }
  };
  const getDropdownData = async () => {
    let allUser = await getDataWithToken(`/api/user-list`);
    if (allUser.data.code === 200) {
      setUserList(allUser.data.data);
    } else {
      setUserMessage(allUser.data.messages.toString());
      if (allUser.data.messages.length < 1) {
        setUserMessage("Something went wrong");
      }
    }
    let allIssueCategories = await getDataWithToken(`api/issue-categories`);
    if (allIssueCategories.data.code === 200) {
      setCategoryList(allIssueCategories.data.data);
    } else {
      setCategoryMessage(allIssueCategories.data.messages.toString());
      if (allIssueCategories.data.messages.length < 1) {
        setCategoryMessage("Something went wrong");
      }
    }
  };
  useEffect(() => {
    if (reportData !== undefined) {
      setMobileNumber(reportData.mobile_no);
      setDescription(reportData.description);
    }
    getDropdownData();
  }, []);

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: reportData === undefined ? "80vh" : "auto" }}
      >
        <div className={classes.form}>
          <div style={{ position: "relative" }}>
            {reportData === undefined ? (
              <IconButton
                // component={Link}
                // to="/store-management"
                onClick={() => window.history.back()}
                style={{ position: "absolute", border: "none" }}
              >
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleDetailDialogClose}
                style={{ position: "absolute", border: "none", right: 0 }}
              >
                <ClearIcon />
              </IconButton>
            )}
            <Typography
              variant="h4"
              color="info"
              gutterBottom
              component="div"
              style={{ textAlign: "center" }}
            >
              Create Ticket
            </Typography>
            {reportData !== undefined && (
              <Typography
                variant="h6"
                color="info"
                gutterBottom
                component="div"
                style={{ textAlign: "center", color: "#8f8f8f" }}
              >
                Reference Id : {reportData.ref_id}
              </Typography>
            )}
          </div>
          <br />
          <Grid container columnSpacing={3}>
            <Grid item xs={6}>
              <TextField
                size="small"
                id="mobile-number"
                label="Enter Mobile Number"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "30px" }}
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-issue-outlined-label">
                  What's the issue?
                </InputLabel>
                <Select
                  labelId="demo-issue-outlined-label"
                  id="demo-issue-outlined"
                  label="What's the issue?"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categoryMessage.length > 0 && (
                    <MenuItem value="">{categoryMessage}</MenuItem>
                  )}
                  {categoryList?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.issue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                style={{ marginBottom: "30px" }}
                size="small"
                id="summary"
                label="Enter a Summary"
                variant="outlined"
                fullWidth
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
              <TextField
                size="small"
                id="description"
                label="Enter Your description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-assign-outlined-label">
                  Assign To
                </InputLabel>
                <Select
                  labelId="demo-assign-outlined-label"
                  id="demo-assign-outlined"
                  label="Assign To"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                >
                  {userMessage.length > 0 && (
                    <MenuItem value="">{userMessage}</MenuItem>
                  )}
                  {userList?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="priority-outlined-label">Priority</InputLabel>
                <Select
                  labelId="priority-outlined-label"
                  id="priority-outlined"
                  label="Priority"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-customer-type-outlined-label">
                  Customer Type
                </InputLabel>
                <Select
                  labelId="demo-customer-type-outlined-label"
                  id="demo-customer-type-outlined"
                  label="Customer Type"
                  value={customerType}
                  onChange={(e) => setCustomerType(e.target.value)}
                >
                  <MenuItem value="Personal">Personal</MenuItem>
                  <MenuItem value="Merchant">Merchant</MenuItem>
                  <MenuItem value="Agent">Agent</MenuItem>
                  <MenuItem value="SR">SR</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  marginBottom: "30px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <ImageUpload allFiles={allFiles} setAllFiles={setAllFiles} />
              </div>
              {reportData === undefined ? (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      disabled={loading}
                      onClick={() => onSubmit(true)}
                      color="info"
                    >
                      {loading === false && "Create And Add Another"}
                      <PulseLoader
                        color={"#00848A"}
                        loading={loading}
                        size={10}
                        speedMultiplier={0.5}
                      />{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    {" "}
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      disabled={loading}
                      onClick={() => onSubmit(false)}
                    >
                      {loading === false && "submit"}
                      <PulseLoader
                        color={"#00848A"}
                        loading={loading}
                        size={10}
                        speedMultiplier={0.5}
                      />{" "}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  style={{ marginBottom: "30px" }}
                  disabled={loading}
                  onClick={() => onSubmit(false)}
                >
                  {loading === false && "submit"}
                  <PulseLoader
                    color={"#00848A"}
                    loading={loading}
                    size={10}
                    speedMultiplier={0.5}
                  />{" "}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default CreateTicket;
