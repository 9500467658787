import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../context/AuthContext";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useNavigate } from "react-router-dom";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    width: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },

  checkboxStyle: {
    "& span": {
      fontSize: "20px",
      fontWeight: 600,
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
  },
  checkboxStyle2: {
    "& span": {
      // fontSize: "20px",
      // fontWeight: 600,
      [theme.breakpoints.down("xl")]: {
        fontSize: "12px",
      },
    },
  },
}));

const CreateRoles = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [mainPermissionList, setMainPermissionList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [createRoleLoading, setCreateRoleLoading] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 9; i++) {
      content.push(
        <Grid item xs={4} key={i}>
          <div className={classes.checkboxStyle}>
            <Skeleton></Skeleton>
          </div>
          <div
            style={{
              paddingLeft: "48px",
              boxSizing: "border-box",
            }}
          >
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
          </div>
        </Grid>
      );
    }
    return content;
  };
  const validation = () => {
    let isError = false;
    if (!roleName.trim()) {
      handleSnakbarOpen("Please enter role name", "error");
      document.getElementById("roleName").focus();
      return (isError = true);
    }

    return isError;
  };

  const createRole = async () => {
    let err = validation();

    if (err) {
      return;
    } else {
      let permissionsList = [];
      permissionList.map((item) => {
        item.permissions.map((el) => {
          if (el.isPermitted) {
            permissionsList.push(el.permission);
          }
        });
      });
      let data = {
        role_name: roleName,
        permissions: permissionsList,
      };
      try {
        setCreateRoleLoading(true);

        let response = await axios({
          url: `api/role`,
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        if (response.data.code === 200) {
          handleSnakbarOpen(response.data.messages.toString(), "success");

          setCreateRoleLoading(false);
          // navigate("/view-role");
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        if (error.response.data.messages.length < 1) {
          handleSnakbarOpen("Something went wrong", "error");
        }
        setCreateRoleLoading(false);
      }
      setCreateRoleLoading(false);
    }
  };

  const getPermissionInfo = async () => {
    setLoading(true);
    let myNewData = [];
    let allRoles = await getDataWithToken(`api/module-permissions`);

    if (allRoles.data.code === 200) {
      for (const key in allRoles.data.data) {
        let newObj = allRoles.data.data[key].map((obj) => ({
          ...obj,
          isPermitted: false,
        }));

        let myObj = {
          title: key,
          allPermitted: false,
          permissions: newObj,
        };
        myNewData.push(myObj);
      }
      setPermissionList(myNewData);
      setMainPermissionList(myNewData);

      setLoading(false);
    } else {
      // handleSnakbarOpen(allRoles.data.messages.toString(), "error");
      setMessage(allRoles.data.messages.toString());
      if (allRoles.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const handlePermissionChange = (item, index, el, i) => {
    let newObj = { ...el, isPermitted: !el.isPermitted };
    let newPermissions = item.permissions;
    item.permissions[i] = newObj;
    const isAnyPermissionFalse = newPermissions.some(
      (el) => el.isPermitted === false
    );

    permissionList[index] = {
      ...item,
      allPermitted: !isAnyPermissionFalse,
      permissions: newPermissions,
    };

    setRefresh(!refresh);
  };
  const handlePermissionSelectByTitle = (checked, item, index) => {
    let newArray = item.permissions.map((obj) => ({
      ...obj,
      isPermitted: checked,
    }));

    permissionList[index] = {
      ...item,
      allPermitted: checked,
      permissions: newArray,
    };
    setRefresh(!refresh);
  };
  useEffect(() => {
    getPermissionInfo();
  }, []);
  return (
    <>
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "85vh" }}
        >
          <div className={classes.form}>
            <img
              src="/image/Logo.png"
              alt=""
              style={{ display: "block", margin: "auto", maxWidth: "155px" }}
            />
            <br />
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "30px" }}
            >
              {message}
            </Typography>
          </div>
        </Grid>
      ) : (
        <TableContainer
          component={Paper}
          style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
        >
          <Typography variant="h4" color="info" gutterBottom component="div">
            Create Role
          </Typography>
          <TextField
            id="roleName"
            fullWidth
            variant="outlined"
            label="Enter Role Name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />
          <br />
          <br />
          <Typography variant="h6" color="info" gutterBottom component="div">
            Select Permissions
          </Typography>

          <Paper style={{ padding: "24px", boxSizing: "border-box" }}>
            <Grid container spacing={3}>
              {loading && pageLoading()}
              {/* {!loading && permissionList.length > 0 && (
              <> */}
              {permissionList?.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <div className={classes.checkboxStyle}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={item.title}
                      checked={item.allPermitted}
                      onChange={(event) => {
                        handlePermissionSelectByTitle(
                          event.target.checked,
                          item,
                          index
                        );
                      }}
                    />
                  </div>
                  <div style={{ paddingLeft: "48px", boxSizing: "border-box" }}>
                    {item.permissions?.map((el, i) => (
                      <div key={i} className={classes.checkboxStyle2}>
                        <FormControlLabel
                          component="div"
                          control={<Checkbox />}
                          label={el.function_name}
                          checked={el.isPermitted}
                          onChange={() => {
                            handlePermissionChange(item, index, el, i);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
              ))}
              {/* </>
            )} */}
            </Grid>
          </Paper>
          <br />
          <br />
          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              disabled={createRoleLoading}
              onClick={createRole}
              style={{ minWidth: "100px" }}
            >
              <PulseLoader
                color={"#00848A"}
                loading={createRoleLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {createRoleLoading === false && "Create And Add New Role"}
            </Button>
          </div>
          <br />
          <br />
        </TableContainer>
      )}
    </>
  );
};

export default CreateRoles;
