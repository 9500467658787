import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AuthContext } from "../../context/AuthContext";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    width: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "auto",
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  titleStyle: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
  checkboxStyle: {
    "& span": {
      fontSize: "16px",
      fontWeight: 600,
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
  },
  checkboxStyle2: {
    "& span": {
      // fontSize: "20px",
      // fontWeight: 600,
      [theme.breakpoints.down("xl")]: {
        fontSize: "12px",
      },
    },
  },
}));

const UpdateRole = () => {
  const classes = useStyles();
  const { slug } = useParams();
  const [
    permissionListWithAssignedPermissions,
    setPermissionListWithAssignedPermissions,
  ] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [permissionMessage, setPermissionMessage] = useState("");
  const [createRoleLoading, setCreateRoleLoading] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 9; i++) {
      content.push(
        <Grid item xs={4} key={i}>
          <div className={classes.checkboxStyle}>
            <Skeleton></Skeleton>
          </div>
          <div
            style={{
              paddingLeft: "48px",
              boxSizing: "border-box",
            }}
          >
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
            <Skeleton></Skeleton>
            <br />
          </div>
        </Grid>
      );
    }
    return content;
  };

  const updateRoleById = async () => {
    try {
      setCreateRoleLoading(true);
      let permissionsList = [];
      permissionListWithAssignedPermissions.map((item) => {
        item.permissions.map((el) => {
          if (el.isPermitted) {
            permissionsList.push(el.permission);
          }
        });
      });
      let data = {
        permissions: permissionsList,
      };

      let response = await axios({
        url: `api/role/${slug}`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      if (response.status === 200) {
        handleSnakbarOpen(response.data.messages.toString(), "success");

        setCreateRoleLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      if (error.response.data.messages.length < 1) {
        handleSnakbarOpen("Something went wrong", "error");
      }
      setCreateRoleLoading(false);
    }
    setCreateRoleLoading(false);
  };

  const getPermissionInfo = async () => {
    setLoading(true);
    let myNewData = [];

    let allPermission = await getDataWithToken(`api/module-permissions`);

    if (allPermission.data.code === 200) {
      for (const key in allPermission.data.data) {
        let newObj = allPermission.data.data[key].map((obj) => ({
          ...obj,
          isPermitted: false,
        }));

        let myObj = {
          title: key,
          allPermitted: false,
          permissions: newObj,
        };
        myNewData.push(myObj);
      }
      // setPermissionList(myNewData);
      setLoading(false);
      return myNewData;
    } else {
      setPermissionMessage(allPermission.data.messages.toString());
      if (allPermission.data.messages.length < 1) {
        setPermissionMessage("Something went wrong");
      }
      setLoading(false);
      return myNewData;
    }
    setLoading(false);
  };
  const getAssaignedPermissionInfo = async (id) => {
    const permissionList2 = await getPermissionInfo();

    setLoading(true);
    let myNewData = [];

    let allAssaignedPermission = await getDataWithToken(`api/role/${id}`);
    if (allAssaignedPermission.status === 200) {
      setRoleName(allAssaignedPermission.data.data.role_name);
      let assignedPermissions =
        allAssaignedPermission.data.data.assigned_permissions;
      permissionList2?.map((item) => {
        let newObj = item.permissions.map((obj) => {
          return {
            ...obj,
            isPermitted: assignedPermissions.includes(obj.permission),
          };
        });
        const isAnyPermissionFalse = newObj.some(
          (el) => el.isPermitted === false
        );
        let myObj = {
          title: item.title,
          allPermitted: !isAnyPermissionFalse,
          permissions: newObj,
        };
        myNewData.push(myObj);
      });
      setPermissionListWithAssignedPermissions(myNewData);

      setLoading(false);
    } else {
      setMessage(allAssaignedPermission.data.messages.toString());
      if (allAssaignedPermission.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const handlePermissionChange = (item, index, el, i) => {
    let newObj = { ...el, isPermitted: !el.isPermitted };
    let newPermissions = item.permissions;
    item.permissions[i] = newObj;
    const isAnyPermissionFalse = newPermissions.some(
      (el) => el.isPermitted === false
    );

    permissionListWithAssignedPermissions[index] = {
      ...item,
      allPermitted: !isAnyPermissionFalse,
      permissions: newPermissions,
    };

    setRefresh(!refresh);
  };
  const handlePermissionSelectByTitle = (checked, item, index) => {
    let newArray = item.permissions.map((obj) => ({
      ...obj,
      isPermitted: checked,
    }));

    permissionListWithAssignedPermissions[index] = {
      ...item,
      allPermitted: checked,
      permissions: newArray,
    };
    setRefresh(!refresh);
  };
  useEffect(() => {
    getAssaignedPermissionInfo(slug);
  }, []);
  return (
    <>
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "85vh" }}
        >
          <div className={classes.form}>
            <img
              src="/image/Logo.png"
              alt=""
              style={{ display: "block", margin: "auto", maxWidth: "155px" }}
            />
            <br />
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "30px" }}
            >
              {message}
            </Typography>
          </div>
        </Grid>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
          >
            {roleName.length > 0 && (
              <Typography
                variant="h4"
                color="info"
                gutterBottom
                component="div"
                style={{ textAlign: "center" }}
              >
                {roleName} has access to
              </Typography>
            )}
            <br />

            <Paper style={{ padding: "24px", boxSizing: "border-box" }}>
              <Grid container spacing={3}>
                {loading && pageLoading()}

                {permissionListWithAssignedPermissions?.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    // style={{ borderBottom: "1px solid red" }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          className={classes.titleStyle}
                          style={{
                            textAlign: "right",
                            marginTop: "8px",
                            color: "#487eb0",
                          }}
                        >
                          {item.title}
                        </Typography>
                        {/* <FormControlLabel
                            control={<Checkbox />}
                            // label={item.title}
                            label='Select All'
                            checked={item.allPermitted}
                            onChange={(event) => {
                              handlePermissionSelectByTitle(
                                event.target.checked,
                                item,
                                index
                              );
                            }}
                          /> */}
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{
                          paddingLeft: "48px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={4} className={classes.checkboxStyle2}>
                            <FormControlLabel
                              control={<Checkbox />}
                              // label={item.title}
                              label="Select All"
                              checked={item.allPermitted}
                              onChange={(event) => {
                                handlePermissionSelectByTitle(
                                  event.target.checked,
                                  item,
                                  index
                                );
                              }}
                            />
                          </Grid>
                          {item.permissions?.map((el, i) => (
                            <Grid
                              item
                              xs={4}
                              key={i}
                              className={classes.checkboxStyle2}
                            >
                              {/* <span key={i} className={classes.checkboxStyle2}> */}
                              <FormControlLabel
                                component="div"
                                control={<Checkbox />}
                                label={el.function_name}
                                checked={el.isPermitted}
                                onChange={() => {
                                  handlePermissionChange(item, index, el, i);
                                }}
                              />
                              {/* </span> */}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {/* </>
              )} */}
              </Grid>
            </Paper>
            <br />
            <br />
            <div></div>
            <Button
              variant="contained"
              disabled={createRoleLoading}
              onClick={updateRoleById}
              style={{ minWidth: "135px", float: "right" }}
            >
              <PulseLoader
                color={"#00848A"}
                loading={createRoleLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {createRoleLoading === false && "Update Role"}
            </Button>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default UpdateRole;
