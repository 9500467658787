import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { useSnackbar } from "notistack";
import CreateTicket from "../tickets/CreateTicket";
import ReactReadMoreReadLess from "react-read-more-read-less";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:nth-child(-n+3)": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td:nth-child(-n+3)": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
  tableHeadStyle2: {
    // background: "#2B335E",
    "& th": {
      // color: "#fff",
      fontSize: "20px",
      borderBottom: "2px solid #353b48",
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tdStyle: {
    "& td": {
      paddingTop: "7px",
      paddingBottom: "7px",
      borderBottom: "none",
      fontSize: "15px",
    },
  },
}));

const CustomerReport = ({ rollPermission }) => {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [transactionId, setTransactionId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [issueCategoryList, setIssueCategoryList] = useState([]);
  const [status, setStatus] = useState("");
  const [issueCategoryId, setIssueCategoryId] = useState("");
  const [allTransactions, SetAllTransactions] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [issueCategoryMessage, setIssueCategoryMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [reportData, setReportData] = useState({});

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell style={{ paddingRight: 0, width: "50px" }}>
            <Skeleton variant="circular" width={45} height={45} />
          </TableCell>
          <TableCell>
            <Typography
              gutterBottom
              component="div"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              <Skeleton></Skeleton>
            </Typography>
            <Skeleton></Skeleton>
          </TableCell>

          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          {rollPermission?.includes("Create ticket") && (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          )}
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setMobileNo("");
    setTransactionId("");
    setIssueCategoryId("");
    setStatus("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);

    let newUrl = `api/customer-report-list?page=1`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    setLoading(true);
    let newPageNO;
    let url;
    if (pageNO) {
      newPageNO = pageNO;
    } else {
      newPageNO = 1;
    }
    if (newUrl) {
      url = newUrl;
    } else {
      let newStartingTime = "";
      let newEndingTime = "";
      let newIssuecategoryId = issueCategoryId;
      let newStatus = status;

      if (startingTime !== null) {
        newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (endingTime !== null) {
        newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
      }

      if (issueCategoryId === "None") {
        newIssuecategoryId = "";
      }
      if (status === "None") {
        newStatus = "";
      }

      url = `api/customer-report-list?mobile_number=${encodeURIComponent(
        mobileNo.trim()
      )}&tx_unique_id=${transactionId.trim()}&starting_time=${newStartingTime}&ending_time=${newEndingTime}&issue_category_id=${newIssuecategoryId}&status=${newStatus}
       &page=${newPageNO}`;
    }
    let reports = await getDataWithToken(url);

    if (reports.status === 200) {
      SetAllTransactions(reports.data.data.data);
      setTotalData(reports.data.data.total);
      if (reports.data.data.data.length < 1) {
        setMessage("No data found");
      }
    } else {
      setMessage(reports.data.messages.toString());
      if (reports.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }

    setLoading(false);
  };
  // setMessage(error.response.data.message.toString());
  const getTicketStatus = async () => {
    try {
      let allStatus = await getDataWithToken(`api/ticket-statuses`);
      if (allStatus.status === 200) {
        setStatusList(allStatus.data.data);
      }
    } catch (error) {
      console.log("error", error);
      setStatusMessage(error.response.data.message.toString());
    }
  };
  const getIssueCategory = async () => {
    try {
      let allIssueCategoryList = await getDataWithToken(
        `api/customer-report/issue-categories`
      );
      if (allIssueCategoryList.status === 200) {
        setIssueCategoryList(allIssueCategoryList.data.data);
      }
    } catch (error) {
      console.log("error", error);
      setIssueCategoryMessage(error.response.data.message.toString());
    }
  };

  const handleCreateTicket = (row) => {
    setReportData(row);
    setDetailOpen(true);
  };
  const handleDetailDialogClose = () => {
    setReportData({});
    setDetailOpen(false);
  };

  useEffect(() => {
    getData();
    getIssueCategory();
    getTicketStatus();
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={6} xl={6}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            Customer Report
          </Typography>
        </Grid>
        <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            // startIcon={<FilterListIcon />}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  id="mobileNo"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="transaction-id"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Transaction Id"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Issue Category
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Issue Category"
                    value={issueCategoryId}
                    onChange={(e) => setIssueCategoryId(e.target.value)}
                  >
                    <MenuItem value="None">
                      {issueCategoryMessage.length > 0 ? (
                        <>{issueCategoryMessage}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>
                    {issueCategoryList?.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">
                      {statusMessage.length > 0 ? <>{statusMessage}</> : "None"}
                    </MenuItem>
                    {statusList?.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Starting Time"
                    value={startingTime}
                    onChange={(newValue) => {
                      setStartingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Ending Time"
                    value={endingTime}
                    onChange={(newValue) => {
                      setEndingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <Grid container spacing={{ md: 4, lg: 4, xl: 1 }}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>

                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell colSpan={2} style={{ minWidth: "240px" }}>
                  Basic Info
                </TableCell>
                <TableCell style={{ minWidth: "180px" }}>
                  Reference Id
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Transaction Id
                </TableCell>
                <TableCell style={{ minWidth: "120px" }}>
                  Issue Category
                </TableCell>
                <TableCell style={{ minWidth: "300px" }}>Description</TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Ticket Id
                </TableCell>
                <TableCell style={{ minWidth: "90px" }}>Remarks</TableCell>
                <TableCell style={{ minWidth: "90px" }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ minWidth: "150px" }}>Created At</TableCell>
                {rollPermission?.includes("Create ticket") && (
                  <TableCell style={{ minWidth: "150px" }} align="center">
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                allTransactions.length > 0 &&
                allTransactions.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row?.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.name}
                      </Typography>
                      {row.mobile_no}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row.ref_id}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        fullWidth
                        color="info"
                        endIcon={<LaunchIcon style={{ fontSize: "16px" }} />}
                        component={Link}
                        to={`/transactions/${row.tx_unique_id}`}
                      >
                        {row.tx_unique_id}
                      </Button>
                    </TableCell>
                    <TableCell>{row.issue_category}</TableCell>
                    <TableCell>
                      <ReactReadMoreReadLess
                        charLimit={30}
                        readMoreText={
                          <Button
                            disableElevation
                            variant="outlined"
                            size="small"
                            color="info"
                            endIcon={
                              <ExpandMoreIcon style={{ fontSize: "12px" }} />
                            }
                            style={{ fontSize: "8px" }}
                          >
                            Read More
                          </Button>
                        }
                        readLessText={
                          <Button
                            disableElevation
                            variant="outlined"
                            size="small"
                            color="info"
                            endIcon={
                              <KeyboardArrowUpIcon
                                style={{ fontSize: "12px" }}
                              />
                            }
                            style={{ fontSize: "8px" }}
                          >
                            Read Less
                          </Button>
                        }
                      >
                        {row.description}
                      </ReactReadMoreReadLess>
                    </TableCell>
                    <TableCell align="center">
                      {row.ticket_id === null ? (
                        "N/A"
                      ) : (
                        <Button
                          variant="outlined"
                          color="info"
                          endIcon={<LaunchIcon style={{ fontSize: "16px" }} />}
                          component={Link}
                          to={`/tickets/${row.ticket_id}`}
                        >
                          {row.ticket_id}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell style={{ minWidth: "220px" }}>
                      {row.remarks === null ? "N/A" : row.remarks}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: "120px",
                        // color: "#fff",
                        color:
                          row.status === "Escalated"
                            ? "rgba(77,208,225,1)"
                            : row.status === "Solved"
                            ? "#1b5e20"
                            : row.status === "On Hold"
                            ? "#696e70"
                            : row.status === "Declined"
                            ? "#d32f2f"
                            : row.status === "In Progress"
                            ? "#4caf50"
                            : "#1976d2",
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell>{row.created_at}</TableCell>
                    {rollPermission?.includes("Create ticket") && (
                      <TableCell align="center">
                        {row.ticket_id === null ? (
                          <Button
                            disableElevation
                            variant="outlined"
                            size="small"
                            color="info"
                            // startIcon={<AddIcon />}
                            onClick={() => handleCreateTicket(row)}
                          >
                            Create Ticket
                          </Button>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}

              {!loading && allTransactions.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={13} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {allTransactions.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
        <Dialog
          onClose={handleDetailDialogClose}
          aria-labelledby="simple-dialog-title"
          open={detailOpen}
          maxWidth={"1250px"}
          style={{ padding: "0px", height: "auto" }}
        >
          <div>
            <CreateTicket
              reportData={reportData}
              handleDetailDialogClose={handleDetailDialogClose}
            />
          </div>
        </Dialog>
      </Paper>
    </TableContainer>
  );
};

export default CustomerReport;
