import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const ViewUser = ({ rollPermission }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const [newUpdateValue, setNewUpdateValue] = useState({});

  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [roleMessage, setRoleMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateLoading, setUpdateLoading] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSwitchClose = () => {
    setSwitchOpen(false);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 20; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          {rollPermission?.includes("Panel user activity log") && (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          )}
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const clearFilter = (event) => {
    setEmail("");
    setStatus("");
    setRoleId("");
    setPage(0);
    let newUrl = `api/users`;
    getData("", newUrl);
  };
  const getData = async (pageNO, newUrl) => {
    setLoading(true);
    let url;
    let newPageNO;

    if (pageNO) {
      newPageNO = pageNO;
    } else {
      newPageNO = 1;
    }
    if (newUrl) {
      url = newUrl;
    } else {
      let newStatus = status;
      let newRole = roleId;

      if (status === "None") {
        newStatus = "";
      }
      if (roleId === "None") {
        newRole = "";
      }

      url = `api/users?email=${encodeURIComponent(
        email
      )}&status=${newStatus}&role=${newRole}&page=${newPageNO}`;
    }

    let allUsers = await getDataWithToken(url);

    if (allUsers.data.code === 200) {
      setUsers(allUsers.data.data.data);

      setRowsPerPage(allUsers.data.data.per_page);
      setTotalData(allUsers.data.data.total);
      if (allUsers.data.data.data.length < 1) {
        setMessage("No data found");
      }
    } else {
      setMessage(allUsers.data.messages.toString());
      if (allUsers.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
      setLoading(false);
    }

    setLoading(false);
  };

  const getDropdownData = async () => {
    let allRoles = await getDataWithToken(`api/roles`);
    if (allRoles.status === 200) {
      setRoleList(allRoles.data.data);
    } else {
      setRoleMessage(allRoles.data.messages.toString());
    }
  };

  const updateRole = async (e) => {
    e.preventDefault();

    let data = {
      user_id: newUpdateValue.id,
      role_name: newUpdateValue.value,
    };

    try {
      setUpdateLoading(true);

      let response = await axios({
        url: `api/update-user-role`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        let rolesObj = selectedForUpdate.roles[0];
        let newRoleObj = { ...rolesObj, name: newUpdateValue.value };
        let newUserObj = { ...selectedForUpdate, roles: [newRoleObj] };
        users[newUpdateValue.index] = newUserObj;

        handleClose();
      }

      handleSnakbarOpen(response.data.messages.toString(), "success");
      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };
  const updateStatus = async (e) => {
    e.preventDefault();

    try {
      setUpdateLoading(true);
      let newStatus;
      if (newUpdateValue.value) {
        newStatus = "Active";
      } else {
        newStatus = "Inactive";
      }

      let data = {
        user_id: newUpdateValue.id,
        status: newStatus,
      };

      let response = await axios({
        url: `api/update-user-status`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        let newUserObj = { ...selectedForUpdate, status: newStatus };
        users[newUpdateValue.index] = newUserObj;
        setSwitchOpen(false);
      }

      handleSnakbarOpen(response.data.messages.toString(), "success");
      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };

  useEffect(() => {
    getData();
    getDropdownData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={6} xl={6}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              User List
            </Typography>
          </Grid>
          <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              color="success"
              // startIcon={<FilterListIcon />}
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filterOpen ? <FilterListOffIcon /> : <FilterListIcon />}
            </Button>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Collapse in={filterOpen} timeout="auto" unmountOnExit>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    id="email"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-Status-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-Status-outlined-label"
                      id="demo-Status-outlined"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-role-outlined-label">Role</InputLabel>
                    <Select
                      labelId="demo-role-outlined-label"
                      id="demo-role-outlined"
                      label="Role"
                      value={roleId}
                      onChange={(e) => setRoleId(e.target.value)}
                    >
                      <MenuItem value="None">
                        {roleMessage.length > 0 ? <>{roleMessage}</> : "None"}
                      </MenuItem>

                      {roleList?.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Grid container spacing={{ lg: 6, xl: 3 }}>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={(event) => clearFilter(event, 0)}
                      >
                        <RestartAltIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        disableElevation
                        // color="success"
                        style={{ background: "#435185", padding: "6.7px 22px" }}
                        size="large"
                        startIcon={<SearchIcon />}
                        fullWidth
                        onClick={(event) => handleChangePage(event, 0)}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <br />
        <>
          <div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ minWidth: "120px" }}>Email</TableCell>
                  <TableCell style={{ minWidth: "70px" }}>
                    Assaigned Role
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Created At
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    {" "}
                    Status
                  </TableCell>
                  {rollPermission?.includes("Panel user activity log") && (
                    <TableCell align="center" style={{ minWidth: "120px" }}>
                      {" "}
                      View Activities
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!loading &&
                  users.length > 0 &&
                  users.map((row, i) => (
                    <TableRow
                      key={i}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>

                      <TableCell>
                        {rollPermission?.includes("User role change") ? (
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                            className={classes.centerSelectStyle}
                            sx={{ minWidth: 250 }}
                          >
                            <InputLabel id="demo-issue-outlined-label">
                              Select role
                            </InputLabel>
                            <Select
                              labelId="demo-issue-outlined-label"
                              id="demo-issue-outlined"
                              value={row.roles[0]?.name}
                              onChange={(e) => {
                                setNewUpdateValue({
                                  index: i,
                                  id: row.id,
                                  value: e.target.value,
                                });
                                setSelectedForUpdate(row);
                                setOpen(true);
                              }}
                            >
                              {roleMessage.length > 0 && (
                                <MenuItem value={roleMessage}>
                                  {roleMessage}
                                </MenuItem>
                              )}
                              {roleList?.map((item, i) => (
                                <MenuItem key={i} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <>{row.roles[0]?.name}</>
                        )}
                      </TableCell>
                      <TableCell align="center">{row.created_at}</TableCell>

                      <TableCell align="center">
                        {rollPermission?.includes("User status change") ? (
                          <Switch
                            color="success"
                            checked={row.status === "Active" ? true : false}
                            onChange={(e) => {
                              setNewUpdateValue({
                                index: i,
                                id: row.id,
                                value: e.target.checked,
                              });
                              setSelectedForUpdate(row);
                              setSwitchOpen(true);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        ) : (
                          <>
                            <img
                              src={
                                row.status === "Active"
                                  ? "/image/verified.svg"
                                  : "/image/unverified.svg"
                              }
                              style={{
                                width: "20px",
                                position: "relative",
                                top: 5,
                              }}
                              alt=""
                            />{" "}
                            {row.status}
                          </>
                        )}
                      </TableCell>
                      {rollPermission?.includes("Panel user activity log") && (
                        <TableCell align="center">
                          <IconButton
                            component={Link}
                            to={`/view-log-detail/${row.id}`}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                {!loading && users.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>

          {users.length > 0 && (
            <div>
              <TablePagination
                style={{ display: "block", borderBottom: "none" }}
                rowsPerPageOptions={[]}
                // count={rows.length}
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to change user role!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateRole}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={switchOpen}
        onClose={handleSwitchClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to change user status
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSwitchClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateStatus}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ViewUser;
