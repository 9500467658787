import React, { useState, useEffect, useContext } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { getDataWithToken } from "../../../services/GetDataService";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useParams, useNavigate } from "react-router-dom";
import KYBBasicInformation from "./KYBBasicInformation";
import { useSnackbar } from "notistack";
import KYBAccountDetails from "./KYBAccountDetails";
import KYBDocument from "./KYBDocument";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import UserInfoPDF from "./UserInfoPDF";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#efefef",
    },
    "& tr:last-child": {
      background: "none",
    },
    "& tr:hover": {
      cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      cursor: "default",
      background: "none",
    },

    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
  },
}));

export default function KYBDetail({ rollPermission }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xl"));
  const { slug } = useParams();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [userInfo, setUserInfo] = useState({});
  const [userStatus, setUserStatus] = useState(false);
  const [level, setLevel] = useState([]);
  const [levelId, setLevelId] = useState("");
  const [levelMessage, setLevelMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [DOB, setDOB] = React.useState(new Date());
  const [businessName, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");

  const [documentId, setDocumentId] = useState("");
  const [documentLoading, setDocumentLoading] = useState(false);
  const [userInfoMessage, setUserInfoMessage] = useState("");

  const handleDateChange = (newValue) => {
    setDOB(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const getDataAfterStatusUpdate = async () => {
    setLoading(true);
    try {
      let data = {
        kyb_id: slug,
      };

      let userInfoResponse = await axios({
        url: "api/kyb-details",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (userInfoResponse.status === 200) {
        setUserInfo(userInfoResponse.data.data);
        setFirstName(userInfoResponse.data.data.update_keys.first_name);
        setLastName(userInfoResponse.data.data.update_keys.last_name);
        setEmail(userInfoResponse.data.data.update_keys.email);
        setFullname(userInfoResponse.data.data.update_keys?.full_name);
        setBusinessName(userInfoResponse.data.data.update_keys?.business_name);
        setDOB(userInfoResponse.data.data.update_keys?.date_of_birth);
        setDocumentId(userInfoResponse.data.data.update_keys?.document_id);
        setLevelId(userInfoResponse.data.data.business_information.level_id);
        if (userInfoResponse.data.data.business_information.is_active === 1) {
          setUserStatus(true);
        } else {
          setUserStatus(false);
        }
      } else {
        setUserInfoMessage(userInfoResponse.data.messages.toString());
      }
    } catch (error) {
      console.log("error", error);
      setUserInfoMessage(error.response.data.messages.toString());
      if (error.response.data.messages.length < 1) {
        setUserInfoMessage("Something went wrong");
      }
    }
    setLoading(false);
  };
  const getDropdownData = async () => {
    setLoading(true);
    try {
      let data = {
        kyb_id: slug,
      };

      let userInfoResponse = await axios({
        url: "api/kyb-details",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (userInfoResponse.status === 200) {
        setUserInfo(userInfoResponse.data.data);
        setFirstName(userInfoResponse.data.data.update_keys.first_name);
        setLastName(userInfoResponse.data.data.update_keys.last_name);
        setEmail(userInfoResponse.data.data.update_keys.email);
        setFullname(userInfoResponse.data.data.update_keys?.full_name);
        setBusinessName(userInfoResponse.data.data.update_keys?.business_name);
        setDOB(userInfoResponse.data.data.update_keys?.date_of_birth);
        setDocumentId(userInfoResponse.data.data.update_keys?.document_id);
        setLevelId(userInfoResponse.data.data.business_information.level_id);
        if (userInfoResponse.data.data.business_information.is_active === 1) {
          setUserStatus(true);
        } else {
          setUserStatus(false);
        }
        // ================================Level======================

        let userLevelResponse = await getDataWithToken(
          `/api/levels?user_type_id=${userInfoResponse.data.data.business_information.user_type_id}`
        );

        if (userLevelResponse.status === 200) {
          setLevel(userLevelResponse.data.data);
        } else {
          setLevelMessage(userLevelResponse.data.messages.toString());
          if (userLevelResponse.data.messages.length < 1) {
            setLevelMessage("Something went wrong");
          }
        }
      } else {
        setUserInfoMessage(userInfoResponse.data.messages.toString());
      }
    } catch (error) {
      console.log("error", error.response);
      setUserInfoMessage(error.response.data.messages.toString());
      if (error.response.data.messages.length < 1) {
        setUserInfoMessage("Something went wrong");
      }
    }
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    let dateCheck = moment(DOB).format("YYYY-MM-DD");
    if (dateCheck === "Invalid date") {
      setDOB(userInfo.update_keys?.date_of_birth);
    }
    setOpen(false);
  };

  const onInfoUpdate = async () => {
    setDocumentLoading(true);
    try {
      let dateCheck = moment(DOB).format("YYYY-MM-DD");
      if (DOB !== null && dateCheck === "Invalid date") {
        handleSnakbarOpen("Invalid date", "error");
        document.getElementById("dob").focus();
      } else {
        let data = {
          kyc_id:
            userInfo?.update_keys?.kyc_id !== null
              ? userInfo?.update_keys?.kyc_id
              : "",
          business_name:
            userInfo?.update_keys?.user_type_id === 2 ? businessName : "",
          document_number:
            userInfo?.update_keys?.status_editable === 1 ? documentId : "",

          first_name: firstName,
          last_name: lastName,

          full_name: fullname,
          date_of_birth: DOB !== null ? moment(DOB).format("YYYY-MM-DD") : null,
          user_id: userInfo.update_keys.user_id,
        };

        if (userInfo?.update_keys?.email_editable === 1) {
          data = { ...data, email: email };
        }
        let response = await axios({
          url: "api/customer-info-update",
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });

        handleSnakbarOpen(response.data.messages.toString(), "success");
        if (response.status === 200) {
          getDataAfterStatusUpdate();
          // let businessInfo = userInfo.business_information;
          // let kybInfo = userInfo.kyb;
          // let newBusinessInfo = {
          //   ...businessInfo,
          //   first_name: firstName,
          //   last_name: lastName,
          //   email: email,
          // };
          // let newKybInfo = {
          //   ...kybInfo,
          //   full_name: fullname,
          //   date_of_birth: moment(DOB).format("YYYY-MM-DD"),
          //   document_id: documentId,
          // };
          // let newUserInfo = {
          //   ...userInfo,
          //   business_information: newBusinessInfo,
          //   kyb: newKybInfo,
          // };
          // setUserInfo(newUserInfo);
          setOpen(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      if (error.response.data.messages.length < 1) {
        handleSnakbarOpen("Something went wrong", "error");
      }
      setDocumentLoading(false);
    }
    setDocumentLoading(false);
  };
  const navigateToCreateTicket = () => {
    navigate(`/create-ticket/${userInfo.business_information.mobile_number}`);
  };
  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "0px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={4} xl={4}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              User's Details
            </Typography>
          </Grid>
          {Object.entries(userInfo).length !== 0 && (
            <Grid item lg={8} xl={8} style={{ textAlign: "right" }}>
              <Button
                disableElevation
                variant="outlined"
                size={matches ? "small" : "large"}
                color="success"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Update Info
              </Button>
              {rollPermission?.includes("Create ticket") && (
                <>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    disableElevation
                    variant="outlined"
                    size={matches ? "small" : "large"}
                    color="info"
                    startIcon={<AddIcon />}
                    onClick={navigateToCreateTicket}
                  >
                    Create Ticket
                  </Button>
                </>
              )}
            </Grid>
          )}
        </Grid>

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="fullWidth"
            >
              <Tab
                style={{ fontWeight: 600 }}
                label="BASIC INFORMATION"
                value="1"
              />
              {rollPermission?.includes("View account details") && (
                <Tab
                  style={{ fontWeight: 600 }}
                  label="ACCOUNT DETAILS"
                  value="2"
                />
              )}
              <Tab style={{ fontWeight: 600 }} label="KYB DOCUMENT" value="3" />
            </TabList>
          </Box>

          <TabPanel
            value="1"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
          >
            <KYBBasicInformation
              businessInformation={userInfo.business_information}
              loading={loading}
              userStatus={userStatus}
              setUserStatus={setUserStatus}
              level={level}
              levelId={levelId}
              setLevelId={setLevelId}
              userInfoMessage={userInfoMessage}
              rollPermission={rollPermission}
            />
          </TabPanel>
          <TabPanel
            value="2"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
          >
            <KYBAccountDetails
              accountDetails={userInfo.account}
              loading={loading}
              userInfoMessage={userInfoMessage}
            />
          </TabPanel>
          <TabPanel
            value="3"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
          >
            <KYBDocument
              businessInformation={userInfo.business_information}
              KYCInfo={userInfo.kyb}
              loading={loading}
              level={level}
              levelId={levelId}
              userInfoMessage={userInfoMessage}
              getDataAfterStatusUpdate={getDataAfterStatusUpdate}
              rollPermission={rollPermission}
            />
          </TabPanel>
        </TabContext>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogContent style={{ minWidth: "500px", maxWidth: "500px" }}>
          <Typography
            variant="h5"
            component="div"
            style={{ marginBottom: "30px", textAlign: "center" }}
          >
            Update Personal Info
          </Typography>

          <Grid container spacing={2}>
            {userInfo?.update_keys?.user_type_id === 2 && (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Business Name"
                  variant="outlined"
                  fullWidth
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                size="small"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                fullWidth
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </Grid>
            {userInfo?.update_keys?.email_editable === 1 && (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of Birth"
                  inputFormat="yyyy/MM/dd"
                  value={DOB}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" id="dob" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {userInfo?.update_keys?.status_editable === 1 && (
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Document ID"
                  value={documentId}
                  onChange={(e) => setDocumentId(e.target.value)}
                />{" "}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            style={{ minWidth: "120px" }}
            // size="small"
            variant="contained"
            disabled={documentLoading}
            disableElevation
            onClick={onInfoUpdate}
            // startIcon={<SaveIcon size="small" />}
          >
            {/* <SendIcon size="small" /> */}
            <PulseLoader
              color={"#00848A"}
              loading={documentLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
            {documentLoading === false && "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
