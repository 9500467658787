import React, { useState, useEffect } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import moment from "moment";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },

    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& td": {

    //     padding: "12px 6px",
    //   },
    //   "& td:nth-child(n+3)": {
    //     paddingLeft: "0px",
    //   },
    // },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const DataBundleLog = () => {
  const classes = useStyles();
  const [transactionId, setTransactionId] = useState("");
  const [MSISDN, setMSISDN] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [status, setStatus] = useState("");
  const [bundleLogList, setBundleLogList] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setMSISDN("");
    setVendorName("");
    setStatus("");
    setTransactionId("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);
    let newUrl = `api/inventory/third-party-api-log`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    setLoading(true);
    let newPageNO;
    let url;
    if (pageNO) {
      newPageNO = pageNO;
    } else {
      newPageNO = 1;
    }
    if (newUrl) {
      url = newUrl;
    } else {
      let newStartingTime = "";
      let newEndingTime = "";
      if (startingTime !== null) {
        newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (endingTime !== null) {
        newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
      }

      let newVendorName = vendorName;
      if (vendorName === "None") {
        newVendorName = "";
      }
      let newStatus = status;
      if (status === "None") {
        newStatus = "";
      }

      url = `api/inventory/third-party-api-log?vendor_name=${newVendorName}&status=${newStatus}&transactionId=${transactionId.trim()}&msisdn=${MSISDN.trim()}&from=${encodeURIComponent(
        newStartingTime
      )}&to=${encodeURIComponent(newEndingTime)}&page=${newPageNO}`;
    }
    let allBundleLog = await getDataWithToken(url);
    if (allBundleLog.status === 200) {
      setBundleLogList(allBundleLog.data.data.transactions);
      setTotalData(allBundleLog.data.data.total);
      setRowsPerPage(allBundleLog.data.data.per_page);
      if (allBundleLog.data.data.transactions.length < 1) {
        setMessage("No data found");
      }
    } else {
      setMessage(allBundleLog.data.messages.toString());
      if (allBundleLog.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={9} xl={9}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            Data Bundle Request List
          </Typography>
        </Grid>
        <Grid item lg={3} xl={3} style={{ textAlign: "right" }}>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-is-assigned-outlined-label">
                    Select Vendor Name
                  </InputLabel>
                  <Select
                    labelId="demo-is-assigned-outlined-label"
                    id="demo-is-assigned-outlined"
                    label="Select Vendor Name"
                    value={vendorName}
                    onChange={(e) => setVendorName(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value={"FastLink"}>FastLink</MenuItem>
                    <MenuItem value={"Newroz"}>Newroz</MenuItem>
                    <MenuItem value={"FTTH"}>FTTH</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="transactionId"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Transaction Id."
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="MSISDN"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="MSISDN"
                  value={MSISDN}
                  onChange={(e) => setMSISDN(e.target.value)}
                />
              </Grid>

              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Starting Time"
                    value={startingTime}
                    onChange={(newValue) => {
                      setStartingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Ending Time"
                    value={endingTime}
                    onChange={(newValue) => {
                      setEndingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-is-assigned-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-is-assigned-outlined-label"
                    id="demo-is-assigned-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"success"}>Success</MenuItem>
                    <MenuItem value={"failed"}>Failed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Vendor Name
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Transaction ID
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  MSISDN
                </TableCell>
                <TableCell align="center" style={{ minWidth: "95px" }}>
                  Status
                </TableCell>
                <TableCell style={{ minWidth: "180px" }}>Message</TableCell>
                <TableCell style={{ minWidth: "140px" }}>Created At</TableCell>
                <TableCell style={{ minWidth: "140px" }}>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                bundleLogList.length > 0 &&
                bundleLogList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="center"
                      style={{
                        minWidth: "120px",
                        fontWeight: 600,
                        fontSize: "16px",

                        color: "#353b48",
                      }}
                    >
                      {row.vendor_name}
                    </TableCell>
                    <TableCell align="center">
                      {row.transaction_unique_id}
                    </TableCell>
                    <TableCell align="center">{row.msisdn}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: "120px",
                        fontWeight: 600,

                        color:
                          row.response_status === "Success"
                            ? "rgba(129,199,132,1)"
                            : row.response_status === "Pending"
                            ? "rgba(105, 110, 112,1)"
                            : "rgba(211, 47, 47,1)",
                      }}
                    >
                      {row.response_status}
                    </TableCell>
                    <TableCell>{row.response_message}</TableCell>
                    <TableCell>{row.created_at}</TableCell>
                    <TableCell>{row.updated_at}</TableCell>
                  </TableRow>
                ))}

              {!loading && bundleLogList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {bundleLogList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              // count={rows.length}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </TableContainer>
  );
};

export default DataBundleLog;
