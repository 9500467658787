import React, { useState, useContext } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ActivityLogOnCustomer from "./ActivityLogOnCustomer";
import { AuthContext } from "../../context/AuthContext";
import ActivityLogs from "./ActivityLogs";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#efefef",
    },
    "& tr:last-child": {
      background: "none",
    },
    "& tr:hover": {
      cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      cursor: "default",
      background: "none",
    },

    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
  },
}));

export default function UserLogDetail({ rollPermission }) {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [value, setValue] = React.useState("1");
  const [userInfo, setUserInfo] = useState({});
  const [additionalData, setAdditionalData] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "0px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={6} xl={6}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              User's Log Details
            </Typography>
          </Grid>
          <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
            {rollPermission?.includes("Panel user activity log") && (
              <>
                <p
                  style={{
                    color: "#353b48",
                    fontSize: "18px",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  User : {additionalData.name}
                </p>
                <p
                  style={{
                    color: "#353b48",
                    fontSize: "18px",
                    marginTop: 4,
                    marginBottom: 0,
                  }}
                >
                  Role: {additionalData.role}{" "}
                </p>
                <p
                  style={{
                    color: "#353b48",
                    fontSize: "18px",
                    marginTop: 4,
                    marginBottom: 0,
                  }}
                >
                  Email : {additionalData.email}
                </p>
              </>
            )}
          </Grid>
        </Grid>

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="fullWidth"
            >
              {rollPermission?.includes("Customer transaction search") && (
                <Tab
                  style={{
                    fontWeight: 600,
                  }}
                  label="Activity Log On Customer"
                  value="1"
                />
              )}
              {rollPermission?.includes("Customer activity log") && (
                <Tab
                  style={{ fontWeight: 600 }}
                  label="Activity Log"
                  value="2"
                />
              )}
            </TabList>
          </Box>

          <TabPanel
            value="1"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <ActivityLogOnCustomer
              rollPermission={rollPermission}
              additionalData={additionalData}
              setAdditionalData={setAdditionalData}
            />
          </TabPanel>

          <TabPanel
            value="2"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            {" "}
            <ActivityLogs basicInformation={userInfo?.basic_information} />
          </TabPanel>
        </TabContext>
      </TableContainer>
    </>
  );
}
