import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthContext } from "../../context/AuthContext";
import { getDataWithToken } from "../../services/GetDataService";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    // textAlign: "center",
    width: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  titleBox: {
    background: "#eaecf3",
    fontSize: "1.5rem",
    // fontWeight: 600,
    textAlign: "center",
    padding: "5px",
    borderRadius: "8px",
    width: "50%",
    margin: "0px auto 30px",
    fontFamily: "'Roboto', sans-serif",
  },
}));

const AddUser = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roleMessage, setRoleMessage] = useState("");
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const validation = () => {
    let isError = false;
    if (!name.trim()) {
      handleSnakbarOpen("Please enter user name", "error");
      document.getElementById("name").focus();
      return (isError = true);
    }
    if (!email.trim()) {
      handleSnakbarOpen("Please enter email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      handleSnakbarOpen("Invalid email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    }
    if (!roleId.trim()) {
      handleSnakbarOpen("Please select user role", "error");

      return (isError = true);
    }

    return isError;
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let err = validation();

    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let data = {
          name,
          email,
          role_name: roleId,
        };
        let response = await axios({
          url: "api/create-user",
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        if (response.data.code === 200) {
          handleSnakbarOpen(response.data.messages.toString(), "success");
          setName("");
          setEmail("");
          setRoleId("");
        }
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        if (error.response.data.messages.length < 1) {
          handleSnakbarOpen("Something went wrong", "error");
        }
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const getDropdownData = async () => {
    let allRoles = await getDataWithToken(`api/roles`);
    if (allRoles.status === 200) {
      setRoleList(allRoles.data.data);
    } else {
      setRoleMessage(allRoles.data.messages.toString());
      if (allRoles.data.messages.length < 1) {
        setRoleMessage("Something went wrong");
      }
    }
  };
  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "80vh" }}
      >
        <div className={classes.form}>
          {/* <Typography
            variant="h5"
            component="div"
            style={{ marginBottom: "30px", textAlign: "center" }}
          >
            Add User
          </Typography> */}
          <div className={classes.titleBox}>Add User</div>
          <TextField
            id="name"
            label="Enter user name"
            fullWidth
            size="small"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "30px" }}
          />
          <TextField
            label="Enter user email address"
            fullWidth
            size="small"
            className={classes.inputStyle}
            style={{ marginBottom: "30px" }}
            variant="outlined"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            style={{ marginBottom: "30px" }}
          >
            <InputLabel id="demo-issue-outlined-label">Select role</InputLabel>
            <Select
              labelId="demo-issue-outlined-label"
              id="demo-issue-outlined"
              label="Select role"
              value={roleId}
              onChange={(e) => setRoleId(e.target.value)}
            >
              {roleMessage.length > 0 && (
                <MenuItem value={roleMessage}>{roleMessage}</MenuItem>
              )}
              {roleList?.map((item, i) => (
                <MenuItem key={i} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            disableElevation
            fullWidth
            style={{ marginBottom: "30px" }}
            disabled={loading}
            onClick={onSubmit}
          >
            {loading === false && "Create & Add Another"}
            <PulseLoader
              color={"#00848A"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default AddUser;
