import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const ZichargeAgentList = ({ rollPermission }) => {
  const classes = useStyles();
  const [mobileNumber, setMobileNumber] = useState("");
  const [dataList, setDataList] = useState([]);

  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [filterOpen, setFilterOpen] = useState(true);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateLoading, setUpdateLoading] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleSwitchClose = () => {
    setSwitchOpen(false);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const clearFilter = () => {
    setMobileNumber("");

    let newUrl = `api/zicharge-agent-list`;
    getData(newUrl);
  };

  const handleSrarch = () => {
    getData();
  };

  const getData = async (newUrl) => {
    try {
      setLoading(true);

      let url;

      if (newUrl) {
        url = newUrl;
      } else {
        url = `api/zicharge-agent-list?mobile_number=${encodeURIComponent(
          mobileNumber.trim()
        )}`;
      }
      let allZichargeAgent = await getDataWithToken(url);
      console.log("allZichargeAgent.data.data.data", allZichargeAgent);
      if (allZichargeAgent.status === 200) {
        setDataList(allZichargeAgent.data.data);

        if (allZichargeAgent.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(allZichargeAgent.data.messages.toString());
        if (allZichargeAgent.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };

  const updateStatus = async (e) => {
    e.preventDefault();

    try {
      setUpdateLoading(true);
      let newStatus;
      if (newUpdateValue.value) {
        newStatus = 1;
      } else {
        newStatus = 0;
      }

      let data = {
        user_id: newUpdateValue.data.user_id,
      };
      // console.log("data", newUpdateValue);
      let response = await axios({
        url: `api/zicharge-agent-update`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        getData();

        handleSnakbarOpen(response.data.messages.toString(), "success");
        setSwitchOpen(false);
      }

      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      if (error.response.data.messages.length < 1) {
        handleSnakbarOpen("Something went wrong", "error");
      }
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={12} xl={12}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              Zicharge Agent List
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={filterOpen} timeout="auto" unmountOnExit>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <TextField
                    id="mobile-no"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Mobile No"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={{ lg: 6, xl: 3 }}>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={clearFilter}
                      >
                        <RestartAltIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        disableElevation
                        // color="success"
                        style={{ background: "#435185", padding: "6.7px 22px" }}
                        size="large"
                        startIcon={<SearchIcon />}
                        fullWidth
                        onClick={handleSrarch}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <br />
        <>
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 370px)",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell style={{ minWidth: "230px" }}>
                    Agent Info
                  </TableCell>

                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Status
                  </TableCell>

                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!loading &&
                  dataList.length > 0 &&
                  dataList.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {/* <TableCell style={{ paddingRight: 0, width: "50px" }}>
                        <Avatar
                          alt=""
                          src={row.merchant?.photo}
                          sx={{ width: 45, height: 45 }}
                        />
                      </TableCell> */}
                      <TableCell>
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ fontSize: "16px", fontWeight: 500 }}
                        >
                          {row.name}
                        </Typography>
                        {row.mobile_no}
                      </TableCell>

                      <TableCell align="center">
                        {" "}
                        <img
                          src={
                            row.status === 1
                              ? "/image/verified.svg"
                              : "/image/unverified.svg"
                          }
                          alt=""
                          height="20px"
                          style={{ position: "relative", top: 5 }}
                        />
                        &nbsp;
                        {row.status === 1 ? (
                          <span style={{ color: "rgba(129,199,132,1)" }}>
                            Active &nbsp;&nbsp;
                          </span>
                        ) : (
                          <span style={{ color: "rgba(211, 47, 47,1)" }}>
                            Inactive
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          color="success"
                          checked={row.status === 1 ? true : false}
                          disabled={row.status === 1 ? true : false}
                          onChange={(e) => {
                            setNewUpdateValue({
                              index: i,
                              data: row,
                              value: e.target.checked,
                            });
                            setSwitchOpen(true);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                {!loading && dataList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
        </>
      </TableContainer>

      <Dialog
        open={switchOpen}
        onClose={handleSwitchClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to active {newUpdateValue?.data?.name} (
              {newUpdateValue?.data?.mobile_no})
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSwitchClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateStatus}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ZichargeAgentList;
