import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PulseLoader from "react-spinners/PulseLoader";
import { CopyBlock, dracula } from "react-code-blocks";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment";
import Menu from "@mui/material/Menu";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
      fontWeight: 600,
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tableHeadStyle2: {
    // background: "#2B335E",
    "& th": {
      // color: "#fff",
      fontSize: "20px",
      borderBottom: "2px solid #353b48",
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
}));

const Transaction = ({ rollPermission, mobileNo }) => {
  const classes = useStyles();
  const [allTransactions, SetAllTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [transactionIdPurchaseLifecycle, setTransactionIdPurchaseLifecycle] =
    useState("");
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [purchaseCycleTimeData, setPurchaseLifeCycleData] = useState([]);
  const [resChecked, setResChecked] = React.useState(false);
  const [resIndexNo, setResIndexNo] = useState();
  const [reqChecked, setReqChecked] = React.useState(false);
  const [reqIndexNo, setReqIndexNo] = useState();
  const [purchaseCycleTimeMessage, setPurchaseLifeCycleMessage] = useState("");
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [transactionId, setTransactionId] = useState("");
  const [email, setEmail] = useState("");
  const [startAmount, setStartAmount] = useState("");
  const [endAmount, setEndAmount] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState("");
  const [transactionTypesList, setTransactionTypesList] = useState([]);
  const [transactionTypesId, setTransactionTypesId] = useState("");
  const [transactionStatusMsg, setTransactionStatusMsg] = useState("");
  const [transactionTypeMsg, setTransactionTypeMsg] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [order, setOrder] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadloading, setDownloadloading] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDetailDialogClickOpen = () => {
    setDetailOpen(true);
  };

  const handleDetailDialogClose = () => {
    setDetailOpen(false);
    setPurchaseLifeCycleData([]);
    setReqIndexNo("");
    setResIndexNo("");
    setPurchaseLifeCycleMessage("");
  };
  const handleReqChange = (i) => {
    setReqIndexNo(i);
    if (reqIndexNo !== i) {
      setReqChecked(true);
    } else {
      setReqChecked(!reqChecked);
    }
  };
  const handleResChange = (i) => {
    setResIndexNo(i);
    if (resIndexNo !== i) {
      setResChecked(true);
    } else {
      setResChecked(!resChecked);
    }
  };
  const pageLoading2 = () => {
    let content = [];

    for (let i = 0; i < 5; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell style={{ paddingRight: 0, width: "50px" }}>
            <Skeleton variant="circular" width={45} height={45} />
          </TableCell>
          <TableCell>
            <Typography
              gutterBottom
              component="div"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              <Skeleton></Skeleton>
            </Typography>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell style={{ paddingRight: 0, width: "50px" }}>
            <Skeleton variant="circular" width={45} height={45} />
          </TableCell>
          <TableCell>
            <Typography
              gutterBottom
              component="div"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              <Skeleton></Skeleton>
            </Typography>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleOrderChange = (orderBy) => {
    setOrder(orderBy);
    setPage(0);
    let newOrderBy = 1;
    let newStartingTime = "";
    let newEndingTime = "";
    let newTransactionTypesId = transactionTypesId;
    let newStatus = status;
    if (orderBy === false) {
      newOrderBy = "";
    }
    if (startingTime !== null) {
      newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (endingTime !== null) {
      newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (transactionTypesId === "None") {
      newTransactionTypesId = "";
    }
    if (status === "None") {
      newStatus = "";
    }
    let newStartAmount = "";
    let newEndAmount = "";
    if (startAmount.trim().length > 0) {
      newStartAmount = parseInt(startAmount.trim());
    }
    if (endAmount.trim().length > 0) {
      newEndAmount = parseInt(endAmount.trim());
    }
    let newData = {
      mobile_number: mobileNo,
      email: email.trim(),
      from_amount: newStartAmount,
      to_amount: newEndAmount,
      transaction_id: transactionId.trim(),
      transaction_type: newTransactionTypesId,
      transaction_status: newStatus,
      reference_id: referenceId.trim(),
      from_date: newStartingTime,
      to_date: newEndingTime,
      order_by_desc: newOrderBy,
      page: 1,
    };
    getData("", newData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const clearFilter = (event) => {
    setTransactionId("");
    setEmail("");
    setTransactionTypesId("");
    setStatus("");
    setStartingTime(null);
    setEndingTime(null);
    setStartAmount("");
    setEndAmount("");
    setReferenceId("");

    setPage(0);

    let newData = {
      mobile_number: mobileNo,
      order_by_desc: 1,
      page: 1,
    };
    setOrder(true);
    getData("", newData);
  };
  const getData = async (pageNO, newData) => {
    try {
      setLoading(true);
      let newPageNO;
      let data;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newData) {
        data = newData;
      } else {
        let newOrderBy = 1;
        let newStartingTime = "";
        let newEndingTime = "";
        let newTransactionTypesId = transactionTypesId;
        let newStatus = status;

        if (startingTime !== null) {
          newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
        }
        if (endingTime !== null) {
          newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
        }

        if (transactionTypesId === "None") {
          newTransactionTypesId = "";
        }
        if (status === "None") {
          newStatus = "";
        }
        if (order === false) {
          newOrderBy = "";
        }

        let newStartAmount = "";
        let newEndAmount = "";
        if (startAmount.trim().length > 0) {
          newStartAmount = parseInt(startAmount.trim());
        }
        if (endAmount.trim().length > 0) {
          newEndAmount = parseInt(endAmount.trim());
        }
        data = {
          mobile_number: mobileNo,
          email: email.trim(),
          from_amount: newStartAmount,
          to_amount: newEndAmount,
          transaction_id: transactionId.trim(),
          transaction_type: newTransactionTypesId,
          transaction_status: newStatus,
          reference_id: referenceId.trim(),
          from_date: newStartingTime,
          to_date: newEndingTime,
          order_by_desc: newOrderBy,
          page: newPageNO,
        };
      }

      let transactions = await axios({
        url: `api/customer-transaction-info`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (transactions.data.code === 200) {
        SetAllTransactions(transactions.data.data.data);
        setTotalData(transactions.data.data.total);
        if (transactions.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(transactions.data.messages);
        if (transactions.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setMessage(error.response.data.messages.toString());
      setLoading(false);
    }
  };
  const downloadFile = async (type) => {
    setDownloadloading(true);
    try {
      let data;

      let newOrderBy = 1;
      let newStartingTime = "";
      let newEndingTime = "";
      let newTransactionTypesId = transactionTypesId;
      let newStatus = status;

      if (startingTime !== null) {
        newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (endingTime !== null) {
        newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
      }

      if (transactionTypesId === "None") {
        newTransactionTypesId = "";
      }
      if (status === "None") {
        newStatus = "";
      }
      if (order === false) {
        newOrderBy = "";
      }

      let newStartAmount = "";
      let newEndAmount = "";
      if (startAmount.trim().length > 0) {
        newStartAmount = parseInt(startAmount.trim());
      }
      if (endAmount.trim().length > 0) {
        newEndAmount = parseInt(endAmount.trim());
      }
      data = {
        mobile_number: mobileNo,
        email: email.trim(),
        from_amount: newStartAmount,
        to_amount: newEndAmount,
        transaction_id: transactionId.trim(),
        transaction_type: newTransactionTypesId,
        transaction_status: newStatus,
        reference_id: referenceId.trim(),
        from_date: newStartingTime,
        to_date: newEndingTime,
        order_by_desc: newOrderBy,
        download: "excel",
        download_type: type,
      };

      let transactions = await axios({
        url: `api/customer-transaction-info`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
        responseType: "blob", // important
      });

      if (transactions.status === 200) {
        const url = window.URL.createObjectURL(new Blob([transactions.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `Transactions Report.${type}`);

        document.body.appendChild(link);
        link.click();

        link.remove();

        handleClose();

        handleSnakbarOpen("File download successfully", "success");
      } else {
        handleSnakbarOpen("Something went wrong", "error");
        handleClose();
      }
      setDownloadloading(false);
    } catch (error) {
      const isJsonBlob = (data) =>
        data instanceof Blob && data.type === "application/json";
      const responseData = isJsonBlob(error.response?.data)
        ? await error.response?.data?.text()
        : error.response?.data || {};
      const responseJson =
        typeof responseData === "string"
          ? JSON.parse(responseData)
          : responseData;
      console.log("error", responseJson);
      handleSnakbarOpen(responseJson?.messages.toString(), "error");
      handleClose();
      setDownloadloading(false);
    }
    setDownloadloading(false);
  };

  const getById = async (id) => {
    try {
      setTransactionIdPurchaseLifecycle(id);
      setTableLoading(true);
      handleDetailDialogClickOpen();
      let ProductLifeCycle = await axios({
        url: `api/bundle-purchase-lifecycle`,
        method: "post",
        data: {
          transaction_id: id,
        },
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (ProductLifeCycle.data.code === 200) {
        setPurchaseLifeCycleData(ProductLifeCycle.data.data);

        if (ProductLifeCycle.data.data.length < 1) {
          setPurchaseLifeCycleMessage("No data found");
        }
      }

      if (ProductLifeCycle.status !== 200) {
        setPurchaseLifeCycleMessage(ProductLifeCycle.data.messages.toString());
      }
    } catch (error) {
      console.log("error", error);
      setPurchaseLifeCycleMessage(error.response.data.messages.toString());
      setTableLoading(false);
    }
    setTableLoading(false);
  };
  const getDropdownData = async () => {
    if (mobileNo !== undefined) {
      let allStatus = await getDataWithToken(`api/transaction-statuses`);
      if (allStatus.status === 200) {
        setStatusList(allStatus.data.data);
      } else {
        setTransactionStatusMsg(allStatus.data.messages.toString());
        if (allStatus.data.messages.length < 1) {
          setTransactionStatusMsg("Something went wrong");
        }
      }
      let allTransactionTypes = await getDataWithToken(`api/transaction-types`);
      if (allTransactionTypes.status === 200) {
        setTransactionTypesList(allTransactionTypes.data.data);
      } else {
        setTransactionTypeMsg(allTransactionTypes.data.messages.toString());
        if (allTransactionTypes.data.messages.length < 1) {
          setTransactionTypeMsg("Something went wrong");
        }
      }
    }
  };

  const showFilterButtons = () => {
    if (rollPermission?.includes("download transaction report")) {
      return (
        <Grid item md={4} lg={3.5} xl={2.4}>
          <Grid container>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                // fullWidth
                onClick={(event) => clearFilter(event, 0)}
              >
                <RestartAltIcon />
              </Button>
            </Grid>

            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                disableElevation
                // color="success"
                style={{
                  background: "#435185",
                  padding: "6.7px 22px",
                  zIndex: 1,
                }}
                size="large"
                startIcon={<SearchIcon />}
                // fullWidth
                onClick={(event) => handleChangePage(event, 0)}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <div>
                <Tooltip title="Download File" placement="top">
                  <Button
                    variant="contained"
                    color="success"
                    disableElevation
                    size="large"
                    id="basic-button"
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    disabled={downloadloading}
                    onClick={handleClick}
                    style={{ maxHeight: "40px" }}
                    // fullWidth
                  >
                    {downloadloading === false && <DownloadIcon />}
                    <PulseLoader
                      color={"#00848A"}
                      loading={downloadloading}
                      size={5}
                      speedMultiplier={0.5}
                    />{" "}
                  </Button>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      downloadFile("xlsx");
                    }}
                  >
                    Export Excel File
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      downloadFile("csv");
                    }}
                  >
                    Export CSV File
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item md={3} lg={3} xl={2.4}>
          <Grid container spacing={{ md: 4, lg: 4, xl: 1 }}>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                fullWidth
                onClick={(event) => clearFilter(event, 0)}
              >
                <RestartAltIcon />
              </Button>
            </Grid>

            <Grid item xs={9}>
              <Button
                variant="contained"
                disableElevation
                // color="success"
                style={{ background: "#435185", padding: "6.7px 22px" }}
                size="large"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={(event) => handleChangePage(event, 0)}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };
  useEffect(() => {
    getData();
    getDropdownData();
  }, []);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={3} lg={3} xl={2.4}>
          <TextField
            id="transaction-id"
            fullWidth
            size="small"
            variant="outlined"
            label="Transaction Id"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
          />
        </Grid>
        <Grid item md={3} lg={3} xl={2.4}>
          <TextField
            id="email"
            fullWidth
            size="small"
            variant="outlined"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item sm={3} md={3} lg={3} xl={2.4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-status-outlined-label">
              Transaction Types
            </InputLabel>
            <Select
              labelId="demo-status-outlined-label"
              id="demo-status-outlined"
              label=" Transaction Types"
              value={transactionTypesId}
              onChange={(e) => setTransactionTypesId(e.target.value)}
            >
              <MenuItem value="None">
                {transactionTypeMsg.length > 0 ? (
                  <>{transactionTypeMsg}</>
                ) : (
                  "None"
                )}
              </MenuItem>
              {transactionTypesList?.map((item, i) => (
                <MenuItem key={i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3} md={3} lg={3} xl={2.4}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-status-outlined-label">Status</InputLabel>
            <Select
              labelId="demo-status-outlined-label"
              id="demo-status-outlined"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="None">
                {transactionStatusMsg.length > 0 ? (
                  <>{transactionStatusMsg}</>
                ) : (
                  "None"
                )}
              </MenuItem>
              {statusList?.map((item, i) => (
                <MenuItem key={i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} lg={3} xl={2.4}>
          <TextField
            id="reference-id"
            fullWidth
            size="small"
            variant="outlined"
            label="Reference Id"
            value={referenceId}
            onChange={(e) => setReferenceId(e.target.value)}
          />
        </Grid>
        <Grid item md={3} lg={3} xl={2.4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField {...props} size="small" fullWidth />
              )}
              label="Starting Time"
              value={startingTime}
              onChange={(newValue) => {
                setStartingTime(newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={3} lg={3} xl={2.4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField {...props} size="small" fullWidth />
              )}
              label="Ending Time"
              value={endingTime}
              onChange={(newValue) => {
                setEndingTime(newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item md={3} lg={3} xl={2.4}>
          <TextField
            id="start-amount"
            type="number"
            fullWidth
            size="small"
            variant="outlined"
            label="Start Amount"
            value={startAmount}
            onChange={(e) => setStartAmount(e.target.value)}
          />
        </Grid>
        <Grid item md={3} lg={3} xl={2.4}>
          <TextField
            id="end-amount"
            type="number"
            fullWidth
            size="small"
            variant="outlined"
            label="End Amount"
            value={endAmount}
            onChange={(e) => setEndAmount(e.target.value)}
          />
        </Grid>

        {showFilterButtons()}
      </Grid>
      <br />
      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell style={{ minWidth: "150px" }} colSpan={2}>
                  Transaction Id
                </TableCell>
                <TableCell colSpan={2} style={{ minWidth: "200px" }}>
                  Sender
                </TableCell>
                <TableCell colSpan={2} style={{ minWidth: "200px" }}>
                  Receiver
                </TableCell>
                <TableCell style={{ minWidth: "120px" }}>Type</TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Amount
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Charge
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Total Amount
                </TableCell>
                <TableCell align="center" style={{ minWidth: "140px" }}>
                  Current Balance
                </TableCell>
                <TableCell style={{ minWidth: "90px" }}>Status</TableCell>
                <TableCell style={{ minWidth: "150px" }}>
                  Date
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOrderChange(!order)}
                  >
                    {order ? (
                      <KeyboardArrowUpIcon style={{ color: "#fff" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell style={{ minWidth: "110px" }}>
                  Reference Id
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                allTransactions.length > 0 &&
                allTransactions.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{
                        paddingRight: 0,
                        paddingLeft: "5px",
                        width: "30px",
                      }}
                    >
                      {rollPermission?.includes("Purchase lifecycle") && (
                        <LightTooltip
                          title="View Purchase Lifecycle"
                          placement="top"
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              getById(row.transaction_id);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </LightTooltip>
                      )}
                    </TableCell>
                    <TableCell style={{ paddingRight: 0, paddingLeft: "5px" }}>
                      {row.transaction_id}
                    </TableCell>
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row.sender?.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.sender?.name}
                      </Typography>
                      {row.sender?.mobile_no}
                    </TableCell>
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row.receiver?.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.receiver?.name}
                      </Typography>
                      {row.receiver?.mobile_no}
                    </TableCell>
                    <TableCell>{row.transaction_type}</TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    <TableCell align="center">{row.charge}</TableCell>
                    <TableCell align="center">{row.total_amount}</TableCell>
                    <TableCell align="center">
                      {row.sender_current_balance}
                    </TableCell>
                    <TableCell>{row.transaction_status}</TableCell>
                    <TableCell>{row.created_at}</TableCell>
                    <TableCell>
                      {row.reference_id === null ? "N/A" : row.reference_id}
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && allTransactions.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={11} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {allTransactions.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
        <Dialog
          onClose={handleDetailDialogClose}
          aria-labelledby="simple-dialog-title"
          open={detailOpen}
          maxWidth={"1250px"}
        >
          <div id="simple-dialog-title" style={{ padding: "16px 16px" }}>
            <Typography variant="h4">Purchase Lifecycle</Typography>
            <Typography
              variant="h6"
              style={{
                marginTop: "10px",
                color: "#9b9696",
                letterSpacing: "1px",
              }}
            >
              Transaction ID: {transactionIdPurchaseLifecycle}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleDetailDialogClose}
              style={{ position: "absolute", top: 13, right: 10 }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <Table
            style={{ minWidth: "1250px", maxWidth: "1250px" }}
            aria-label="simple table"
          >
            <TableHead className={classes.tableHeadStyle2}>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Request</TableCell>
                <TableCell>Response</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!tableLoading && purchaseCycleTimeData < 1 && (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    {purchaseCycleTimeMessage}
                  </TableCell>
                </TableRow>
              )}
              {tableLoading ? (
                pageLoading2()
              ) : (
                <>
                  {purchaseCycleTimeData &&
                    purchaseCycleTimeData.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ verticalAlign: "top" }}
                        >
                          {row.time}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          <span style={{ color: "red" }}>{row.app_name}</span> |{" "}
                          {row.title}
                        </TableCell>
                        <TableCell
                          className={classes.codeBlockStyle}
                          style={{ verticalAlign: "top" }}
                        >
                          <Collapse
                            in={i === reqIndexNo && reqChecked}
                            collapsedSize={80}
                          >
                            <div style={{ position: "relative" }}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => handleReqChange(i)}
                                style={{
                                  display: "block",
                                  position: "absolute",
                                  top: 10,
                                  right: 10,
                                  zIndex: 1,
                                }}
                              >
                                {reqIndexNo === i && reqChecked ? (
                                  <KeyboardArrowUpIcon
                                    size="small"
                                    style={{
                                      color: "#fff",
                                      background: "#959292",
                                      borderRadius: "25px",
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    size="small"
                                    style={{
                                      color: "#fff",
                                      background: "#959292",
                                      borderRadius: "25px",
                                    }}
                                  />
                                )}
                              </IconButton>
                              {row.request_payload && (
                                <CopyBlock
                                  text={JSON.stringify(
                                    row.request_payload,
                                    null,
                                    4
                                  )}
                                  language={"jsx"}
                                  showLineNumbers={false}
                                  theme={dracula}
                                  codeBlock
                                  wrapLongLines={true}
                                />
                              )}
                            </div>
                          </Collapse>
                        </TableCell>
                        <TableCell
                          className={classes.codeBlockStyle}
                          style={{ verticalAlign: "top" }}
                        >
                          <Collapse
                            in={i === resIndexNo && resChecked}
                            collapsedSize={80}
                          >
                            <div style={{ position: "relative" }}>
                              <IconButton
                                size="small"
                                onClick={() => handleResChange(i)}
                                style={{
                                  display: "block",
                                  position: "absolute",
                                  top: 10,
                                  right: 10,
                                  zIndex: 1,
                                }}
                              >
                                {resIndexNo === i && resChecked ? (
                                  <KeyboardArrowUpIcon
                                    size="small"
                                    style={{
                                      color: "#fff",
                                      background: "#959292",
                                      borderRadius: "25px",
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    size="small"
                                    style={{
                                      color: "#fff",
                                      background: "#959292",
                                      borderRadius: "25px",
                                    }}
                                  />
                                )}
                              </IconButton>
                              {row.response_payload && (
                                <CopyBlock
                                  text={JSON.stringify(
                                    row.response_payload,
                                    null,
                                    4
                                  )}
                                  language={"jsx"}
                                  showLineNumbers={false}
                                  theme={dracula}
                                  codeBlock
                                  wrapLongLines={true}
                                />
                              )}
                            </div>
                          </Collapse>
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {row.status}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </Dialog>
      </Paper>
    </>
  );
};

export default Transaction;
