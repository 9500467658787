import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import CountUp from "react-countup";
import BlockIcon from "@mui/icons-material/Block";
import { getDataWithToken } from "../../services/GetDataService";
import PauseIcon from "@mui/icons-material/Pause";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { useNavigate } from "react-router-dom";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { useSnackbar } from "notistack";
import Skeleton from "@mui/material/Skeleton";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    width: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  card: {
    padding: "15px 20px",
    borderRadius: "10px",
    position: "relative",
    // minHeight: "32vh",
    height: "100%",
    overflow: "hidden",
    // cursor: "pointer",
    boxSizing: "border-box",
    // display: "flex",
    // alignItems: "center",
    minHeight: "250px",
  },
  cardBg1: {
    background:
      "linear-gradient(56deg, rgba(13,71,161,1) 0%, rgba(33,150,243,1) 100%)",
  },
  cardBg2: {
    background:
      "linear-gradient(90deg, rgba(56,142,60,1) 0%, rgba(129,199,132,1) 100%)",
  },
  cardBg3: {
    background:
      "linear-gradient(90deg, rgba(245,124,0,1) 0%, rgba(255,183,77,1) 100%)",
  },
  cardBg4: {
    background:
      "linear-gradient(90deg, rgba(0,121,107,1) 0%, rgba(77,182,172,1) 100%)",
  },
  cardBg5: {
    background:
      "linear-gradient(90deg, rgba(81,45,168,1) 0%, rgba(149,117,205,1) 100%)",
  },
  cardBg6: {
    background:
      "linear-gradient(90deg, rgba(0,151,167,1) 0%, rgba(77,208,225,1) 100%)",
  },
  cardIcon: {
    position: "absolute",
    fontSize: "34px !important",

    right: 20,
    // fontSize: "320px !important",
    // bottom: -80,
    // right: -20,
    opacity: 0.5,
    [theme.breakpoints.down("xl")]: {
      fontSize: "24px !important",
    },
  },
  white: {
    fontSize: "1rem !important",

    color: "white",
    marginBottom: "5px !important",
    padding: "0px 10px",
    borderRadius: "10px",
    background: "rgba(255,255,255,.1)",
    cursor: "pointer",
    "& span": {
      float: "right",
    },
    "&:hover": {
      background: "rgba(255,255,255,.5)",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.75rem !important",
    },
  },
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
  },
  skeletonStyle: {
    height: "250px !important",
    [theme.breakpoints.down("xl")]: {
      height: "139px !important",
    },
  },
  titleStyle: {
    fontSize: "1.5rem !important",
    textAlign: "center",
    color: "#fff",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1rem !important",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  let navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [cardData, setCardData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    let allCardData = await getDataWithToken("api/dashboard/statistics");
    if (allCardData.data.code === 200) {
      setCardData(allCardData.data.data);
    } else {
      setMessage(allCardData.data.messages.toString());
      if (allCardData.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
    setLoading(false);
  };

  const fnNavigate = (title, menu) => {
    return;
    console.log("title || menu", title, menu);
    if (title === "KYC Requests") {
      switch (menu) {
        case "Partial":
          navigate("/kyc-partial-request");
          break;
        case "Declined":
          navigate("/kyc-declined-request");
          break;
        case "Manual":
          navigate("/kyc-manual-request");
          break;
        case "Digital":
          navigate("/kyc-digital-request");
          break;
        case "Approved":
          navigate("/kyc-Approved-request");
          break;
        case "Agent Verified":
          navigate("/kyc-agent-verified-request");
          break;

        default:
          break;
      }
    } else if (title === "KYB Requests") {
      navigate("/kyb-request", { state: menu });
    } else if (title === "Block Accounts") {
      navigate("/block-user-list");
    } else if (title === "Tickets") {
      navigate("/tickets", { state: menu });
    } else if (title === "Customer Registered") {
      switch (menu) {
        case "New Personal Account":
          let newMenu = { userStatus: 8, usertype: 4 };
          navigate("/user-list", { state: newMenu });
          break;

        default:
          break;
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const bg = [
    "linear-gradient(56deg, rgba(13,71,161,1) 0%, rgba(33,150,243,1) 100%)",

    "linear-gradient(90deg, rgba(56,142,60,1) 0%, rgba(129,199,132,1) 100%)",

    "linear-gradient(90deg, rgba(0,121,107,1) 0%, rgba(77,182,172,1) 100%)",

    "linear-gradient(90deg, rgba(81,45,168,1) 0%, rgba(149,117,205,1) 100%)",

    "linear-gradient(90deg, rgba(245,124,0,1) 0%, rgba(255,183,77,1) 100%)",

    "linear-gradient(90deg, rgba(0,151,167,1) 0%, rgba(77,208,225,1) 100%)",
  ];
  return (
    <React.Fragment>
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "85vh" }}
        >
          <div className={classes.form}>
            <img
              src="/image/Logo.png"
              alt=""
              style={{ display: "block", margin: "auto", maxWidth: "155px" }}
            />
            <br />
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "30px" }}
            >
              {message}
            </Typography>
          </div>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2} style={{ marginBottom: "30px" }}>
            {loading ? (
              [1, 2, 3].map((item, i) => (
                <Grid item xs={4} key={i}>
                  <Skeleton
                    variant="rectangular"
                    className={classes.skeletonStyle}
                  />
                </Grid>
              ))
            ) : (
              <>
                {cardData?.map((item, i) => {
                  let myBackground = bg[i];

                  return (
                    <Grid item xs={4} key={i}>
                      <>
                        <div
                          className={`${classes.card}  `}
                          style={{
                            background: myBackground,
                          }}
                          // onClick={() => navigate("/kyc-request")}
                        >
                          <Typography className={classes.titleStyle}>
                            {item.title}

                            {i === 0 && (
                              <PaidOutlinedIcon className={classes.cardIcon} />
                            )}
                            {i === 1 && (
                              <SwitchAccountOutlinedIcon
                                className={classes.cardIcon}
                              />
                            )}
                            {i === 2 && (
                              <ConfirmationNumberIcon
                                className={classes.cardIcon}
                              />
                            )}
                            {i === 3 && (
                              <AppRegistrationIcon
                                className={classes.cardIcon}
                              />
                            )}
                            {i === 4 && (
                              <ConfirmationNumberIcon
                                className={classes.cardIcon}
                              />
                            )}
                          </Typography>

                          <br />
                          {item.menu.map((el, i) => (
                            <Typography
                              className={classes.white}
                              variant="body1"
                              key={i}
                              onClick={() => fnNavigate(item.title, el.name)}
                            >
                              {el.name}

                              <CountUp
                                start={0}
                                end={el.count}
                                duration={0.5}
                              />
                            </Typography>
                          ))}
                        </div>
                      </>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

export default Home;
