import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import TablePagination from "@mui/material/TablePagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CopyBlock, dracula } from "react-code-blocks";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
      fontWeight: 600,
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tableHeadStyle2: {
    // background: "#2B335E",
    "& th": {
      // color: "#fff",
      fontSize: "20px",
      borderBottom: "2px solid #353b48",
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
}));

const SupportActivityLogs = ({ basicInformation, userInfoMessage }) => {
  const classes = useStyles();
  const [activityLogsData, setActivityLogsData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [eventPayload, setEventPayload] = useState({});
  const [beforeBlockOpen, setBeforeBlockOpen] = useState(false);
  const [afterBlockOpen, setAfterBlockOpen] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [purpose, setPurpose] = useState("");
  const [purposeList, setPurposeList] = useState([]);
  const [purposeListMessage, setPurposeListMessage] = useState([]);
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [order, setOrder] = useState(true);

  const handleDetailDialogClickOpen = () => {
    setDetailOpen(true);
  };

  const handleDetailDialogClose = () => {
    setDetailOpen(false);
    setEventPayload({});
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>

          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));
  const clearFilter = (event) => {
    setPurpose("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);
    setOrder(true);
    let newData = {
      customer_id: basicInformation?.user_id,
      purpose: "",
      from: "",
      to: "",
      order_by_desc: 1,
      page: 1,
    };
    getActivityList("", newData);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getActivityList(pageNo);
    setPage(newPage);
  };
  const handleOrderChange = (orderBy) => {
    setOrder(orderBy);
    setPage(0);
    let newOrderBy = 1;
    let newStartingTime = "";
    let newEndingTime = "";
    if (orderBy === false) {
      newOrderBy = "";
    }
    if (startingTime !== null) {
      newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (endingTime !== null) {
      newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    let newPurpose = purpose;
    if (purpose === "None") {
      newPurpose = "";
    }
    let newData = {
      customer_id: basicInformation?.user_id,
      purpose: newPurpose,
      from: newStartingTime,
      to: newEndingTime,
      order_by_desc: newOrderBy,
      page: 1,
    };
    getActivityList("", newData);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getActivityList = async (pageNO, newData) => {
    if (basicInformation?.user_id === undefined) {
      setMessage(userInfoMessage);
    } else {
      try {
        setLoading(true);
        let newPageNO;
        let data;
        if (pageNO) {
          newPageNO = pageNO;
        } else {
          newPageNO = 1;
        }
        if (newData) {
          data = newData;
        } else {
          let newOrderBy = 1;
          let newStartingTime = "";
          let newEndingTime = "";
          if (startingTime !== null) {
            newStartingTime = moment(startingTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          if (endingTime !== null) {
            newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
          }
          let newPurpose = purpose;
          if (purpose === "None") {
            newPurpose = "";
          }
          if (order === false) {
            newOrderBy = "";
          }
          data = {
            customer_id: basicInformation?.user_id,
            purpose: newPurpose,
            from: newStartingTime,
            to: newEndingTime,
            order_by_desc: newOrderBy,
            page: newPageNO,
          };
        }

        let ActivityLogsResponse = await axios({
          url: `api/customer-audit-trail-list`,
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });

        if (ActivityLogsResponse.data.code === 200) {
          setActivityLogsData(ActivityLogsResponse.data.data.data);
          setRowsPerPage(ActivityLogsResponse.data.data.per_page);
          setTotalData(ActivityLogsResponse.data.data.total);
          if (ActivityLogsResponse.data.data.data.length < 1) {
            setMessage("No data found");
          }
        } else {
          setMessage(ActivityLogsResponse.data.messages.toString());
          if (ActivityLogsResponse.data.messages.length < 1) {
            setMessage("Something went wrong");
            setLoading(false);
          }
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setMessage(error.response.data.messages.toString());
        setLoading(false);
      }
    }
  };
  const getDropdownData = async () => {
    if (basicInformation?.user_id !== undefined) {
      let allAuditTrailPurposes = await getDataWithToken(
        `api/audit-trail/audit-trail-purposes`
      );
      if (allAuditTrailPurposes.status === 200) {
        setPurposeList(allAuditTrailPurposes.data.data);
      } else {
        setPurposeListMessage(allAuditTrailPurposes.data.messages.toString());
      }
    }
  };
  useEffect(() => {
    getActivityList();
    getDropdownData();
  }, []);
  return (
    <>
      <Grid container columnSpacing={3} style={{ padding: "0 0 16px 0" }}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="demo-is-assigned-outlined-label">
                  Purpose
                </InputLabel>
                <Select
                  labelId="demo-is-assigned-outlined-label"
                  id="demo-is-assigned-outlined"
                  label="Purpose"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                >
                  <MenuItem value="None">
                    {purposeListMessage.length > 0 ? (
                      <>{purposeListMessage}</>
                    ) : (
                      "None"
                    )}
                  </MenuItem>
                  {purposeList.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Starting Time"
                  value={startingTime}
                  onChange={(newValue) => {
                    setStartingTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Ending Time"
                  value={endingTime}
                  onChange={(newValue) => {
                    setEndingTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={{ lg: 6, xl: 3 }}>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    disableElevation
                    style={{ background: "#435185", padding: "6.7px 22px" }}
                    size="large"
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={(event) => handleChangePage(event, 0)}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell style={{ minWidth: "150px" }}>
                  Performer Name
                </TableCell>

                <TableCell style={{ minWidth: "160px" }}>
                  Performer Email
                </TableCell>
                <TableCell style={{ minWidth: "120px" }}>Purpose</TableCell>
                <TableCell style={{ minWidth: "140px" }}>Description</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell style={{ minWidth: "160px" }}>
                  Performed At
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOrderChange(!order)}
                  >
                    {order ? (
                      <KeyboardArrowUpIcon style={{ color: "#fff" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                activityLogsData.length > 0 &&
                activityLogsData.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <LightTooltip title="View Event Payload" placement="top">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleDetailDialogClickOpen();
                            setEventPayload(row);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </LightTooltip>
                      {row.performer_name}
                    </TableCell>
                    <TableCell>{row.performer_email}</TableCell>
                    <TableCell>
                      {row.purpose !== null ? row.purpose : <p>-----</p>}
                    </TableCell>

                    <TableCell>{row.message}</TableCell>
                    <TableCell>
                      {row.remarks !== null ? (
                        row.remarks
                      ) : (
                        <p style={{ textAlign: "center" }}>-----</p>
                      )}
                    </TableCell>
                    <TableCell>{row.created_at}</TableCell>
                  </TableRow>
                ))}

              {!loading && activityLogsData.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {activityLogsData.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
        <Dialog
          onClose={handleDetailDialogClose}
          aria-labelledby="simple-dialog-title"
          open={detailOpen}
          maxWidth={"1250px"}
        >
          <div id="simple-dialog-title" style={{ padding: "12px 16px 0px" }}>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
              }}
            >
              Event Payload
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                marginTop: "3px",
                color: "#9b9696",
                letterSpacing: "1px",
              }}
            >
              {basicInformation?.name} ({basicInformation?.mobile_number})
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleDetailDialogClose}
              style={{ position: "absolute", top: 13, right: 10 }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <Table
            style={{ minWidth: "1250px", maxWidth: "1250px" }}
            aria-label="simple table"
          >
            {/* <TableHead className={classes.tableHeadStyle2}>
            <TableRow>
              <TableCell>Before</TableCell>
              <TableCell>After</TableCell>
            </TableRow>
          </TableHead> */}
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.codeBlockStyle}
                  style={{ verticalAlign: "top", paddingRight: "2px" }}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      background: "#ddd",
                      padding: "10px",
                      margin: 0,
                    }}
                  >
                    Before
                  </h3>
                  <Collapse in={beforeBlockOpen} collapsedSize={160}>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setBeforeBlockOpen(!beforeBlockOpen)}
                        style={{
                          display: "block",
                          position: "absolute",
                          top: 10,
                          right: 10,
                          zIndex: 1,
                        }}
                      >
                        {beforeBlockOpen ? (
                          <KeyboardArrowUpIcon
                            size="small"
                            style={{
                              color: "#fff",
                              background: "#959292",
                              borderRadius: "25px",
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            size="small"
                            style={{
                              color: "#fff",
                              background: "#959292",
                              borderRadius: "25px",
                            }}
                          />
                        )}
                      </IconButton>
                      {eventPayload?.event_payload?.before && (
                        <CopyBlock
                          text={JSON.stringify(
                            eventPayload?.event_payload?.before,
                            null,
                            4
                          )}
                          language={"jsx"}
                          showLineNumbers={false}
                          theme={dracula}
                          codeBlock
                          wrapLongLines={true}
                        />
                      )}
                    </div>
                  </Collapse>
                </TableCell>

                <TableCell
                  className={classes.codeBlockStyle}
                  style={{ verticalAlign: "top", paddingLeft: "2px" }}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      background: "#ddd",
                      padding: "10px",
                      margin: 0,
                    }}
                  >
                    After
                  </h3>
                  <Collapse in={afterBlockOpen} collapsedSize={160}>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setAfterBlockOpen(!afterBlockOpen)}
                        style={{
                          display: "block",
                          position: "absolute",
                          top: 10,
                          right: 10,
                          zIndex: 1,
                        }}
                      >
                        {afterBlockOpen ? (
                          <KeyboardArrowUpIcon
                            size="small"
                            style={{
                              color: "#fff",
                              background: "#959292",
                              borderRadius: "25px",
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            size="small"
                            style={{
                              color: "#fff",
                              background: "#959292",
                              borderRadius: "25px",
                            }}
                          />
                        )}
                      </IconButton>
                      {eventPayload?.event_payload?.after && (
                        <CopyBlock
                          text={JSON.stringify(
                            eventPayload?.event_payload?.after,
                            null,
                            4
                          )}
                          language={"jsx"}
                          showLineNumbers={false}
                          theme={dracula}
                          codeBlock
                          wrapLongLines={true}
                        />
                      )}
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Dialog>
      </Paper>
    </>
  );
};

export default SupportActivityLogs;
