import React, { useState, useEffect } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 16px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
  },
}));

const UserList = () => {
  const classes = useStyles();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [levelList, setLevelList] = useState([]);
  const [levelId, setLevelId] = useState("");
  const [userTypeList, setUserTypeList] = useState([]);
  const [userType, setUserType] = useState("");
  const [userTypeMessage, setUserTypeMessage] = useState("");
  const [status, setStatus] = useState("");
  const [userStatusList, setUserStatusList] = useState([]);
  const [userStatus, setUserStatus] = useState("");
  const [kycStatus, setkycStatus] = useState("");
  const [userList, setUserList] = useState([]);

  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [levelMessage, setLevelMessage] = useState("");
  const [userStatusMessage, setUserStatusMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell colSpan={2}>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setMobileNumber("");
    setEmail("");
    setLevelId("");
    setUserType("");
    setStatus("");
    setUserStatus("");
    setkycStatus("");
    setPage(0);
    let newUrl = `api/customer-list`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newLevelId = levelId;
        let newUserType = userType;
        let newStatus = status;
        let newUserStatus = userStatus;
        let newKycStatus = kycStatus;

        if (kycStatus === "None") {
          newKycStatus = "";
        }
        if (userStatus === "None") {
          newUserStatus = "";
        }
        if (userType === "None") {
          newUserType = "";
        }
        if (levelId === "None") {
          newLevelId = "";
        }
        if (status === "None") {
          newStatus = "";
        }

        url = `api/customer-list?email=${encodeURIComponent(
          email.trim()
        )}&mobile_number=${encodeURIComponent(
          mobileNumber.trim()
        )}&level=${newLevelId}&user_type=${newUserType}&status=${newStatus}&user_status=${newUserStatus}&kyc_status=${newKycStatus}&page=${newPageNO}`;
      }
      let allUser = await getDataWithToken(url);
      if (allUser.status === 200) {
        setUserList(allUser.data.data.data);
        setTotalData(allUser.data.data.total);
        if (allUser.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(allUser.data.messages.toString());
        if (allUser.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };

  const getDropdownData = async () => {
    let allLevel = await getDataWithToken(`/api/levels`);
    if (allLevel.status === 200) {
      setLevelList(allLevel.data.data);
    } else {
      setLevelMessage(allLevel.data.messages.toString());
      if (allLevel.data.messages.length < 1) {
        setLevelMessage("Something went wrong");
      }
    }
    let allStatus = await getDataWithToken(`api/user-status`);
    if (allStatus.status === 200) {
      setUserStatusList(allStatus.data.data);
      if (location.state !== null) {
        setUserStatus(location.state.userStatus);
      }
    } else {
      setUserStatusMessage(allStatus.data.messages.toString());
      if (allStatus.data.messages.length < 1) {
        setUserStatusMessage("Something went wrong");
      }
    }

    let allUserType = await getDataWithToken(`api/user-type`);
    if (allUserType.status === 200) {
      setUserTypeList(allUserType.data.data);
      if (location.state !== null) {
        setUserType(location.state.usertype);
      }
    } else {
      setUserTypeMessage(allUserType.data.messages.toString());
      if (allUserType.data.messages.length < 1) {
        setUserTypeMessage("Something went wrong");
      }
    }
  };
  useEffect(() => {
    // getData();

    getDropdownData();

    if (location.state === null) {
      getData();
    } else {
      let url = `api/customer-list?user_type=${location.state.usertype}&user_status=${location.state.userStatus}`;
      getData("", url);
    }
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={6} xl={6}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            User List
          </Typography>
        </Grid>
        <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            // startIcon={<FilterListIcon />}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  id="mobile-no"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No."
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-is-assigned-outlined-label">
                    Level
                  </InputLabel>
                  <Select
                    labelId="demo-is-assigned-outlined-label"
                    id="demo-is-assigned-outlined"
                    value={levelId}
                    onChange={(e) => setLevelId(e.target.value)}
                    label="Level"
                  >
                    <MenuItem value="None">
                      {levelMessage.length > 0 ? <>{levelMessage}</> : "None"}
                    </MenuItem>
                    {levelList?.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="priority-outlined-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="priority-outlined-label"
                    id="priority-outlined"
                    label="User Type"
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <MenuItem value="None">
                      {userTypeMessage.length > 0 ? (
                        <>{userTypeMessage}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>
                    {userTypeList?.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    User Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="User Status"
                    value={userStatus}
                    onChange={(e) => setUserStatus(e.target.value)}
                  >
                    <MenuItem value="None">
                      {userStatusMessage.length > 0 ? (
                        <>{userStatusMessage}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>
                    {userStatusList?.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    KYC Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="KYC Status"
                    value={kycStatus}
                    onChange={(e) => setkycStatus(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value={1}>Verified</MenuItem>
                    <MenuItem value={0}>Unverified</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={{ lg: 6, xl: 3 }}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell colSpan={2} style={{ minWidth: "230px" }}>
                  Basic Info
                </TableCell>
                <TableCell style={{ minWidth: "120px" }}>Email</TableCell>

                <TableCell align="center" style={{ minWidth: "120px" }}>
                  User Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Level
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  Account Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Account No
                </TableCell>
                <TableCell align="center" style={{ minWidth: "90px" }}>
                  Balance
                </TableCell>
                <TableCell align="center" style={{ minWidth: "90px" }}>
                  User Status
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Is KYC Verified
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Is User Verified
                </TableCell>
                <TableCell align="center" style={{ minWidth: "90px" }}>
                  Created At
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  View Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                userList.length > 0 &&
                userList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.name}
                      </Typography>
                      {row.mobile}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="center">{row.user_type}</TableCell>
                    <TableCell align="center">{row.level}</TableCell>
                    <TableCell align="center">{row.account_type}</TableCell>
                    <TableCell align="center">{row.account_no}</TableCell>
                    <TableCell align="center">{row.balance}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">
                      <img
                        src={
                          row.is_kyc_verified === 1
                            ? "/image/verified.svg"
                            : "/image/unverified.svg"
                        }
                        alt=""
                        height="20px"
                        style={{ position: "relative", top: 5 }}
                      />{" "}
                      &nbsp;
                      {row.is_kyc_verified === 1 ? (
                        <span style={{ color: "rgba(129,199,132,1)" }}>
                          Verified &nbsp;&nbsp;
                        </span>
                      ) : (
                        <span style={{ color: "rgba(211, 47, 47,1)" }}>
                          Unverified
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <img
                        src={
                          row.is_user_active === 1
                            ? "/image/verified.svg"
                            : "/image/unverified.svg"
                        }
                        alt=""
                        height="20px"
                        style={{ position: "relative", top: 5 }}
                      />
                      &nbsp;
                      {row.is_user_active === 1 ? (
                        <span style={{ color: "rgba(129,199,132,1)" }}>
                          Verified &nbsp;&nbsp;
                        </span>
                      ) : (
                        <span style={{ color: "rgba(211, 47, 47,1)" }}>
                          Unverified
                        </span>
                      )}
                    </TableCell>

                    <TableCell align="center">{row.created_at}</TableCell>
                    <TableCell align="center">
                      <IconButton component={Link} to={`/user/${row.mobile}`}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && userList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {userList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              // count={rows.length}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </TableContainer>
  );
};

export default UserList;
