import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import HomeIcon from "@mui/icons-material/Home";
import Visibility from "@mui/icons-material/Visibility";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import StoreIcon from "@mui/icons-material/Store";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LockResetIcon from "@mui/icons-material/LockReset";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AppsIcon from "@mui/icons-material/Apps";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { makeStyles } from "@mui/styles";
import Navigation from "../navigation/Navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ListItem from "@mui/material/ListItem";
import Skeleton from "@mui/material/Skeleton";
import SettingsPowerIcon from "@mui/icons-material/SettingsPower";
import { getDataWithToken } from "../../services/GetDataService";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import DnsIcon from "@mui/icons-material/Dns";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { IdleTimerProvider } from "react-idle-timer";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
const useStyles = makeStyles((theme) => ({
  linkStyle: {
    textDecoration: "none",
    color: "#212F3C",
    // color: "#858e9b",
  },
  menuItem: {
    // color: "#fff",
    padding: "6px 16px !important",

    "& span": {
      fontSize: "15px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    ["& .MuiListItemIcon-root"]: {
      minWidth: "46px",
    },
    ["& .MuiSvgIcon-root"]: {
      position: "relative",
      top: "-2px",
      color: "#212F3C",
      fontSize: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
  },
  menuItemActive: {
    // color: "rgba(15, 188, 249,1.0) !important",
    color: "#fff !important",
    // background:
    //   "linear-gradient(56deg, rgba(232,9,43,.5) 0%, rgba(232,9,43,.2) 100%) !important",
    // background: "rgb(37,49,109,0.2) !important",
    background: "#00848A !important",
    borderRadius: "10px !important",
    ["& .MuiSvgIcon-root"]: {
      color: "#fff !important",
    },
  },
  menuSubItem: {
    padding: "6px 32px 2px 38px !important",
    "& span": {
      fontSize: "15px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
    ["& .MuiListItemIcon-root"]: {
      minWidth: "46px",
    },
    ["& .MuiSvgIcon-root"]: {
      color: "#212F3C",
      fontSize: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
  },

  MuiDrawer: {
    // backgroundColor: "#353b48 !important",
    backgroundColor: "#fff !important",
    color: "#212F3C !important",
    // paddingRight: "7px",
    // paddingLeft: "7px",
  },
}));

const drawerWidth = 270;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Layout() {
  const classes = useStyles();
  let navigate = useNavigate();
  let pathname = useLocation().pathname;

  const { login, fastpay_support_panel_user, logout } = useContext(AuthContext);
  // console.log("pathname", pathname);

  const theme = useTheme();
  const [kycRequestOpen, setKycRequestOpen] = useState(false);
  const [onboardingOpen, setOnboardingOpen] = useState(false);
  const [manageRolesOpen, setManageRolesOpen] = useState(false);
  const [manageAccessOpen, setManageAccessOpen] = useState(false);
  const [merchantOnboardingOpen, setMerchantOnboardingOpen] = useState(false);
  const [bankingLogOpen, setBankingLogOpen] = useState(false);
  const [rollPermission, setRollPermission] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const time = parseInt(1000 * 60 * 120);

  const navigateRoutes = (routeName) => {
    navigate(routeName, { replace: true });
  };

  const fnLogout = () => {
    logout();
    setRollPermission([]);
    navigate("/");
  };
  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (pathname !== "/") {
      setOpenLoadingDialog(true);
      setTimeout(() => {
        setOpenLoadingDialog(false);
        fnLogout();
      }, 2000);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(true);
  const [OpenCollapse, setOpenCollapse] = useState("");
  const manageOpen = (id) => {
    if (OpenCollapse === id) {
      setOpenCollapse("");
    } else {
      setOpenCollapse(id);
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const withoutLayout = ["/", "/forgot-password", "/reset-password", "/verify"];

  let ticketPathnameCheck = pathname.includes("ticket");

  let transactionstPathnameCheck = pathname.includes("transactions");
  const kybRequesttPathnameCheck = () => {
    if (pathname.includes("/kyb-request")) {
      return true;
    } else if (pathname.includes("/kyb-details/")) {
      return true;
    } else {
      return false;
    }
  };
  const kycRequesttPathnameCheck = () => {
    if (pathname.includes("/kyc-request")) {
      return true;
    } else if (pathname.includes("/kyc-detail/")) {
      return true;
    } else {
      return false;
    }
  };
  const usertPathnameCheck = () => {
    if (pathname.includes("/search-user")) {
      return true;
    } else if (pathname.includes("/user/")) {
      return true;
    } else {
      return false;
    }
  };

  const storePathnameCheck = () => {
    if (pathname.includes("/store-management")) {
      return true;
    } else if (pathname.includes("/store-detail/")) {
      return true;
    } else if (pathname.includes("/update-store-detail/")) {
      return true;
    } else {
      return false;
    }
  };

  let showOnboardingRequest = () => {
    if (
      rollPermission?.includes("KYC request list") ||
      rollPermission?.includes("KYB request list")
    ) {
      return true;
    } else {
      return false;
    }
  };

  let showManageRoles = () => {
    if (
      rollPermission?.includes("Manage role") ||
      rollPermission?.includes("View role")
    ) {
      return true;
    } else {
      return false;
    }
  };
  let showManageAccess = () => {
    if (
      rollPermission?.includes("Create user") ||
      rollPermission?.includes("View user")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const pageLoading = () => {
    return (
      <List>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
          <ListItem button key={index}>
            <Skeleton
              variant="rectangular"
              style={{ width: "100%" }}
              height={30}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const getData = async () => {
    if (fastpay_support_panel_user.token) {
      // setLoading(true);

      let userPermissions = await getDataWithToken("api/user-permissions");

      if (userPermissions.data.code === 200) {
        setRollPermission(userPermissions.data.data.permissions);
      } else {
        setMessage(userPermissions.data.messages.toString());
        if (userPermissions.data.messages.length < 1) {
          setMessage("Something went wrong");
          // setLoading(false);
        }
        // setLoading(false);
      }
      // setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  if (withoutLayout.includes(pathname)) {
    return (
      <IdleTimerProvider
        timeout={time}
        // onPrompt={onPrompt}
        onIdle={onIdle}
        // onActive={onActive}
        // onAction={onAction}
      >
        <Navigation
          rollPermission={rollPermission}
          setRollPermission={setRollPermission}
          openLoadingDialog={openLoadingDialog}
          setOpenLoadingDialog={setOpenLoadingDialog}
        />
      </IdleTimerProvider>
    );
  } else if (!fastpay_support_panel_user.token) {
    return (
      <IdleTimerProvider
        timeout={time}
        // onPrompt={onPrompt}
        onIdle={onIdle}
        // onActive={onActive}
        // onAction={onAction}
      >
        <Navigation
          rollPermission={rollPermission}
          setRollPermission={setRollPermission}
          openLoadingDialog={openLoadingDialog}
          setOpenLoadingDialog={setOpenLoadingDialog}
        />
      </IdleTimerProvider>
    );
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        <AppBar position="fixed" open={open} style={{ background: "#fff" }}>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>

            <div>
              <IconButton
                id="basic-button"
                aria-controls={menuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={handleClick}
                style={{
                  padding: 0,
                  color: "#212F3C",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                <SettingsPowerIcon sx={{ width: 40, height: 40 }} />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigateRoutes("/change-password");
                  }}
                >
                  Change Password
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    fnLogout();
                  }}
                >
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          classes={{ paper: classes.MuiDrawer }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <img
              src="/image/Logo.png"
              alt=""
              style={{
                display: "block",
                margin: "auto",
                cursor: "pointer",
                width: "190px",
              }}
            />
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
          </DrawerHeader>
          <Divider />
          <div
            style={{ textAlign: "center", padding: "20px 0", color: "#212F3C" }}
          >
            <Avatar
              alt=""
              src={
                fastpay_support_panel_user && fastpay_support_panel_user.user
                  ? fastpay_support_panel_user.user.profile_pic_url
                  : "PersonIcon"
              }
              sx={{ width: 40, height: 40 }}
              style={{ display: "block", margin: " auto auto 10px auto" }}
            />
            {fastpay_support_panel_user && fastpay_support_panel_user.user
              ? fastpay_support_panel_user.user.email
              : ""}
          </div>
          <Divider />
          {loading ? (
            pageLoading()
          ) : (
            <List>
              {rollPermission?.includes("Dashboard counts") && (
                <Link to="/home" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/home" ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>

                    <ListItemText primary="Home" />
                  </ListItemButton>
                </Link>
              )}
              {rollPermission?.includes("Merchant onboarding") && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem}`}
                    onClick={() => {
                      manageOpen("merchantOnboardingOpen");
                    }}
                  >
                    <ListItemIcon>
                      <PortraitOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Merchant Onboarding" />
                    {OpenCollapse === "merchantOnboardingOpen" ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={OpenCollapse === "merchantOnboardingOpen"}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#fff" }}
                  >
                    <List component="div" disablePadding>
                      {/* {rollPermission?.includes("Create user") && ( */}
                      <Link to="/merchant-list" className={classes.linkStyle}>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/merchant-list"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <RecentActorsIcon />
                          </ListItemIcon>
                          <ListItemText primary="Merchant list" />
                        </ListItemButton>
                      </Link>
                      {/* )} */}
                      {/* {rollPermission?.includes("View user") && ( */}
                      <Link to="/create-merchant" className={classes.linkStyle}>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/create-merchant"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <PersonAddAlt1Icon />
                          </ListItemIcon>
                          <ListItemText primary="Create Merchant" />
                        </ListItemButton>
                      </Link>
                      {/* )} */}
                    </List>
                  </Collapse>
                </>
              )}
              {rollPermission?.includes("Merchant send message") && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem}`}
                    onClick={() => {
                      manageOpen("Promotional Notification");
                    }}
                  >
                    <ListItemIcon>
                      <NotificationsNoneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Promotional Notification" />
                    {OpenCollapse === "Promotional Notification" ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={OpenCollapse === "Promotional Notification"}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#fff" }}
                  >
                    <List component="div" disablePadding>
                      {/* {rollPermission?.includes("Create user") && ( */}
                      <Link to="/notification-list" className={classes.linkStyle}>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/notification-list"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <SpeakerNotesOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Notification List" />
                        </ListItemButton>
                      </Link>
                      <Link to="/send-message" className={classes.linkStyle}>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/send-message"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <ForwardToInboxOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Send Notification" />
                        </ListItemButton>
                      </Link>
                      {/* )} */}
                    </List>
                  </Collapse>
                </>
              )}
              {rollPermission?.includes("View ticket") && (
                <Link to="/tickets" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      ticketPathnameCheck ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tickets" />
                  </ListItemButton>
                </Link>
              )}
              {rollPermission?.includes("Customer report list") && (
                <Link to="/customer-report" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/customer-report"
                        ? classes.menuItemActive
                        : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <SummarizeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Customer Report" />
                  </ListItemButton>
                </Link>
              )}
              {rollPermission?.includes("Search customer") && (
                <Link to="/search-user" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      usertPathnameCheck() ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <PersonSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Search User" />
                  </ListItemButton>
                </Link>
              )}
              {rollPermission?.includes("Customer list") && (
                <Link to="/user-list" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/user-list" ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="User List" />
                  </ListItemButton>
                </Link>
              )}
              {rollPermission?.includes("View block list") && (
                <Link to="/block-user-list" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/block-user-list"
                        ? classes.menuItemActive
                        : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <NoAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Block User List" />
                  </ListItemButton>
                </Link>
              )}
              {rollPermission?.includes("Customer transaction list") && (
                <Link to="/transactions" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      transactionstPathnameCheck ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <CompareArrowsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </ListItemButton>
                </Link>
              )}
              {/* {rollPermission?.includes("Data bundle log") && (
                <Link to="/data-bundle-log" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/data-bundle-log"
                        ? classes.menuItemActive
                        : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Bundle Log" />
                  </ListItemButton>
                </Link>
              )} */}
              {/* {showOnboardingRequest() && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem}`}
                    onClick={() => {
                      manageOpen("onboardingOpen");
                    }}
                  >
                    {" "}
                    <ListItemIcon>
                      <PersonAddAlt1Icon />
                    </ListItemIcon>
                    <ListItemText primary="Onboarding Request" />
                    {onboardingOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={onboardingOpen}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#424752" }}
                  >
                    <List component="div" disablePadding>
                      {rollPermission?.includes("KYC request list") && (
                        <Link to="/kyc-request" className={classes.linkStyle}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`${classes.menuSubItem} ${
                              kycRequesttPathnameCheck()
                                ? classes.menuItemActive
                                : null
                            }`}
                          >
                            <ListItemIcon>
                              <HowToRegIcon />
                            </ListItemIcon>
                            <ListItemText primary="KYC Requests" />
                          </ListItemButton>
                        </Link>
                      )}
                      {rollPermission?.includes("KYB request list") && (
                        <Link to="/kyb-request" className={classes.linkStyle}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`${classes.menuSubItem} ${
                              kybRequesttPathnameCheck()
                                ? classes.menuItemActive
                                : null
                            }`}
                          >
                            <ListItemIcon>
                              <HowToRegIcon />
                            </ListItemIcon>
                            <ListItemText primary="KYB Requests" />
                          </ListItemButton>
                        </Link>
                      )}
                    </List>
                  </Collapse>
                </>
              )} */}
              {/* ====================Ekyc Menu Start ============================= */}
              {/* {rollPermission?.includes("KYC request list") && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem}`}
                    onClick={() => {
                      manageOpen("kycRequest");
                    }}
                  >
                    {" "}
                    <ListItemIcon>
                      <PersonAddAlt1Icon />
                    </ListItemIcon>
                    <ListItemText primary="KYC Request" />
                    {kycRequestOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={kycRequestOpen}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#fff" }}
                  >
                    <List component="div" disablePadding>
                      <Link
                        to="/kyc-partial-request"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/kyc-partial-request"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Partial KYC" />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/kyc-declined-request"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/kyc-declined-request"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Declined KYC" />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/declined-physical-kyc"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/declined-physical-kyc"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Declined Physical KYC" />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/kyc-manual-request"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/kyc-manual-request"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Manual KYC " />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/kyc-digital-request"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/kyc-digital-request"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Digital KYC" />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/kyc-Approved-request"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/kyc-Approved-request"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Approved KYC" />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/kyc-agent-verified-request"
                        className={classes.linkStyle}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/kyc-agent-verified-request"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <HowToRegIcon />
                          </ListItemIcon>
                          <ListItemText primary="Agent Verified KYC" />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                </>
              )}{" "} */}
              {/* {rollPermission?.includes("KYB request list") && (
                <Link to="/kyb-request" className={classes.linkStyle}>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    className={`${classes.menuItem} ${
                      kybRequesttPathnameCheck() ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <HowToRegIcon />
                    </ListItemIcon>
                    <ListItemText primary="KYB Requests" />
                  </ListItemButton>
                </Link>
              )} */}
              {/* ====================Ekyc Menu End ============================= */}
              {/* {rollPermission?.includes("View store configuration") && (
                <Link to="/store-management" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      storePathnameCheck() ? classes.menuItemActive : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Store Management" />
                  </ListItemButton>
                </Link>
              )} */}
              {/* {showManageRoles() && ( */}
              {rollPermission?.includes("fib topup request") && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem} `}
                    onClick={() => {
                      manageOpen("bankingLogOpen");
                    }}
                  >
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Banking Log" />
                    {OpenCollapse === "bankingLogOpen" ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={OpenCollapse === "bankingLogOpen"}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#fff" }}
                  >
                    <List component="div" disablePadding>
                      <Link to="/fib-requests" className={classes.linkStyle}>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          className={`${classes.menuSubItem} ${
                            pathname === "/fib-requests"
                              ? classes.menuItemActive
                              : null
                          }`}
                        >
                          <ListItemIcon>
                            <PriceChangeIcon />
                          </ListItemIcon>
                          <ListItemText primary="FIB Requests" />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                </>
              )}
              {showManageRoles() && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem} `}
                    onClick={() => {
                      manageOpen("manageRolesOpen");
                    }}
                  >
                    <ListItemIcon>
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Roles" />
                    {OpenCollapse === "manageRolesOpen" ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={OpenCollapse === "manageRolesOpen"}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#fff" }}
                  >
                    <List component="div" disablePadding>
                      {rollPermission?.includes("Manage role") && (
                        <Link to="/create-role" className={classes.linkStyle}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`${classes.menuSubItem} ${
                              pathname === "/create-role"
                                ? classes.menuItemActive
                                : null
                            }`}
                          >
                            <ListItemIcon>
                              <AppRegistrationIcon />
                            </ListItemIcon>
                            <ListItemText primary="Create Roles" />
                          </ListItemButton>
                        </Link>
                      )}
                      {rollPermission?.includes("View role") && (
                        <Link to="/view-role" className={classes.linkStyle}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`${classes.menuSubItem} ${
                              pathname === "/view-role"
                                ? classes.menuItemActive
                                : null
                            }`}
                          >
                            <ListItemIcon>
                              <AppsIcon />
                            </ListItemIcon>
                            <ListItemText primary="View Roles" />
                          </ListItemButton>
                        </Link>
                      )}
                    </List>
                  </Collapse>
                </>
              )}
              {showManageAccess() && (
                <>
                  <ListItemButton
                    className={`${classes.menuItem}`}
                    onClick={() => {
                      manageOpen("manageAccessOpen");
                    }}
                  >
                    <ListItemIcon>
                      <LockResetIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Access" />
                    {OpenCollapse === "manageAccessOpen" ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={OpenCollapse === "manageAccessOpen"}
                    timeout="auto"
                    unmountOnExit
                    style={{ background: "#fff" }}
                  >
                    <List component="div" disablePadding>
                      {rollPermission?.includes("Create user") && (
                        <Link to="/add-user" className={classes.linkStyle}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`${classes.menuSubItem} ${
                              pathname === "/add-user"
                                ? classes.menuItemActive
                                : null
                            }`}
                          >
                            <ListItemIcon>
                              <PersonAddAlt1Icon />
                            </ListItemIcon>
                            <ListItemText primary="Add User" />
                          </ListItemButton>
                        </Link>
                      )}
                      {rollPermission?.includes("View user") && (
                        <Link to="/view-user" className={classes.linkStyle}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`${classes.menuSubItem} ${
                              pathname === "/view-user"
                                ? classes.menuItemActive
                                : null
                            }`}
                          >
                            <ListItemIcon>
                              <Visibility />
                            </ListItemIcon>
                            <ListItemText primary="View User" />
                          </ListItemButton>
                        </Link>
                      )}
                    </List>
                  </Collapse>
                </>
              )}
              {/* {rollPermission?.includes("Adjustment") && (
                <Link to="/adjustment-list" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/adjustment-list"
                        ? classes.menuItemActive
                        : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <DnsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Adjustment List" />
                  </ListItemButton>
                </Link>
              )} */}
              {/* {rollPermission?.includes("Manage zicharge agent") && (
                <Link to="/zicharge-agent-list" className={classes.linkStyle}>
                  <ListItemButton
                    className={`${classes.menuItem} ${
                      pathname === "/zicharge-agent-list"
                        ? classes.menuItemActive
                        : null
                    }`}
                    onClick={() => {
                      manageOpen("close");
                    }}
                  >
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Zicharge Agent List" />
                  </ListItemButton>
                </Link>
              )} */}
            </List>
          )}
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <IdleTimerProvider
            timeout={time}
            // onPrompt={onPrompt}
            onIdle={onIdle}
            // onActive={onActive}
            // onAction={onAction}
          >
            <Navigation
              rollPermission={rollPermission}
              setRollPermission={setRollPermission}
              openLoadingDialog={openLoadingDialog}
              setOpenLoadingDialog={setOpenLoadingDialog}
            />
          </IdleTimerProvider>
        </Main>
      </Box>
    );
  }
}
