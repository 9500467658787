import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getDataWithToken } from "../../services/GetDataService";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Map from "../../map/Map";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    // textAlign: "center",
    width: "900px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  form2: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    width: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  imgStyle: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
const UpdateStoreDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { slug } = useParams();
  const previewImage = "/image/NoImage.jpg";
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [storeDetail, setStoreDetail] = useState({});
  const [storeId, setStoreId] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [location, setLocation] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [storeType, setStoreType] = useState("");
  const [visibility, setVisibility] = useState("");
  const [storeURL, setStoreURL] = useState("");
  const [successURL, setSuccessURL] = useState("");
  const [cancelURL, setCancelURL] = useState("");
  const [iPNURL, setIPNURL] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [tradeLicenseImageFile, setTradeLicenseImageFile] = useState(null);
  const [tradeLicensepreview, setTradeLicensepreview] = useState(previewImage);
  const [logoImageFile, setLogoImageFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(previewImage);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [message, setMessage] = useState("");
  // const handleStoreTypeChange = (event) => {
  //   if (storeDetail.type === "Online") {
  //     setStoreType(event.target.value);
  //   }
  // };

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.value);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const cancelImage = (button) => {
    if (button === "logo") {
      setLogoImageFile(null);
      setLogoPreview(previewImage);
    } else {
      setTradeLicenseImageFile(null);
      setTradeLicensepreview(previewImage);
    }
  };
  const imageProcess = (e, button) => {
    let imageFile;
    if (e.target.files && e.target.files[0]) {
      imageFile = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (x) => {
        if (button === "logo") {
          setLogoImageFile(imageFile);
          setLogoPreview(x.target.result);
        } else {
          setTradeLicenseImageFile(imageFile);
          setTradeLicensepreview(x.target.result);
        }
      };
      reader.readAsDataURL(imageFile);
    } else {
      if (button === "logo") {
        setLogoImageFile(imageFile);
        setLogoPreview(logoPreview);
      } else {
        setTradeLicenseImageFile(imageFile);
        setTradeLicensepreview(tradeLicensepreview);
      }
    }
  };
  const validation = () => {
    let isError = false;

    if (!mobileNumber.trim()) {
      handleSnakbarOpen("Please enter mobile number", "error");
      document.getElementById("mobile-number").focus();
      return (isError = true);
    }

    return isError;
  };

  const onSubmit = async () => {
    let err = validation();

    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let newStoreType = 2;
        if (storeType === "Online") {
          newStoreType = 1;
        }
        const formdata = new FormData();
        formdata.append("store_configuration_id", storeId);
        formdata.append("category_id", category);
        formdata.append("address", location);

        formdata.append("mobile_number", mobileNumber);
        formdata.append("shop_type_id", newStoreType);
        formdata.append("visibility", visibility);
        formdata.append("remarks", remarks);
        if (newStoreType === 1) {
          formdata.append("store_url", storeURL);
          formdata.append("ipn_url", iPNURL);
          formdata.append("success_url", successURL);
          formdata.append("cancel_url", cancelURL);
        }
        if (newStoreType === 2) {
          formdata.append("lat", lat);
          formdata.append("lng", lng);
        }

        if (tradeLicenseImageFile !== null) {
          formdata.append("trade_license", tradeLicenseImageFile);
        }
        if (logoImageFile !== null) {
          formdata.append("store_logo", logoImageFile);
        }

        let response = await axios({
          url: "api/store/update-store-configuration",
          method: "post",
          data: formdata,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });

        handleSnakbarOpen(response.data.messages.toString(), "success");

        navigate("/store-management");
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");

        setLoading(false);
      }
      setLoading(false);
    }
  };
  const getDropdownData = async () => {
    let allCategories = await getDataWithToken(`api/business/categories`);
    if (allCategories.status === 200) {
      setCategoryList(allCategories.data.data);
    } else {
      handleSnakbarOpen(allCategories.data.messages.toString(), "error");
      if (allCategories.data.messages.length < 1) {
        handleSnakbarOpen("Something went wrong", "error");
      }
    }

    let storeInfo = await getDataWithToken(
      `api/store/show-store-configuration?store_id=${slug}`
    );

    if (storeInfo.status === 200) {
      console.log("storeInfo.data.data", storeInfo.data.data);
      setStoreDetail(storeInfo.data.data);

      setStoreId(storeInfo.data.data?.id);
      setName(storeInfo.data.data?.merchant.name);
      setMobileNumber(storeInfo.data.data?.merchant.mobile_no);
      setStoreType(storeInfo.data.data?.type);
      setCategory(storeInfo.data.data?.store_category.id);
      setLocation(storeInfo.data.data?.address);
      setVisibility(storeInfo.data.data?.visibility);
      setStoreURL(storeInfo.data.data?.store_url);
      setSuccessURL(storeInfo.data.data?.success_url);
      setCancelURL(storeInfo.data.data?.cancel_url);
      setIPNURL(storeInfo.data.data?.ipn_url);
      setRemarks(storeInfo.data.data?.remarks);
      setRemarks(storeInfo.data.data?.remarks);
      setTradeLicensepreview(storeInfo.data.data?.trade_license);
      setLogoPreview(storeInfo.data.data?.store_logo);
      setLat(storeInfo.data.data?.latitude);
      setLng(storeInfo.data.data?.longitude);
    } else {
      // handleSnakbarOpen(storeInfo.data.messages.toString(), "error");
      setMessage(storeInfo.data.messages.toString());
      if (storeInfo.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      {/* <div>
        <Map />
      </div> */}
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "85vh" }}
        >
          <div className={classes.form2}>
            <img
              src="/image/Logo.png"
              alt=""
              style={{ display: "block", margin: "auto", maxWidth: "155px" }}
            />
            <br />
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "30px" }}
            >
              {message}
            </Typography>
          </div>
        </Grid>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "80vh" }}
          >
            <div className={classes.form}>
              <div style={{ position: "relative" }}>
                <IconButton
                  component={Link}
                  to="/store-management"
                  style={{ position: "absolute", border: "none" }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography
                  variant="h4"
                  color="info"
                  gutterBottom
                  component="div"
                  style={{ textAlign: "center" }}
                >
                  {name}'s Store ({mobileNumber})
                </Typography>
              </div>
              <br />
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <TextField
                    style={{ marginBottom: "30px" }}
                    size="small"
                    id="location"
                    label="Location"
                    variant="outlined"
                    fullWidth
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <Collapse
                    in={storeType === "Offline" ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextField
                      size="small"
                      id="lat"
                      label="Latitude"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      value={lat}
                      onChange={(e) => setLat(e.target.value)}
                    />
                  </Collapse>
                  <FormControl style={{ marginBottom: "30px", width: "100%" }}>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Shop Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={storeType}
                      // onChange={handleStoreTypeChange}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        style={{ flex: 1 }}
                        value="Offline"
                        control={<Radio disabled />}
                        label="Offline/Physical Store"
                      />
                      <FormControlLabel
                        style={{ flex: 1 }}
                        value="Online"
                        control={<Radio disabled />}
                        label="Online Store"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Collapse
                    in={storeType === "Online" ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextField
                      size="small"
                      id="storeURL"
                      label="Store URL"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      value={storeURL}
                      onChange={(e) => setStoreURL(e.target.value)}
                    />

                    <TextField
                      size="small"
                      id="cancelURL"
                      label="Cancel URL"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      value={cancelURL}
                      onChange={(e) => setCancelURL(e.target.value)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: "30px" }}
                  >
                    <InputLabel id="demo-issue-outlined-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-issue-outlined-label"
                      id="demo-issue-outlined"
                      label="Category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categoryList?.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Collapse
                    in={storeType === "Offline" ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextField
                      size="small"
                      id="lng"
                      label="Longitude"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      value={lng}
                      onChange={(e) => setLng(e.target.value)}
                    />
                  </Collapse>
                  <FormControl style={{ marginBottom: "30px", width: "100%" }}>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Is visible to app?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={visibility}
                      onChange={handleVisibilityChange}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                        style={{ flex: 1 }}
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                        style={{ flex: 1 }}
                      />
                    </RadioGroup>
                  </FormControl>

                  <Collapse
                    in={storeType === "Online" ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextField
                      size="small"
                      id="successURL"
                      label="Success URL"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      value={successURL}
                      onChange={(e) => setSuccessURL(e.target.value)}
                    />

                    <TextField
                      size="small"
                      id="iPNURL"
                      label="IPN URL"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "30px" }}
                      value={iPNURL}
                      onChange={(e) => setIPNURL(e.target.value)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="remarks"
                    label="Remarks"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "30px" }}
                    multiline
                    rows={2}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <div style={{ position: "relative" }}>
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => cancelImage("License")}
                    >
                      <ClearIcon />
                    </IconButton>
                    <img
                      src={tradeLicensepreview}
                      alt=""
                      height="120px"
                      width="120px"
                      className={classes.imgStyle}
                    />
                    <input
                      accept="image/*"
                      hidden
                      id="contained-button-file"
                      type="file"
                      onChange={(e) => imageProcess(e, "License")}
                    />

                    <label htmlFor="contained-button-file">
                      <Button
                        id="uploadBtn"
                        variant="outlined"
                        color="primary"
                        component="span"
                        style={{ marginTop: "15px" }}
                        fullWidth
                      >
                        <PhotoCameraIcon />
                        &nbsp; Upload Trade License
                      </Button>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ marginBottom: "30px", position: "relative" }}>
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => cancelImage("logo")}
                    >
                      <ClearIcon />
                    </IconButton>
                    <img
                      src={logoPreview}
                      alt=""
                      height="120px"
                      width="120px"
                      className={classes.imgStyle}
                    />
                    <input
                      accept="image/*"
                      hidden
                      id="logo-file"
                      type="file"
                      onChange={(e) => imageProcess(e, "logo")}
                    />

                    <label htmlFor="logo-file">
                      <Button
                        id="uploadBtn"
                        variant="outlined"
                        color="primary"
                        component="span"
                        style={{ marginTop: "15px" }}
                        fullWidth
                      >
                        <PhotoCameraIcon />
                        &nbsp; Upload Logo
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                disableElevation
                style={{
                  minWidth: "200px",
                  display: "block",
                  marginLeft: "auto",
                }}
                disabled={loading}
                onClick={onSubmit}
                size="large"
              >
                {loading === false && "Update"}
                <PulseLoader
                  color={"#00848A"}
                  loading={loading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default UpdateStoreDetails;
