import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
const useStyles = makeStyles((theme) => ({
  rightAlign: {
    textAlign: "right",
  },

  uploadButtonStyle: {
    padding: "10px 32px",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "none !important",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

const ImageUpload = ({ iconButton, allFiles, setAllFiles }) => {
  const classes = useStyles();

  const showPreview = async (e) => {
    if (e.target.files) {
      let files = e.target.files[0];
      setAllFiles(files);
    }
  };

  const clickUpload = () => {
    document.getElementById("imageUpload").click();
  };

  return (
    <>
      <input
        type="file"
        name="file"
        id="imageUpload"
        hidden
        accept="image/*"
        onChange={showPreview}
      />
      {iconButton ? (
        <IconButton id="imageUpload" onClick={clickUpload}>
          <AttachFileIcon color="primary" />
        </IconButton>
      ) : (
        <Button
          id="imageUpload"
          variant="outlined"
          color="primary"
          className={classes.uploadButtonStyle}
          onClick={clickUpload}
        >
          Upload Attachment
        </Button>
      )}

      <label style={{ cursor: "pointer" }} htmlFor="imageUpload">
        {" "}
        {allFiles ? <> {allFiles.name} </> : "No attachment chosen "}
      </label>
    </>
  );
};

export default ImageUpload;
