import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { AuthContext } from "../../context/AuthContext";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import PulseLoader from "react-spinners/PulseLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:first-child": {
      padding: "10px 16px",
    },
    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& td": {
    //     paddingTop: "12px",
    //     paddingBottom: "12px",
    //     padding: "12px 6px",
    //   },
    //   "& td:nth-child(n+3)": {
    //     paddingLeft: "0px",
    //   },
    // },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },

    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const BlockUserList = ({ rollPermission }) => {
  const classes = useStyles();
  const [mobileNumber, setMobileNumber] = useState("");
  const [featureList, setFeatureList] = useState([]);
  const [feature, setFeature] = useState("");
  const [blockedUserList, setBlockedUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [blockFeatureMessage, setBlockFeatureMessage] = useState("");
  const [unblockData, setUnblockData] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell colSpan={2}>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          {rollPermission?.includes("Customer unblock operation") && (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          )}
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setMobileNumber("");
    setFeature("");
    setPage(0);
    let newUrl = `api/user-block-list?mobile_number=&feature=&page=1`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    setLoading(true);
    let newPageNO;
    let url;
    if (pageNO) {
      newPageNO = pageNO;
    } else {
      newPageNO = 1;
    }
    if (newUrl) {
      url = newUrl;
    } else {
      let newFeature = feature;
      if (feature === "None") {
        newFeature = "";
      }
      url = `api/user-block-list?mobile_number=${encodeURIComponent(
        mobileNumber.trim()
      )}&feature=${newFeature}&page=${newPageNO}`;
    }
    let allBlockedUser = await getDataWithToken(url);

    if (allBlockedUser.status === 200) {
      setBlockedUserList(allBlockedUser.data.data.data);
      setTotalData(allBlockedUser.data.data.total);
      setRowsPerPage(allBlockedUser.data.data.per_page);
      if (allBlockedUser.data.data.data.length < 1) {
        setMessage("No data found");
      }
    } else {
      // handleSnakbarOpen(allBlockedUser.data.messages.toString(), "error");
      setMessage(allBlockedUser.data.messages.toString());
      if (allBlockedUser.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }

    setLoading(false);
  };

  const updateBlockStatus = async () => {
    let data = {
      blocklist_id: unblockData.id,
    };

    try {
      setUpdateLoading(true);

      let response = await axios({
        url: `api/unblock-user`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        await getData(page + 1);
        handleSnakbarOpen(response.data.messages.toString(), "success");
        setUpdateLoading(false);
        setOpen(false);
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };

  const getDropdownData = async () => {
    let allFeatures = await getDataWithToken(`api/user-block-features`);
    if (allFeatures.status === 200) {
      setFeatureList(allFeatures.data.data);
    } else {
      setBlockFeatureMessage(allFeatures.data.messages.toString());
      if (allFeatures.data.messages.length < 1) {
        setBlockFeatureMessage("Something went wrong");
      }
    }
  };
  useEffect(() => {
    getData();
    getDropdownData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={12} xl={12}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              Blocked User List
            </Typography>
          </Grid>

          <br />
          <Grid item xs={12}>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  id="mobile-no"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No."
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Select Feature
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Select Feature"
                    value={feature}
                    onChange={(e) => setFeature(e.target.value)}
                  >
                    <MenuItem value="None">
                      {blockFeatureMessage.length > 0 ? (
                        <>{blockFeatureMessage}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>

                    {featureList?.map((item, i) => (
                      <MenuItem key={i} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  disableElevation
                  size="large"
                  onClick={(event) => clearFilter(event, 0)}
                >
                  <RestartAltIcon />
                </Button>{" "}
                &nbsp;
                <Button
                  variant="contained"
                  disableElevation
                  // color="success"
                  style={{ background: "#435185", padding: "6.7px 22px" }}
                  size="large"
                  startIcon={<SearchIcon />}
                  onClick={(event) => handleChangePage(event, 0)}
                >
                  Search
                </Button>
                {/* <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Paper>
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 370px)",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell colSpan={2} style={{ minWidth: "230px" }}>
                    Basic Info
                  </TableCell>
                  <TableCell style={{ minWidth: "140px" }}>
                    Blocked At
                  </TableCell>
                  <TableCell style={{ minWidth: "140px" }}>
                    Unblock At
                  </TableCell>
                  <TableCell style={{ minWidth: "140px" }}>
                    Created At
                  </TableCell>

                  <TableCell style={{ minWidth: "150px" }}>Remarks</TableCell>
                  {rollPermission?.includes("Customer unblock operation") && (
                    <TableCell align="center" style={{ minWidth: "120px" }}>
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!loading &&
                  blockedUserList.length > 0 &&
                  blockedUserList.map((row, i) => (
                    <TableRow
                      key={i}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ paddingRight: 0, width: "50px" }}>
                        <Avatar
                          alt=""
                          src={row.photo}
                          sx={{ width: 45, height: 45 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ fontSize: "16px", fontWeight: 500 }}
                        >
                          {row.name}
                        </Typography>
                        {row.mobile_no}
                      </TableCell>
                      <TableCell>{row.blocked_at}</TableCell>
                      <TableCell>{row.unblock_at}</TableCell>
                      <TableCell>{row.created_at}</TableCell>
                      <TableCell>{row.remarks}</TableCell>
                      {rollPermission?.includes(
                        "Customer unblock operation"
                      ) && (
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="success"
                            disableElevation
                            // component={Link}
                            // to={`/tickets/${row.id}`}
                            onClick={() => {
                              setOpen(true);
                              setUnblockData({ id: row.id, name: row.name });
                            }}
                          >
                            Unblock
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                {!loading && blockedUserList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
          {blockedUserList.length > 0 && (
            <div>
              <TablePagination
                style={{ display: "block", borderBottom: "none" }}
                rowsPerPageOptions={[]}
                // count={rows.length}
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to unblock <b>{unblockData?.name}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateBlockStatus}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default BlockUserList;
