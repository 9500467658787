import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import moment from "moment";
const useStyles = makeStyles({
  tbodyStyle: {
    "& td": {
      fontSize: "12px !important",
    },
  },
});
const UserInfoPDF = ({ customerInfo }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xl"));
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      customerInfo?.basic_information?.first_name +
      " " +
      customerInfo?.basic_information?.last_name,
  });

  return (
    <div>
      <div style={{ display: "none" }}>
        <div ref={componentRef} style={{ padding: "60px 60px" }}>
          <Grid container>
            <Grid item xs={6}>
              <img
                src="/image/Logo.png"
                alt=""
                width="120px"
                // style={{ marginBottom: "20px" }}
              />

              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 400, color: "#000" }}
              >
                Allai Newroz Group Building
                <br />
                Baharka Road, Ankawa, Erbil, Kurdistan 44001, IQ
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <img
                src={customerInfo?.basic_information?.photo}
                alt=""
                width="90px"
              />
            </Grid>
          </Grid>
          <br />
          <div style={{ background: "#f1f1f1", padding: "5px 16px" }}>
            <Typography variant="subtitle1" style={{ color: "#000" }}>
              Customer Profile
            </Typography>
          </div>
          <Table aria-label="simple table">
            <TableBody className={classes.tbodyStyle}>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>First name</TableCell>
                <TableCell>
                  : {customerInfo?.basic_information?.first_name}
                </TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>
                  : {customerInfo?.basic_information?.last_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>Email</TableCell>
                <TableCell>
                  : {customerInfo?.basic_information?.email}
                </TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>
                  : {customerInfo?.basic_information?.mobile_number}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>DOB</TableCell>
                <TableCell>
                  :{" "}
                  {customerInfo?.kyc?.date_of_birth &&
                    moment(customerInfo?.kyc?.date_of_birth).format(
                      "DD-MM-YYYY"
                    )}
                  {customerInfo?.kyc_detail?.date_of_birth &&
                    moment(customerInfo?.kyc_detail?.date_of_birth).format(
                      "DD-MM-YYYY"
                    )}
                </TableCell>
                <TableCell>Issue Country</TableCell>
                <TableCell>
                  : {customerInfo?.kyc?.issuing_country}
                  {customerInfo?.kyc_detail?.issuing_country}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>Document Type</TableCell>
                <TableCell>
                  : {customerInfo?.kyc?.document_type}
                  {customerInfo?.kyc_detail?.document_type}
                </TableCell>
                <TableCell>Issue Date</TableCell>
                <TableCell>
                  :{" "}
                  {customerInfo?.kyc?.issue_date &&
                    moment(customerInfo?.kyc?.issue_date).format("DD-MM-YYYY")}
                  {customerInfo?.kyc_detail?.issue_date &&
                    moment(customerInfo?.kyc_detail?.issue_date).format(
                      "DD-MM-YYYY"
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>Expair Date</TableCell>
                <TableCell>
                  :{" "}
                  {customerInfo?.kyc?.expiry_date &&
                    moment(customerInfo?.kyc?.expiry_date).format("DD-MM-YYYY")}
                  {customerInfo?.kyc_detail?.expiry_date &&
                    moment(customerInfo?.kyc_detail?.expiry_date).format(
                      "DD-MM-YYYY"
                    )}
                </TableCell>
                <TableCell>Document Id</TableCell>
                <TableCell>
                  : {customerInfo?.kyc?.document_id}{" "}
                  {customerInfo?.kyc_detail?.document_id}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>User Type</TableCell>
                <TableCell>: {customerInfo?.basic_information?.type}</TableCell>

                <TableCell>Status</TableCell>
                <TableCell>
                  : {customerInfo?.basic_information?.status}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <br />
          <div style={{ background: "#f1f1f1", padding: "5px 16px" }}>
            <Typography variant="subtitle1" style={{ color: "#000" }}>
              Attachment
            </Typography>
          </div>
          <br />
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ fontWeight: 400, color: "#000" }}
          >
            ID:
          </Typography>
          {customerInfo?.kyc?.document_files.map((item, i) => (
            <img
              key={i}
              src={item}
              alt=""
              style={{
                maxHeight: "150px",
                maxWidth: "300px",
                marginRight: "40px",
              }}
            />
          ))}
          {customerInfo?.kyc_detail?.document_files.map((item, i) => (
            <img
              key={i}
              src={item}
              alt=""
              style={{
                maxHeight: "150px",
                maxWidth: "300px",
                marginRight: "40px",
              }}
            />
          ))}
          <br /> <br /> <br /> <br /> <br /> <br />
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={3} style={{ textAlign: "center" }}>
              -------------------------
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 400, color: "#000" }}
              >
                Customer
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              -------------------------
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 400, color: "#000" }}
              >
                Agent
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              -------------------------
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 400, color: "#000" }}
              >
                Ops Manager
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              -------------------------
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 400, color: "#000" }}
              >
                Management
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>

      <Button
        variant="outlined"
        size={matches ? "small" : "large"}
        disableElevation
        onClick={handlePrint}
        startIcon={<DescriptionIcon />}
      >
        Download Info
      </Button>
    </div>
  );
};

export default UserInfoPDF;
