import React, { useState, useEffect, useContext, useMemo } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getDataWithToken } from "../../services/GetDataService";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ImageUpload from "./ImageUpload";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import Skeleton from "@mui/material/Skeleton";
import ShowMoreText from "react-show-more-text";
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    width: "400px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
}));
const Ticketdetail = () => {
  const classes = useStyles();
  let { id } = useParams();
  let navigate = useNavigate();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState("");
  const [ticketInfo, setTicketInfo] = useState({});
  const [ticketImage, setTicketImage] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [allFiles, setAllFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendReplyLoading, setSendReplyLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [changingTicketData, setChangingTicketData] = useState({});
  const [assignLogs, setAssignLogs] = useState([]);
  const [assignLogsMessage, setAssignLogsMessage] = useState("");
  const [conversations, setConversations] = useState([]);
  const [conversationsMessage, setConversationsMessage] = useState("");
  const [expand, setExpand] = useState(false);
  const [userListMessage, setUserListMessage] = useState("");
  const [issueCategoryMessage, setIssueCategoryMessage] = useState("");
  const [ticketStautusMessage, setTicketStautusMessage] = useState("");
  const [message, setMessage] = useState("");
  const [remarks, setRemarks] = useState("");
  const handleReadMore = () => {
    setExpand(!expand);
  };
  const handleClose = () => {
    setOpen(false);
    setRemarks("");
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const validation = () => {
    let isError = false;

    if (!description.trim()) {
      handleSnakbarOpen("Please enter description", "error");
      document.getElementById("description").focus();
      return (isError = true);
    }
    // if (!allFiles) {
    //   handleSnakbarOpen("Please select attachment", "error");

    //   return (isError = true);
    // }

    return isError;
  };
  const onTicketDataChange = (message, pathEnd, value, addRemarks) => {
    let data = { message, pathEnd, value };
    console.log("value", value);
    setChangingTicketData({ message, pathEnd, value, addRemarks });
    setOpen(true);
  };

  const onTicketDataChangeSubmit = async (e) => {
    e.preventDefault();

    let data = {};

    try {
      setUpdateLoading(true);
      switch (changingTicketData.pathEnd) {
        case "ticket-change-status":
          if (parseInt(changingTicketData.value) === 7) {
            data = {
              ticket_id: id,
              status: changingTicketData.value,
              remarks: remarks,
            };
          } else {
            data = {
              ticket_id: id,
              status: changingTicketData.value,
            };
          }

          break;
        case "ticket-change-customer-type":
          data = {
            ticket_id: id,
            customer_type: changingTicketData.value,
          };
          break;
        case "ticket-change-category":
          data = {
            ticket_id: id,
            issue_category_id: changingTicketData.value,
          };
          break;
        case "ticket-change-priority":
          data = {
            ticket_id: id,
            priority: changingTicketData.value,
          };
          break;
        case "ticket-change-assignee":
          data = {
            ticket_id: id,
            assigned_to: changingTicketData.value,
          };
          break;

        default:
          break;
      }

      let response = await axios({
        url: `api/${changingTicketData.pathEnd}`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      handleClose();

      switch (changingTicketData.pathEnd) {
        case "ticket-change-status":
          setStatus(changingTicketData.value);
          break;
        case "ticket-change-customer-type":
          setCustomerType(changingTicketData.value);
          break;
        case "ticket-change-category":
          setCategory(changingTicketData.value);
          break;
        case "ticket-change-priority":
          setPriority(changingTicketData.value);
          break;
        case "ticket-change-assignee":
          setUser(changingTicketData.value);

          let ticketData = await getDataWithToken(`api/ticket/${id}`);
          if (ticketData.status === 200) {
            setAssignLogs(ticketData.data.data.assign_logs);
          } else {
            setAssignLogsMessage(ticketData.data.messages.toString());
            if (ticketData.data.messages.length < 1) {
              setAssignLogsMessage("Something went wrong");
            }
          }
          break;

        default:
          break;
      }
      handleSnakbarOpen(response.data.messages.toString(), "success");
      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let err = validation();

    if (err) {
      return;
    } else {
      try {
        setSendReplyLoading(true);
        const formdata = new FormData();
        formdata.append("ticket_id", id);
        formdata.append("details", description);
        {
          allFiles !== null && formdata.append("attachments[0]", allFiles);
        }

        let response = await axios({
          url: "api/ticket-conversation",
          method: "post",
          data: formdata,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });

        handleSnakbarOpen(response.data.messages.toString(), "success");
        let ticketData = await getDataWithToken(`api/ticket/${id}`);
        if (ticketData.status === 200) {
          setConversations(ticketData.data.data.conversations);
        } else {
          setConversationsMessage(ticketData.data.messages.toString());
          if (ticketData.data.messages.length < 1) {
            setConversationsMessage("Something went wrong");
          }
        }
        setDescription("");
        setAllFiles(null);
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        setSendReplyLoading(false);
      }
      setSendReplyLoading(false);
    }
  };
  const getDropdownData = async () => {
    setLoading(true);
    let allUser = await getDataWithToken(`/api/user-list`);
    if (allUser.status === 200) {
      setUserList(allUser.data.data);
    } else {
      setUserListMessage(allUser.data.messages.toString());
      if (allUser.data.messages.length < 1) {
        setUserListMessage("Something went wrong");
      }
    }
    let allIssueCategories = await getDataWithToken(`api/issue-categories`);
    if (allIssueCategories.status === 200) {
      setCategoryList(allIssueCategories.data.data);
    } else {
      setIssueCategoryMessage(allIssueCategories.data.messages.toString());
      if (allIssueCategories.data.messages.length < 1) {
        setIssueCategoryMessage("Something went wrong");
      }
    }
    let allStatus = await getDataWithToken(`api/ticket-statuses`);
    if (allStatus.status === 200) {
      setStatusList(allStatus.data.data);
    } else {
      // handleSnakbarOpen(allStatus.data.messages.toString(), "error");
      setTicketStautusMessage(allStatus.data.messages.toString());
      if (allStatus.data.messages.length < 1) {
        setTicketStautusMessage("Something went wrong");
      }
    }
    let ticketData = await getDataWithToken(`api/ticket/${id}`);

    if (ticketData.status === 200) {
      setTicketInfo(ticketData.data.data);
      setTicketImage(ticketData.data.data.attachments[0]?.file_url);
      setCustomerType(ticketData.data.data.customer_type);
      setPriority(ticketData.data.data.priority);
      setStatus(ticketData.data.data.status);
      setCategory(ticketData.data.data.issue_category);
      setAssignLogs(ticketData.data.data.assign_logs);
      setConversations(ticketData.data.data.conversations);
    } else {
      // handleSnakbarOpen(ticketData.data.messages.toString(), "error");
      setMessage(ticketData.data.messages.toString());
      if (ticketData.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      {message.length > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "85vh" }}
        >
          <div className={classes.form}>
            <img
              src="/image/Logo.png"
              alt=""
              style={{ display: "block", margin: "auto", maxWidth: "155px" }}
            />
            <br />
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "30px" }}
            >
              {message}
            </Typography>
          </div>
        </Grid>
      ) : (
        <Grid container columnSpacing={3}>
          <Grid item xs={8}>
            <Paper style={{ padding: "16px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="description"
                    label="Enter Your description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={10}>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <ImageUpload
                      iconButton={true}
                      allFiles={allFiles}
                      setAllFiles={setAllFiles}
                    />
                  </div>
                  {allFiles !== null ? (
                    <>
                      &nbsp;&nbsp;
                      <Button
                        style={{
                          textDecoration: "none",
                          color: "#273c75",
                          textTransform: "none",
                        }}
                        onClick={() => setAllFiles(null)}
                      >
                        Cancel Attachment
                      </Button>
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  {/* <IconButton onClick={onSubmit}>
                  <SendIcon color="primary" />
                </IconButton> */}
                  <IconButton
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={sendReplyLoading}
                    onClick={onSubmit}
                  >
                    {sendReplyLoading === false && <SendIcon color="primary" />}
                    <PulseLoader
                      color={"#00848A"}
                      loading={sendReplyLoading}
                      size={10}
                      speedMultiplier={0.5}
                    />{" "}
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
            <br />
            <br />
            <Paper style={{ padding: "16px" }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item>
                      {loading ? (
                        <Skeleton variant="circular" width={45} height={45} />
                      ) : (
                        <Avatar
                          alt=""
                          src={ticketInfo.customer_photo}
                          sx={{ width: 45, height: 45 }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" gutterBottom component="div">
                        {loading ? (
                          <Skeleton width={250} />
                        ) : (
                          <> {ticketInfo.customer_name}</>
                        )}
                      </Typography>

                      <br />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  {loading ? (
                    <Skeleton width={120} style={{ float: "right" }} />
                  ) : (
                    <>
                      <label style={{ color: "#B2B3B4" }}>
                        {" "}
                        {ticketInfo.time}
                      </label>
                      <br />
                      {ticketImage !== "" && (
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#273c75",
                            cursor: "pointer",
                          }}
                          href={ticketImage}
                          target="_blank"
                        >
                          View Attachment
                        </a>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    {loading ? (
                      <>
                        <Skeleton />
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        {/* <label
                          style={{
                            fontSize: "20px",
                            textDecoration: "underline",
                          }}
                        >
                          Description :
                        </label>{" "} */}
                        <ShowMoreText
                          /* Default options */
                          lines={1}
                          more="Show more"
                          less="Show less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          onClick={handleReadMore}
                          expanded={expand}
                          width={500}
                          truncatedEndingComponent={"... "}
                        >
                          {ticketInfo.description}
                        </ShowMoreText>
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {conversations?.map((item, i) => (
                <Paper
                  style={{
                    padding: "16px",
                    marginBottom: "10px",

                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  }}
                  key={i}
                >
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={8}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Avatar
                            alt=""
                            src={item.user.profile_pic_url}
                            sx={{ width: 40, height: 40 }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            gutterBottom
                            component="div"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.user.name}
                          </Typography>
                          <Typography
                            style={{ fontSize: "11px", color: "#B2B3B4" }}
                          >
                            {" "}
                            {item.created_time}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {item.attachments[0]?.file_path ? (
                        <a
                          style={{ textDecoration: "none", color: "#273c75" }}
                          href={item.attachments[0]?.file_path}
                          target="_blank"
                        >
                          View Attachment
                        </a>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ShowMoreText
                          /* Default options */
                          lines={1}
                          more="Show more"
                          less="Show less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          onClick={handleReadMore}
                          expanded={expand}
                          width={500}
                          truncatedEndingComponent={"... "}
                        >
                          {item.details}
                        </ShowMoreText>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              {conversations.length < 1 &&
                loading &&
                [1, 2].map((item, i) => (
                  <Paper
                    style={{
                      padding: "16px",
                      marginBottom: "10px",

                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                    key={i}
                  >
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={8}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              gutterBottom
                              component="div"
                              style={{ fontWeight: "bold" }}
                            >
                              <Skeleton width={250} />
                            </Typography>
                            <Typography
                              style={{ fontSize: "11px", color: "#B2B3B4" }}
                            >
                              {" "}
                              <Skeleton width={180} />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton width={120} style={{ float: "right" }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                          <Skeleton height={120} style={{ width: "100%" }} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper style={{ padding: "16px" }}>
              <Grid
                container
                columnSpacing={3}
                style={{ marginBottom: "30px" }}
              >
                <Grid item xs={8}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {ticketInfo.customer_photo && (
                        <Avatar
                          alt=""
                          src={ticketInfo.customer_photo}
                          sx={{ width: 45, height: 45 }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="div"
                        style={{ marginBottom: "-5px", fontSize: "18px" }}
                      >
                        {ticketInfo.customer_name ? (
                          <>{ticketInfo.customer_name}</>
                        ) : (
                          <Skeleton width={250} />
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        style={{ margin: "0px", color: "#B2B3B4" }}
                      >
                        {ticketInfo.customer_mobile_number ? (
                          <>
                            {" "}
                            {/* <PhoneIphoneIcon
                              style={{
                                color: "#B2B3B4",
                                position: "relative",
                                top: "6px",
                                // fontSize: "14px",
                              }}
                            />  */}
                            {ticketInfo.customer_mobile_number}
                          </>
                        ) : (
                          <Skeleton width={220} />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  {ticketInfo.customer_mobile_number && (
                    <p
                      style={{ cursor: "pointer", color: "#273c75", margin: 0 }}
                      // to=`/user/+9641600000022`
                      onClick={() =>
                        navigate(`/user/${ticketInfo.customer_mobile_number}`)
                      }
                    >
                      Go to Profile
                    </p>
                  )}
                </Grid>
              </Grid>

              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-category-outlined-label">
                  Category
                </InputLabel>
                <Select
                  labelId="demo-category-outlined-label"
                  id="demo-category-outlined"
                  label="Category"
                  value={category}
                  onChange={(e) =>
                    onTicketDataChange(
                      `You want to change category`,

                      "ticket-change-category",
                      e.target.value,
                      false
                    )
                  }
                >
                  {categoryList?.map((item, i) => (
                    <MenuItem key={i} value={item.id} title={item.issue}>
                      {item.issue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-status-outlined-label">Status</InputLabel>
                <Select
                  labelId="demo-status-outlined-label"
                  id="demo-status-outlined"
                  label="Status"
                  value={status}
                  onChange={(e) =>
                    onTicketDataChange(
                      `You want to change status`,

                      "ticket-change-status",
                      e.target.value,
                      parseInt(e.target.value) === 7 ? true : false
                    )
                  }
                >
                  {statusList?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="priority-outlined-label">Priority</InputLabel>
                <Select
                  labelId="priority-outlined-label"
                  id="priority-outlined"
                  label="Priority"
                  value={priority}
                  onChange={(e) =>
                    onTicketDataChange(
                      `You want to make priority ${e.target.value}`,

                      "ticket-change-priority",
                      e.target.value,
                      false
                    )
                  }
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-customer-type-outlined-label">
                  Customer Type
                </InputLabel>
                <Select
                  labelId="demo-customer-type-outlined-label"
                  id="demo-customer-type-outlined"
                  label=" Customer Type"
                  value={customerType}
                  onChange={(e) =>
                    onTicketDataChange(
                      `You want to make customer type ${e.target.value}`,
                      "ticket-change-customer-type",
                      e.target.value,
                      false
                    )
                  }
                >
                  <MenuItem value="Personal">Personal</MenuItem>
                  <MenuItem value="Merchant">Merchant</MenuItem>
                  <MenuItem value="Agent">Agent</MenuItem>
                  <MenuItem value="SR">SR</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginBottom: "30px" }}
              >
                <InputLabel id="demo-assign-outlined-label">
                  Assign To
                </InputLabel>
                <Select
                  labelId="demo-assign-outlined-label"
                  id="demo-assign-outlined"
                  label="Assign to"
                  value={user}
                  onChange={(e) =>
                    onTicketDataChange(
                      `You want to add a new assignee`,
                      "ticket-change-assignee",
                      e.target.value,
                      false
                    )
                  }
                >
                  {userList?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div style={{ paddingLeft: "10px" }}>
                <Typography variant="h6" gutterBottom component="div">
                  Assign Logs
                </Typography>
                {assignLogs?.map((item, i) => (
                  <Grid container alignContent="center" key={i}>
                    <Grid item>{item.assigned_by_profile?.name}</Grid>
                    <Grid item>
                      &nbsp; &nbsp; &nbsp; <ArrowForwardIcon /> &nbsp; &nbsp;
                      &nbsp;
                    </Grid>
                    <Grid item>{item.assigned_to_profile?.name}</Grid>
                  </Grid>
                ))}
                {assignLogs.length < 1 &&
                  loading &&
                  [1, 2].map((item, i) => <Skeleton key={i} />)}
              </div>
              <br />
            </Paper>{" "}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {changingTicketData.message}
              {changingTicketData.addRemarks && (
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Type the reason"
                  multiline
                  rows={4}
                  fullWidth
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={onTicketDataChangeSubmit}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default Ticketdetail;
