import axios from "axios";

const getDataWithToken = async (url,responseType) => {
  try {
    const localData = JSON.parse(
      localStorage.getItem("fastpay_support_panel_user")
    );

    // console.log("localData.token", localData.token);
    let response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${localData.token}`,
      },
      responseType: responseType ? responseType : "",
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log("error", error);
    return error.response;
  }
};

export { getDataWithToken };
