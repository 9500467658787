import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const StoreManagement = ({ rollPermission }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [storeId, setStoreId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [storeType, setStoreType] = useState("");
  const [status, setStatus] = useState("");
  const [visibility, setVisibility] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [storeList, setStoreList] = useState([]);

  const [users, setUsers] = useState([]);
  const [selectedForRole, setSelectedForUpdate] = useState({});
  const [newUpdateValue, setNewUpdateValue] = useState({});

  // const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [categoryMessage, setCategoryMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateLoading, setUpdateLoading] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleSwitchClose = () => {
    setSwitchOpen(false);
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell colSpan={2}>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const clearFilter = (event) => {
    setMobileNumber("");
    setStoreId("");
    setBusinessName("");
    setCategory("");
    setStoreType("");
    setStatus("");
    setVisibility("");
    setPage(0);
    let newUrl = `api/store/store-configuration`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newCategory = category;
        let newStatus = status;
        let newVisibility = visibility;
        let newStoreType = storeType;
        if (storeType === "None") {
          newStoreType = "";
        }
        if (visibility === "None") {
          newVisibility = "";
        }
        if (status === "None") {
          newStatus = "";
        }
        if (category === "None") {
          newCategory = "";
        }
        url = `api/store/store-configuration?store_id=${storeId.trim()}&bussiness_name=${encodeURIComponent(
          businessName.trim()
        )}&mobile_number=${encodeURIComponent(
          mobileNumber.trim()
        )}&store_type=${newStoreType}&category_id=${newCategory}&status=${newStatus}&visibility=${newVisibility}&page=${newPageNO}`;
      }
      let allStores = await getDataWithToken(url);

      if (allStores.data.code === 200) {
        setStoreList(allStores.data.data.data);
        setTotalData(allStores.data.data.total);
        if (allStores.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(allStores.data.messages.toString());
        if (allStores.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };

  const updateStatus = async (e) => {
    e.preventDefault();

    try {
      setUpdateLoading(true);
      let newStatus;
      if (newUpdateValue.value) {
        newStatus = 1;
      } else {
        newStatus = 0;
      }

      let data = {
        store_configuration_id: newUpdateValue.id,
        status: newStatus,
      };

      let response = await axios({
        url: `api/store/change-store-status`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        let newUserObj = {
          ...selectedForRole,
          status: newStatus === 1 ? "Active" : "Inactive",
        };
        storeList[newUpdateValue.index] = newUserObj;

        handleSnakbarOpen(response.data.messages.toString(), "success");
        setSwitchOpen(false);
      }

      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      if (error.response.data.messages.length < 1) {
        handleSnakbarOpen("Something went wrong", "error");
      }
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };

  const getDropdownData = async () => {
    let allCategories = await getDataWithToken(`api/business/categories`);
    if (allCategories.status === 200) {
      setCategoryList(allCategories.data.data);
    } else {
      setCategoryMessage(allCategories.data.messages.toString());
      if (allCategories.data.messages.length < 1) {
        setCategoryMessage("Something went wrong");
      }
    }
  };
  useEffect(() => {
    getData();
    getDropdownData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={9} xl={9}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              Store List
            </Typography>
          </Grid>
          <Grid item lg={3} xl={3} style={{ textAlign: "right" }}>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              color="success"
              // startIcon={<FilterListIcon />}
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filterOpen ? <FilterListOffIcon /> : <FilterListIcon />}
            </Button>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Collapse in={filterOpen} timeout="auto" unmountOnExit>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    id="mobile-no"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Mobile No"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="store-id"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Store Id"
                    value={storeId}
                    onChange={(e) => setStoreId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="businessName"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Business Name"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-status-outlined-label">
                      Store Category
                    </InputLabel>
                    <Select
                      labelId="demo-status-outlined-label"
                      id="demo-status-outlined"
                      label="Store Category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <MenuItem value="None">
                        {categoryMessage.length > 0 ? (
                          <>{categoryMessage}</>
                        ) : (
                          "None"
                        )}
                      </MenuItem>
                      {categoryList?.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-is-assigned-outlined-label">
                      Store Type
                    </InputLabel>
                    <Select
                      labelId="demo-is-assigned-outlined-label"
                      id="demo-is-assigned-outlined"
                      label="Store Type"
                      value={storeType}
                      onChange={(e) => setStoreType(e.target.value)}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value={1}>Online</MenuItem>
                      <MenuItem value={2}>Offline / Physical</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-is-assigned-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-is-assigned-outlined-label"
                      id="demo-is-assigned-outlined"
                      label="Status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-is-assigned-outlined-label">
                      Visibility
                    </InputLabel>
                    <Select
                      labelId="demo-is-assigned-outlined-label"
                      id="demo-is-assigned-outlined"
                      label="Visibility"
                      value={visibility}
                      onChange={(e) => setVisibility(e.target.value)}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value={1}>Visible</MenuItem>
                      <MenuItem value={0}>Not Visible</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={{ lg: 6, xl: 3 }}>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={(event) => clearFilter(event, 0)}
                      >
                        <RestartAltIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        disableElevation
                        // color="success"
                        style={{ background: "#435185", padding: "6.7px 22px" }}
                        size="large"
                        startIcon={<SearchIcon />}
                        fullWidth
                        onClick={(event) => handleChangePage(event, 0)}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <br />
        <Paper>
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 370px)",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell colSpan={2} style={{ minWidth: "250px" }}>
                    Owner
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: "nowrap", minWidth: "200px" }}
                  >
                    {" "}
                    Business Name
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    Store ID
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Store Type
                  </TableCell>
                  <TableCell style={{ minWidth: "120px" }}>Category</TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Location
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Visible
                  </TableCell>

                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Status
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Created at
                  </TableCell>
                  <TableCell style={{ minWidth: "120px" }}>Remarks</TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!loading &&
                  storeList.length > 0 &&
                  storeList.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ paddingRight: 0, width: "50px" }}>
                        <Avatar
                          alt=""
                          src={row.merchant?.photo}
                          sx={{ width: 45, height: 45 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ fontSize: "16px", fontWeight: 500 }}
                        >
                          {row.merchant?.name}
                        </Typography>
                        {row.merchant?.mobile_no}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row.bussiness_name}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row.store_id}
                      </TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {row.store_category?.text}
                      </TableCell>
                      <TableCell align="center">{row.address}</TableCell>
                      <TableCell align="center">
                        {row.visibility === 1 ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        {rollPermission?.includes(
                          "Change store configuration status"
                        ) ? (
                          <Switch
                            color="success"
                            checked={row.status === "Active" ? true : false}
                            onChange={(e) => {
                              setNewUpdateValue({
                                index: i,
                                id: row.id,
                                value: e.target.checked,
                              });
                              setSelectedForUpdate(row);

                              setSwitchOpen(true);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        ) : (
                          <>
                            <img
                              src={
                                row.status === "Active"
                                  ? "/image/verified.svg"
                                  : "/image/unverified.svg"
                              }
                              style={{
                                width: "20px",
                                position: "relative",
                                top: 5,
                              }}
                              alt=""
                            />{" "}
                            {row.status}
                          </>
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {row.created_at}
                      </TableCell>
                      <TableCell>{row.remarks}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          component={Link}
                          to={`/store-detail/${row.id}`}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {rollPermission?.includes(
                          "Edit store configuration"
                        ) && (
                          <IconButton
                            onClick={() => {
                              navigate(`/update-store-detail/${row.id}`);
                            }}
                          >
                            <EditIcon color="info" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                {!loading && storeList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
          {storeList.length > 0 && (
            <div>
              <TablePagination
                style={{ display: "block", borderBottom: "none" }}
                rowsPerPageOptions={[]}
                // count={rows.length}
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </TableContainer>

      <Dialog
        open={switchOpen}
        onClose={handleSwitchClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to change store status
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSwitchClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateStatus}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default StoreManagement;
