import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },

    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
      fontWeight: 600,
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
}));

const ActivityLogs = ({ userId }) => {
  const classes = useStyles();
  const { slug } = useParams();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [ip, setIp] = useState("");
  const [customerActivityLog, setCustomerActivityLog] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [order, setOrder] = useState(true);

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setIp("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);
    setOrder(true);
    let newData = {
      user_id: slug,
      ip: "",
      from: "",
      to: "",
      order_by_desc: "",
      page: 1,
    };
    getData("", newData);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };
  const handleOrderChange = (orderBy) => {
    setOrder(orderBy);
    setPage(0);
    let newOrderBy = 1;
    let newStartingTime = "";
    let newEndingTime = "";
    if (orderBy === false) {
      newOrderBy = "";
    }
    if (startingTime !== null) {
      newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (endingTime !== null) {
      newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    let newData = {
      user_id: slug,
      ip: ip,
      from: newStartingTime,
      to: newEndingTime,
      order_by_desc: newOrderBy,
      page: 1,
    };
    getData("", newData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newData) => {
    try {
      setLoading(true);
      let newPageNO;
      let data;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newData) {
        data = newData;
      } else {
        let newOrderBy = 1;
        let newStartingTime = "";
        let newEndingTime = "";
        if (order === false) {
          newOrderBy = "";
        }
        if (startingTime !== null) {
          newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
        }
        if (endingTime !== null) {
          newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
        }

        data = {
          user_id: slug,
          ip: ip,
          from: newStartingTime,
          to: newEndingTime,
          order_by_desc: newOrderBy,
          page: newPageNO,
        };
      }

      let allCustomerActivityLog = await axios({
        // url: "api/customer-activity-log-data",
        url: "api/panel-user-activity-log-list",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (allCustomerActivityLog.status === 200) {
        setCustomerActivityLog(allCustomerActivityLog.data.data.data);
        setTotalData(allCustomerActivityLog.data.data.total);
        setRowsPerPage(allCustomerActivityLog.data.data.per_page);
        if (allCustomerActivityLog.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(allCustomerActivityLog.data.messages.toString());
        if (allCustomerActivityLog.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }
    } catch (error) {
      console.log("error", error);

      setMessage(error.response.data.messages.toString());
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Grid container columnSpacing={3} style={{ padding: "0 0 16px 0" }}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                id="ip"
                fullWidth
                size="small"
                variant="outlined"
                label="IP"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
              />
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Starting Time"
                  value={startingTime}
                  onChange={(newValue) => {
                    setStartingTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Ending Time"
                  value={endingTime}
                  onChange={(newValue) => {
                    setEndingTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={{ lg: 6, xl: 3 }}>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    disableElevation
                    style={{ background: "#435185", padding: "6.7px 22px" }}
                    size="large"
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={(event) => handleChangePage(event, 0)}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper>
        <div style={{ overflowX: "auto", width: "Calc(100vw - 370px)" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell style={{ minWidth: "140px" }}>Description</TableCell>
                <TableCell style={{ width: "140px" }}>IP Address</TableCell>
                <TableCell style={{ width: "180px" }}>
                  Performed At
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOrderChange(!order)}
                  >
                    {order ? (
                      <KeyboardArrowUpIcon style={{ color: "#fff" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                customerActivityLog.length > 0 &&
                customerActivityLog.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {row.ip !== null ? row.ip : <p>-----</p>}
                    </TableCell>
                    <TableCell>{row.created_at}</TableCell>
                  </TableRow>
                ))}

              {!loading && customerActivityLog.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {customerActivityLog.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </>
  );
};

export default ActivityLogs;
