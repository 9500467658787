import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CopyBlock, dracula } from "react-code-blocks";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:nth-child(-n+3)": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td:nth-child(-n+3)": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
  tableHeadStyle2: {
    // background: "#2B335E",
    "& th": {
      // color: "#fff",
      fontSize: "20px",
      borderBottom: "2px solid #353b48",
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tdStyle: {
    "& td": {
      paddingTop: "7px",
      paddingBottom: "7px",
      borderBottom: "none",
      fontSize: "15px",
    },
  },
}));

const FIBRequests = ({ rollPermission }) => {
  const classes = useStyles();
  const [transactionId, setTransactionId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [paymentId, setPaymentId] = useState("");
  const [status, setStatus] = useState("");
  const [allFIBTopups, setAllFIBTopups] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [detailOpen, setDetailOpen] = useState(false);
  const [requestPayload, setRequestPayload] = useState("");
  const [reqChecked, setReqChecked] = useState(false);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [startAmount, setStartAmount] = useState("");
  const [endAmount, setEndAmount] = useState("");
  const [order, setOrder] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [depositDialog, setDepositDialog] = useState(false);
  const [depositData, setDepositData] = useState({});
  const handleDepositDialogClose = () => {
    setDepositDialog(false);
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>

          <TableCell style={{ paddingRight: 0, width: "50px" }}>
            <Skeleton variant="circular" width={45} height={45} />
          </TableCell>
          <TableCell>
            <Typography
              gutterBottom
              component="div"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              <Skeleton></Skeleton>
            </Typography>
            <Skeleton></Skeleton>
          </TableCell>

          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };
  const handleOrderChange = (orderBy) => {
    setOrder(orderBy);
    setPage(0);
    let newOrderBy = 1;
    let newStartingTime = "";
    let newEndingTime = "";
    let newStatus = status;

    if (startingTime !== null) {
      newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (endingTime !== null) {
      newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
    }

    if (status === "None") {
      newStatus = "";
    }
    if (orderBy === false) {
      newOrderBy = "";
    }

    let newStartAmount = "";
    let newEndAmount = "";
    if (startAmount.trim().length > 0) {
      newStartAmount = parseInt(startAmount.trim());
    }
    if (endAmount.trim().length > 0) {
      newEndAmount = parseInt(endAmount.trim());
    }
    let newData = {
      mobile_number: mobileNo,
      payment_id: paymentId.trim(),
      from_amount: newStartAmount,
      to_amount: newEndAmount,
      transaction_id: transactionId.trim(),
      status: newStatus,
      from_date: newStartingTime,
      to_date: newEndingTime,
      order_by_desc: newOrderBy,
      page: 1,
    };

    getData("", newData);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newData) => {
    try {
      setLoading(true);
      let newPageNO;
      let data;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newData) {
        data = newData;
      } else {
        let newOrderBy = 1;
        let newStartingTime = "";
        let newEndingTime = "";
        let newStatus = status;

        if (startingTime !== null) {
          newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
        }
        if (endingTime !== null) {
          newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
        }

        if (status === "None") {
          newStatus = "";
        }
        if (order === false) {
          newOrderBy = "";
        }

        let newStartAmount = "";
        let newEndAmount = "";
        if (startAmount.trim().length > 0) {
          newStartAmount = parseInt(startAmount.trim());
        }
        if (endAmount.trim().length > 0) {
          newEndAmount = parseInt(endAmount.trim());
        }
        data = {
          mobile_number: mobileNo,
          payment_id: paymentId.trim(),
          from_amount: newStartAmount,
          to_amount: newEndAmount,
          transaction_id: transactionId.trim(),
          status: newStatus,
          from_date: newStartingTime,
          to_date: newEndingTime,
          order_by_desc: newOrderBy,
          page: newPageNO,
        };
      }

      let fibTopups = await axios({
        url: `api/fib-topup-list`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (fibTopups.data.code === 200) {
        setAllFIBTopups(fibTopups.data.data.data);
        setTotalData(fibTopups.data.data.total);
        setRowsPerPage(fibTopups.data.data.per_page);

        if (fibTopups.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(fibTopups.data.messages);
        if (fibTopups.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setMessage(error.response.data.messages.toString());
      setLoading(false);
    }
  };

  const handleDeposit = (i, id) => {
    setDepositData({ index: i, id: id });
    setDepositDialog(true);
  };
  const handleDepositSubmit = async (i, id) => {
    try {
      setLoading2(true);
      let response = await axios({
        url: `api/fib-deposit-money`,
        method: "post",
        data: { payment_id: depositData.id },
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      handleSnakbarOpen(response.data.messages.toString(), "success");
      console.log("i", i);
      console.log("allFIBTopups", allFIBTopups);
      allFIBTopups[depositData.index].enable_deposit = false;
      setDepositDialog(false);
      setLoading2(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setLoading2(false);
      setDepositDialog(false);
    }
  };

  const getRequestPayload = async (data) => {
    setDetailOpen(true);
    setRequestPayload(data);
  };

  const handleDetailDialogClose = () => {
    setDetailOpen(false);
    // setRequestPayload("");
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={6} xl={6}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            FIB Requests
          </Typography>
        </Grid>
        <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
          {/* <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            // startIcon={<FilterListIcon />}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button> */}
        </Grid>
        <br />
        {/* <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item md={3} lg={3}>
                <TextField
                  id="payment-id"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Payment Id"
                  value={paymentId}
                  onChange={(e) => setPaymentId(e.target.value)}
                />
              </Grid>
              <Grid item md={3} lg={3}>
                <TextField
                  id="mobileNo"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </Grid>

              <Grid item md={3} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Initiated">Initiated</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                    <MenuItem value="Failed">Failed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} lg={3}>
                <TextField
                  id="transaction-id"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Transaction Id"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </Grid>

              <Grid item md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Starting Time"
                    value={startingTime}
                    onChange={(newValue) => {
                      setStartingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Ending Time"
                    value={endingTime}
                    onChange={(newValue) => {
                      setEndingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} lg={3}>
                <TextField
                  id="start-amount"
                  type="number"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Start Amount"
                  value={startAmount}
                  onChange={(e) => setStartAmount(e.target.value)}
                />
              </Grid>
              <Grid item md={3} lg={3}>
                <TextField
                  id="end-amount"
                  type="number"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="End Amount"
                  value={endAmount}
                  onChange={(e) => setEndAmount(e.target.value)}
                />
              </Grid>
              <Grid item md={9} lg={9}></Grid>
              <Grid item md={3} lg={3}>
                <Grid container spacing={{ md: 12, lg: 6, xl: 3 }}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid> */}
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell style={{ minWidth: "150px" }}>Payment Id</TableCell>
                <TableCell colSpan={2} style={{ minWidth: "200px" }}>
                  User
                </TableCell>

                <TableCell align="center">Amount</TableCell>

                {/* <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Current Balance
                </TableCell> */}
                <TableCell style={{ minWidth: "90px" }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ minWidth: "150px" }}>
                  Created At{" "}
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOrderChange(!order)}
                  >
                    {order ? (
                      <KeyboardArrowUpIcon style={{ color: "#fff" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell style={{ minWidth: "150px" }}>Updated At</TableCell>

                {/* <TableCell style={{ minWidth: "110px" }}>Remaks</TableCell> */}
                {/* {rollPermission?.includes("fib deposit money") && (
                  <TableCell style={{ minWidth: "110px" }} align="center">
                    Action
                  </TableCell>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                allFIBTopups.length > 0 &&
                allFIBTopups.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      style={{
                        width: "150px",
                      }}
                    >
                      {row.tx_unique_id}
                    </TableCell>
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row?.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row?.first_name} {row?.last_name}
                      </Typography>
                      {row?.mobile_no}
                    </TableCell>

                    <TableCell align="center">{row.amount}</TableCell>

                    {/* <TableCell align="center">
                      {row.sender_current_balance}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      style={{
                        color: row.status === 0 && "#4dd0e1",
                      }}
                    >
                      {row.status === 0 ? "Initiated" : row.status}
                    </TableCell>

                    <TableCell>{row.created_at}</TableCell>
                    <TableCell>{row.updated_at}</TableCell>
                  </TableRow>
                ))}

              {!loading && allFIBTopups.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={13} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {allFIBTopups.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
        <Dialog
          onClose={handleDetailDialogClose}
          aria-labelledby="simple-dialog-title"
          open={detailOpen}
          maxWidth={"450px"}
        >
          <div id="simple-dialog-title" style={{ padding: "16px 16px" }}>
            <Typography variant="h6">Request Payload</Typography>

            <IconButton
              aria-label="delete"
              onClick={handleDetailDialogClose}
              style={{ position: "absolute", top: 13, right: 10 }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {requestPayload ? (
            <div
              className={classes.codeBlockStyle}
              style={{ minWidth: "450px" }}
            >
              <Collapse in={reqChecked} collapsedSize={120}>
                <div style={{ position: "relative" }}>
                  {/* <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => setReqChecked(!reqChecked)}
                    style={{
                      display: "block",
                      position: "absolute",
                      top: 10,
                      right: 10,
                      zIndex: 1,
                    }}
                  >
                    {reqChecked ? (
                      <KeyboardArrowUpIcon
                        size="small"
                        style={{
                          color: "#fff",
                          background: "#959292",
                          borderRadius: "25px",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        size="small"
                        style={{
                          color: "#fff",
                          background: "#959292",
                          borderRadius: "25px",
                        }}
                      />
                    )}
                  </IconButton> */}

                  <CopyBlock
                    text={JSON.stringify(requestPayload, null, 4)}
                    language={"jsx"}
                    showLineNumbers={false}
                    theme={dracula}
                    codeBlock
                    wrapLongLines={true}
                  />
                </div>
              </Collapse>
            </div>
          ) : (
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                minWidth: "450px",
              }}
            >
              No data found
            </Typography>
          )}
        </Dialog>
        <Dialog
          open={depositDialog}
          onClose={handleDepositDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ padding: "10px", minWidth: "300px" }}>
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You want to deposit the money
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDepositDialogClose}>cancel</Button>
              <Button
                variant="contained"
                disabled={loading2}
                onClick={handleDepositSubmit}
                style={{ minWidth: "100px" }}
                autoFocus
              >
                <PulseLoader
                  color={"#00848A"}
                  loading={loading2}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
                {loading2 === false && "Confirm"}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Paper>
    </TableContainer>
  );
};

export default FIBRequests;
