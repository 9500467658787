import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableHeadStyle: {
    // background: "#00848A",
    "& th": {
      // color: "#fff",
      fontSize: "16px",
      fontWeight: "600 !important",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tableBodyStyle: {
    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
}));

const UserKYCDocument = ({
  basicInformation,
  KYCInfo,
  loading,
  level,
  levelId,
  userInfoMessage,
  getDataAfterStatusUpdate,
  rollPermission,
}) => {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [status, setStatus] = useState(KYCInfo?.status);
  const [temporaryLevelId, setTemporaryLevelId] = useState(levelId);
  const [imageDialog, setImageDialog] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [statusDialog, setStatusDialog] = useState(false);
  const [changingStatusData, setChangingStatusData] = useState("");
  const [reason, setReason] = useState("");
  const [statusLoading, setStatusLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const openImageDialog = (src) => {
    setImgSrc(src);
    setImageDialog(true);
  };
  const handleClose = () => {
    setImageDialog(false);
  };
  const handleStatusClose = () => {
    setStatusDialog(false);
  };

  const onStatusSubmit = async (value) => {
    setStatusLoading(true);
    try {
      let data = {
        user_id: KYCInfo.user_id,
        kyc_id: KYCInfo.kyc_id,
        kyc_status: changingStatusData,
        level: temporaryLevelId,
        reason: reason,
      };
      let response = await axios({
        url: "api/kyc-update",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      if (response.data.code === 200) {
        handleSnakbarOpen(response.data.messages.toString(), "success");

        getDataAfterStatusUpdate();
        setStatusDialog(false);
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setStatusLoading(false);
    }
    setStatusLoading(false);
  };

  const fnStatusChange = (value) => {
    setChangingStatusData(value);
    setStatusDialog(true);
  };

  return (
    <>
      {userInfoMessage.length > 0 && (
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 600, textAlign: "center" }}
          component="div"
        >
          {userInfoMessage}
        </Typography>
      )}
      {KYCInfo === null ? (
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 600, textAlign: "center" }}
          gutterBottom
          component="div"
        >
          No Data Found
        </Typography>
      ) : (
        <Table
          aria-label="simple table"
          style={{ border: "1px solid #dcdde1" }}
        >
          <TableBody className={classes.tableBodyStyle}>
            {loading && pageLoading()}

            {!loading && KYCInfo && Object.entries(KYCInfo).length !== 0 && (
              <>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Issuing Country</TableCell>
                  <TableCell>{KYCInfo?.issuing_country}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Document Type</TableCell>
                  <TableCell>{KYCInfo?.document_type}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Document ID</TableCell>
                  <TableCell>{KYCInfo?.document_id}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Document File</TableCell>
                  <TableCell>
                    {KYCInfo?.document_files?.map((item, i) => (
                      <img
                        key={i}
                        src={item}
                        onClick={() => openImageDialog(item)}
                        alt=""
                        style={{
                          width: "150px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  </TableCell>
                </TableRow>
                {KYCInfo?.trade_licence_doc.length > 0 && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>Trade License</TableCell>
                    <TableCell>
                      {KYCInfo?.trade_licence_doc?.map((item, i) => (
                        <img
                          key={i}
                          src={item}
                          onClick={() => openImageDialog(item)}
                          alt=""
                          style={{
                            width: "150px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Full Name</TableCell>
                  <TableCell>{KYCInfo?.full_name}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Surname</TableCell>
                  <TableCell>{KYCInfo?.surname}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Date of Birth</TableCell>
                  <TableCell>{KYCInfo?.date_of_birth}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Issue Date</TableCell>
                  <TableCell>{KYCInfo?.issue_date}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Expiry Date</TableCell>
                  <TableCell>{KYCInfo?.expiry_date}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Status</TableCell>
                  <TableCell>{KYCInfo?.status}</TableCell>
                </TableRow>
                {KYCInfo?.status_editable === 1 && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>Action</TableCell>
                    <TableCell>
                      <>
                        <Button
                          className={classes.approveStatus}
                          variant="contained"
                          color="success"
                          disableElevation
                          onClick={() => fnStatusChange("1")}
                        >
                          Approve
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          className={classes.pendingStatus}
                          variant="contained"
                          color="error"
                          disableElevation
                          onClick={() => fnStatusChange("9")}
                        >
                          Declined
                        </Button>
                      </>
                    </TableCell>
                  </TableRow>
                )}
                {/* {KYCInfo?.status === "Verified" && ( */}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Remarks</TableCell>
                  <TableCell>{KYCInfo?.reason}</TableCell>
                </TableRow>
                {/* )} */}
              </>
            )}
          </TableBody>
        </Table>
      )}
      <Dialog
        open={imageDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 20 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <br />
        <DialogContent>
          <img src={imgSrc} alt="" style={{ width: "500px" }} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={statusDialog}
        onClose={handleStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent style={{ paddingBottom: 0 }}>
            <DialogContentText id="alert-dialog-description">
              You want to update user status?
            </DialogContentText>
            {changingStatusData == "1" && (
              <>
                {rollPermission?.includes("Customer level update") && (
                  <>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.centerSelectStyle}
                    >
                      <Select
                        labelId="demo-store-category-outlined-label"
                        // id="demo-store-category-outlined"
                        value={temporaryLevelId}
                        onChange={(e) => {
                          setTemporaryLevelId(e.target.value);
                        }}
                      >
                        {level?.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Type the reason"
                  multiline
                  rows={4}
                  fullWidth
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </>
            )}
            {changingStatusData == "9" && (
              <>
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Type the reason"
                  multiline
                  rows={4}
                  fullWidth
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </>
            )}
          </DialogContent>
          <DialogActions style={{ paddingRight: "24px" }}>
            <Button onClick={handleStatusClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={statusLoading}
              onClick={onStatusSubmit}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={statusLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {statusLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default UserKYCDocument;
