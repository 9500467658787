import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { getDataWithToken } from "../../services/GetDataService";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
  tableHeadStyle: {
    // background: "#00848A",
    "& th": {
      // color: "#fff",
      // fontSize: "16px",
      fontWeight: "bold",
    },
  },
}));
// { limits, loading2 }
const UserLimits = ({ mobileNo, userInfoMessage }) => {
  const classes = useStyles();
  const { slug } = useParams();
  const [limits, setLimits] = useState({});
  const [limitMessage, setLimitMessage] = useState("");
  const [loading2, setLoading2] = useState(false);
  const getDropdownData = async () => {
    if (mobileNo === undefined) {
      setLimitMessage(userInfoMessage);
    } else {
      setLoading2(true);

      let limitUrl = `api/limits?mobile_no=${encodeURIComponent(mobileNo)}`;
      let userLimitResponse = await getDataWithToken(limitUrl);

      if (userLimitResponse.status === 200) {
        setLimits(userLimitResponse.data.data);
      } else {
        setLimitMessage(userLimitResponse.data.messages.toString());
        if (userLimitResponse.data.messages.length < 1) {
          setLimitMessage("Something went wrong");
        }
      }

      setLoading2(false);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <>
      {!loading2 && limitMessage.length > 0 ? (
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 600, textAlign: "center" }}
          component="div"
        >
          {limitMessage}
        </Typography>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {loading2 ? (
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600 }}
                gutterBottom
                component="div"
              >
                loading...
              </Typography>
            ) : (
              <>
                {!loading2 && limits?.daily?.length > 0 ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: 600 }}
                      gutterBottom
                      component="div"
                    >
                      Daily Limit
                    </Typography>

                    <Table
                      aria-label="simple table"
                      style={{ border: "1px solid #dcdde1" }}
                    >
                      <TableHead className={classes.tableHeadStyle}>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell> Count</TableCell>
                          <TableCell> Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBodyStyle}>
                        {limits?.daily?.map((item, i) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={i}
                          >
                            <TableCell>{item.service_type}</TableCell>
                            <TableCell>
                              {item.consumed_tx_count} /{" "}
                              {item.total_max_no_of_tx}{" "}
                            </TableCell>
                            <TableCell>
                              {item.consumed_tx_amount} /{" "}
                              {item.total_max_amount}{" "}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                    component="div"
                  >
                    No daily limit is set
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {loading2 ? (
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600 }}
                gutterBottom
                component="div"
              >
                loading...
              </Typography>
            ) : (
              <>
                {!loading2 && limits?.monthly?.length > 0 ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: 600 }}
                      gutterBottom
                      component="div"
                    >
                      Monthy Limit
                    </Typography>

                    <Table
                      aria-label="simple table"
                      style={{ border: "1px solid #dcdde1" }}
                    >
                      <TableHead className={classes.tableHeadStyle}>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell> Count</TableCell>
                          <TableCell> Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBodyStyle}>
                        {limits?.monthly?.map((item, i) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={i}
                          >
                            <TableCell>{item.service_type}</TableCell>
                            <TableCell>
                              {item.consumed_tx_count} /{" "}
                              {item.total_max_no_of_tx}{" "}
                            </TableCell>
                            <TableCell>
                              {item.consumed_tx_amount} /{" "}
                              {item.total_max_amount}{" "}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                    component="div"
                  >
                    No monthly limit is set
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserLimits;
