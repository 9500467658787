import React, { useState, useEffect } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSnackbar } from "notistack";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import PulseLoader from "react-spinners/PulseLoader";
import Menu from "@mui/material/Menu";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 6px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const EKYCAgentVerifiedRequest = ({ rollPermission }) => {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [docId, setDocId] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [docList, setDocList] = useState([]);
  const [docListMessage, setDocListMessage] = useState("");
  const [docTypeId, setDocTypeId] = useState("");
  const [status, setStatus] = useState("");
  const [kybRequestList, setKybRequestList] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [downloadloading, setDownloadloading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          {rollPermission?.includes("KYC request details") && (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          )}
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setMobileNumber("");
    setEmail("");
    setDocTypeId("");
    setDocId("");
    setStatus("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);
    let newUrl = `api/kyc-agent-verified-info`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newStartingTime = "";
        let newEndingTime = "";
        if (startingTime !== null) {
          newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
        }
        if (endingTime !== null) {
          newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
        }
        let newDocTypeId = docTypeId;
        if (docTypeId === "None") {
          newDocTypeId = "";
        }
        url = `api/kyc-agent-verified-info?mobile_number=${encodeURIComponent(
          mobileNumber.trim()
        )}&status=${status}&email=${encodeURIComponent(
          email.trim()
        )}&document_id=${docId.trim()}&document_type_id=${newDocTypeId}&starting_time=${encodeURIComponent(
          newStartingTime
        )}&ending_time=${encodeURIComponent(
          newEndingTime
        )}&order_by_desc=${1}&page=${newPageNO}`;
      }
      let kybRequests = await getDataWithToken(url);
      if (kybRequests.status === 200) {
        setKybRequestList(kybRequests.data.data.data);
        setTotalData(kybRequests.data.data.total);
        if (kybRequests.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(kybRequests.data.messages.toString());
        if (kybRequests.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };

  const getDropdownData = async () => {
    let allDocType = await getDataWithToken(`api/verification-doc-type-list`);
    if (allDocType.status === 200) {
      setDocList(allDocType.data.data);
    } else {
      setDocListMessage(allDocType.data.messages.toString());
      if (allDocType.data.messages.length < 1) {
        setDocListMessage("Something went wrong");
      }
    }
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));
  const showFilterButtons = () => {
    if (rollPermission?.includes("Download kyc report")) {
      return (
        <Grid item xs={4}>
          <Grid container spacing={{ md: 1, lg: 1, xl: 3 }}>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                fullWidth
                onClick={(event) => clearFilter(event, 0)}
              >
                <RestartAltIcon />
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                disableElevation
                // color="success"
                style={{ background: "#435185", padding: "6.7px 22px" }}
                size="large"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={(event) => handleChangePage(event, 0)}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <div>
                <Tooltip title="Download File" placement="top">
                  <Button
                    variant="contained"
                    color="success"
                    disableElevation
                    size="large"
                    id="basic-button"
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    disabled={downloadloading}
                    onClick={handleClick}
                    style={{ maxHeight: "40px" }}
                    fullWidth
                  >
                    {downloadloading === false && <DownloadIcon />}
                    <PulseLoader
                      color={"#00848A"}
                      loading={downloadloading}
                      size={5}
                      speedMultiplier={0.5}
                    />{" "}
                  </Button>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      downloadFile("xlsx");
                    }}
                  >
                    Export Excel File
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      downloadFile("csv");
                    }}
                  >
                    Export CSV File
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={4}>
          <Grid container spacing={{ md: 4, lg: 4, xl: 1 }}>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                fullWidth
                onClick={(event) => clearFilter(event, 0)}
              >
                <RestartAltIcon />
              </Button>
            </Grid>

            <Grid item xs={9}>
              <Button
                variant="contained"
                disableElevation
                // color="success"
                style={{ background: "#435185", padding: "6.7px 22px" }}
                size="large"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={(event) => handleChangePage(event, 0)}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };
  const downloadFile = async (type) => {
    setDownloadloading(true);
    try {
      let newStartingTime = "";
      let newEndingTime = "";
      let newDocTypeId = docTypeId;
      if (docTypeId === "None") {
        newDocTypeId = "";
      }
      if (startingTime !== null) {
        newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (endingTime !== null) {
        newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
      }

      let url = `api/kyc-agent-verified-info?mobile_number=${encodeURIComponent(
        mobileNumber.trim()
      )}&status=${status}&email=${encodeURIComponent(
        email.trim()
      )}&document_id=${docId.trim()}&document_type_id=${newDocTypeId}&starting_time=${encodeURIComponent(
        newStartingTime
      )}&ending_time=${encodeURIComponent(
        newEndingTime
      )}&order_by_desc=${1}&download=excel&download_type=${type}`;

      let Requests = await axios({
        url: url,
        method: "get",
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
        responseType: "blob", // important
      });

      if (Requests.status === 200) {
        const url = window.URL.createObjectURL(new Blob([Requests.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `KYC Agent Verified Requests.${type}`);

        document.body.appendChild(link);
        link.click();

        link.remove();

        handleClose();

        handleSnakbarOpen("File download successfully", "success");
      } else {
        handleSnakbarOpen("Something went wrong", "error");
        handleClose();
      }
      setDownloadloading(false);
    } catch (error) {
      const isJsonBlob = (data) =>
        data instanceof Blob && data.type === "application/json";
      const responseData = isJsonBlob(error.response?.data)
        ? await error.response?.data?.text()
        : error.response?.data || {};
      const responseJson =
        typeof responseData === "string"
          ? JSON.parse(responseData)
          : responseData;
      console.log("error", responseJson);
      handleSnakbarOpen(responseJson?.messages.toString(), "error");
      handleClose();
      setDownloadloading(false);
    }
    setDownloadloading(false);
  };
  useEffect(() => {
    getData();
    getDropdownData();
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={9} xl={9}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            KYC Agent Verified Requests
          </Typography>
        </Grid>
        <Grid item lg={3} xl={3} style={{ textAlign: "right" }}>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  id="mobile-no"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No."
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Doc Type
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Doc Type"
                    value={docTypeId}
                    onChange={(e) => setDocTypeId(e.target.value)}
                  >
                    <MenuItem value="None">
                      {docListMessage.length > 0 ? (
                        <>{docListMessage}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>
                    {docList?.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Doc ID"
                  value={docId}
                  onChange={(e) => setDocId(e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Starting Time"
                    value={startingTime}
                    onChange={(newValue) => {
                      setStartingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Ending Time"
                    value={endingTime}
                    onChange={(newValue) => {
                      setEndingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={8}></Grid>
              {showFilterButtons()}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell colSpan={2} style={{ minWidth: "200px" }}>
                  Basic Info
                </TableCell>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Level
                </TableCell>
                <TableCell style={{ minWidth: "120px" }}>Email</TableCell>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Doc Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "95px" }}>
                  Doc ID
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  Registration Date
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {" "}
                  Status
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {" "}
                  Verified By
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {" "}
                  Remarks
                </TableCell>
                {rollPermission?.includes("KYC request details") && (
                  <TableCell align="center" style={{ minWidth: "90px" }}>
                    {" "}
                    View Details
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                kybRequestList.length > 0 &&
                kybRequestList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.name}
                      </Typography>
                      {row.mobile_number}
                    </TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">{row.level}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="center">{row.document_type}</TableCell>
                    <TableCell align="center">{row.document_id}</TableCell>
                    <TableCell align="center">{row.uploaded_at}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {row.status === "Verified" ? (
                          <img
                            src="/image/verified.svg"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        ) : row.status === "Pending" ? (
                          <img
                            src="/image/unverified.svg"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        ) : row.status === "Awaiting Approval" ? (
                          <img
                            src="/image/unverified.svg"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        ) : row.status === "Declined" ? (
                          <HighlightOffIcon color="error" />
                        ) : (
                          ""
                        )}
                        &nbsp; {row.status}
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.verified_by}</TableCell>
                    <TableCell align="center">{row.reason}</TableCell>
                    {rollPermission?.includes("KYC request details") && (
                      <TableCell align="center">
                        <IconButton
                          component={Link}
                          to={`/kyc-details/${row.id}`}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}

              {!loading && kybRequestList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>

        {kybRequestList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              // count={rows.length}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </TableContainer>
  );
};

export default EKYCAgentVerifiedRequest;
