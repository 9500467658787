import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import StoreIcon from "@mui/icons-material/Store";
import { IconButton, InputAdornment } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import PulseLoader from "react-spinners/PulseLoader";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Switch from "@mui/material/Switch";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 6px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  titleBox: {
    background: "#eaecf3",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "center",
    padding: "5px",
    borderRadius: "8px",
    width: "50%",
    margin: "0px auto 20px",
  },
  imageStyle: {
    height: "90px",
    width: "90px",
    borderRadius: "8px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
}));

const POSList = ({ rollPermission }) => {
  const classes = useStyles();
  let { id } = useParams();
  const uploadImage = "/image/noimage2.jpg";
  const { fastpay_support_panel_user } = useContext(AuthContext);

  const [status, setStatus] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [storeName, setStoreName] = useState("");

  const [posDeviceId, setPosDeviceId] = useState("");
  const [terminal, setTerminal] = useState("");
  const [openAddStoreDialog, setOpenAddStoreDialog] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [iban, setIban] = useState("");

  const [addStoreLoading, setAddStoreLoading] = useState(false);
  const [formStoreName, setFormStoreName] = useState("");
  const [storeLogoImageFile, setstoreLogoImageFile] = useState(null);
  const [storeLogoPreview, setstoreLogoPreview] = useState(uploadImage);
  const [businessCategoryId, setBusinessCategoryId] = useState("");
  const [businessCategorylist, setBusinessCategorylist] = useState([]);
  const [businessCategoryeMessage, setBusinessCategoryeMessage] = useState([]);
  const [formPosDeviceId, setFormPosDeviceId] = useState("");
  const [formReason, setFormReason] = useState("");
  const [address, setAddress] = useState("");
  const [posId, setPosId] = useState("");

  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const [switchOpen, setSwitchOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [terminalName, setTerminalName] = useState("");
  const [terminalAccountNo, setTerminalAccountNo] = useState("");
  const [filterTerminalAccountNo, setFilterTerminalAccountNo] = useState("");
  const [filterIbanNo, setFilterIbanNo] = useState("");
  const [filterTerminalName, setFilterTerminalName] = useState("");
  const [isRefundable, setIsRefundable] = useState("");
  const [reason, setReason] = useState("");
  const handleSwitchClose = () => {
    setSwitchOpen(false);
    setReason("");
  };
  const updateStatus = async (e) => {
    e.preventDefault();

    try {
      setUpdateLoading(true);
      let newStatus;
      if (newUpdateValue.value) {
        newStatus = 1;
      } else {
        newStatus = 0;
      }

      let data = {
        store_configuration_id: newUpdateValue.id,
        status: newStatus,
        remarks: reason,
      };

      let response = await axios({
        url: `api/pos-store-status-update`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        getData();
        setSwitchOpen(false);
      }

      handleSnakbarOpen(response.data.messages.toString(), "success");
      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };
  const handleClickOpen = async (data) => {
    let url = `api/pos-store-show?id=${data.id}`;
    let storesData = await getDataWithToken(url);
    console.log("storesData", storesData);

    setPosId(storesData.data.data.id);
    setFormStoreName(
      storesData.data.data.store_name === null
        ? ""
        : storesData.data.data.store_name
    );
    setBusinessCategoryId(
      storesData.data.data.business_category_id === null
        ? ""
        : storesData.data.data.business_category_id
    );
    setFormPosDeviceId(
      storesData.data.data.pos_device_id === null
        ? ""
        : storesData.data.data.pos_device_id
    );
    setFormReason(
      storesData.data.data.remarks === null ? "" : storesData.data.data.remarks
    );
    setTerminalName(
      storesData.data.data.terminal_name === null
        ? ""
        : storesData.data.data.terminal_name
    );
    setTerminalAccountNo(
      storesData.data.data.terminal_acc_no === null
        ? ""
        : storesData.data.data.terminal_acc_no.includes("+964")
        ? storesData.data.data.terminal_acc_no.replace("+964", "")
        : storesData.data.data.terminal_acc_no
    );
    setClientId(
      storesData.data.data.client_id === null
        ? ""
        : storesData.data.data.client_id
    );
    setClientSecret(
      storesData.data.data.client_secret === null
        ? ""
        : storesData.data.data.client_secret
    );
    setAddress(
      storesData.data.data.address === null ? "" : storesData.data.data.address
    );
    setIsRefundable(
      storesData.data.data.is_refund_enabled === null
        ? ""
        : storesData.data.data.is_refund_enabled
    );
    setIban(
      storesData.data.data.iban === null ? "" : storesData.data.data.iban
    );
    // setStoreName(
    //   storesData.data.data.store_name === null
    //     ? ""
    //     : storesData.data.data.store_name
    // );
    setstoreLogoPreview(storesData.data.data.store_logo);
    setOpenAddStoreDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddStoreDialog(false);
      clearForm();
    }
  };
  const imageProcess = (e, title) => {
    console.log("imageProcess title", title);
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setstoreLogoImageFile(imageFile);
        setstoreLogoPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setstoreLogoImageFile(null);
    }
  };
  const clearForm = () => {
    setPosId("");
    setStoreName("");
    setBusinessCategoryId("");
    setPosDeviceId("");
    setTerminalName("");
    setTerminalAccountNo("");
    setClientId("");
    setClientSecret("");
    setAddress("");
    setIsRefundable("");
    setstoreLogoImageFile(null);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setStoreName("");
    setPosDeviceId("");
    setTerminal("");
    setStatus("");
    setFilterIbanNo("");
    setFilterTerminalName("");
    setFilterTerminalAccountNo("");
    setPage(0);
    let newUrl = `api/pos-store-list?user_id=${id}&page=1`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newStatus = status;

        if (status === "None") {
          newStatus = "";
        }
        let newTerminalAccountNo = "";

        if (filterTerminalAccountNo.trim().length > 0) {
          newTerminalAccountNo = `+964${filterTerminalAccountNo.trim()}`;
        }
        url = `api/pos-store-list?user_id=${id}&store_name=${storeName.trim()}&terminal_account_no=${encodeURIComponent(
          newTerminalAccountNo
        )}&terminal_iban_number=${filterIbanNo.trim()}&pos_device_id=${encodeURIComponent(
          posDeviceId.trim()
        )}&terminal=${terminal}&status=${newStatus}&page=${newPageNO}`;
      }
      let stores = await getDataWithToken(url);
      console.log("stores", stores);
      if (stores.status === 200) {
        setStoreList(stores.data.data.data);
        setTotalData(stores.data.data.total);
        setRowsPerPage(stores.data.data.per_page);
        if (stores.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(stores.data.messages.toString());
        if (stores.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setMessage(error.response.data.message.toString());
    }
    setLoading(false);
  };
  const validation = () => {
    let isError = false;
    // if (storeLogoImageFile === null) {
    //   handleSnakbarOpen("Please upload your logo", "error");
    //   return (isError = true);
    // }
    if (!formStoreName.trim()) {
      handleSnakbarOpen("Please enter store name", "error");
      document.getElementById("formStoreName").focus();
      return (isError = true);
    }
    if (!businessCategoryId) {
      handleSnakbarOpen("Please select a business category", "error");
      document.getElementById("businessCategoryId").focus();
      return (isError = true);
    }

    if (!formPosDeviceId.trim()) {
      handleSnakbarOpen("Please enter POS device id", "error");
      document.getElementById("formPosDeviceId").focus();
      return (isError = true);
    }
    if (!terminalAccountNo.trim()) {
      handleSnakbarOpen("Please enter terminal account no", "error");
      document.getElementById("terminalAccountNo").focus();
      return (isError = true);
    }
    if (!clientId) {
      handleSnakbarOpen("Please enter  client id", "error");
      document.getElementById("clientId").focus();
      return (isError = true);
    }
    if (!clientSecret) {
      handleSnakbarOpen("Please enter  client secret", "error");
      document.getElementById("clientSecret").focus();
      return (isError = true);
    }
    if (!iban) {
      handleSnakbarOpen("Please enter iban", "error");
      document.getElementById("iban").focus();
      return (isError = true);
    }
    if (!address.trim()) {
      handleSnakbarOpen("Please enter address", "error");
      document.getElementById("address").focus();
      return (isError = true);
    }

    return isError;
  };
  const onSubmit = async (e) => {
    console.log("onSubmit");
    e.preventDefault();

    let err = validation();

    if (err) {
      return;
    } else {
      setAddStoreLoading(true);
      try {
        let formData = new FormData();
        formData.append("id", posId);
        formData.append("store_name", formStoreName);
        formData.append("business_category_id", businessCategoryId);
        formData.append("fib_client_id", clientId);
        formData.append("fib_client_secret", clientSecret);
        formData.append("iban", iban);
        formData.append("pos_device_id", formPosDeviceId);
        // formData.append("terminal_name", terminalName);
        formData.append("store_id", `+964${terminalAccountNo}`);
        formData.append("address", address);
        formData.append("is_refund_enabled", isRefundable);
        formData.append("remarks", formReason);
        if (storeLogoImageFile !== null) {
          formData.append("store_logo", storeLogoImageFile);
        }
        let response = await axios({
          url: "api/pos-store-update",
          method: "post",
          data: formData,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        if (response.data.code === 200) {
          handleSnakbarOpen(response.data.messages.toString(), "success");
          handleClose();
          clearForm();
          getData();
        }
      } catch (error) {
        console.log("error", error);
        setAddStoreLoading(false);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        if (error.response.data.messages.length < 1) {
          handleSnakbarOpen("Something went wrong", "error");
        }
      }
      setAddStoreLoading(false);
    }
  };
  const getBusinessCategoryList = async () => {
    let businessCategory = await getDataWithToken(`api/business/categories`);

    if (businessCategory.status === 200) {
      setBusinessCategorylist(businessCategory.data.data);
    } else {
      setBusinessCategoryeMessage(businessCategory.data.messages.toString());
      if (businessCategory.data.messages.length < 1) {
        setBusinessCategoryeMessage("Something went wrong");
      }
    }
  };
  useEffect(async () => {
    getBusinessCategoryList();
    getData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={6} xl={6}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              POS List
            </Typography>
          </Grid>
          <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              color="success"
              // startIcon={<FilterListIcon />}
              onClick={() => setOpen(!open)}
            >
              {open ? <FilterListOffIcon /> : <FilterListIcon />}
            </Button>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    id="storeName"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Terminal Name"
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <TextField
                    id="filterTerminalName"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Terminal Name"
                    value={filterTerminalName}
                    onChange={(e) => setFilterTerminalName(e.target.value)}
                  />
                </Grid> */}
                <Grid item xs={3}>
                  <TextField
                    id="mobile-no"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Terminal Account Number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">(+964)</InputAdornment>
                      ),
                    }}
                    value={filterTerminalAccountNo}
                    onChange={(e) => setFilterTerminalAccountNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="mobile-no"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Terminal IBAN Number"
                    value={filterIbanNo}
                    onChange={(e) => setFilterIbanNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="posDeviceId"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="POS Device Id"
                    value={posDeviceId}
                    onChange={(e) => setPosDeviceId(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-is-assigned-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-is-assigned-outlined-label"
                      id="demo-is-assigned-outlined"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={9}></Grid> */}
                <Grid item xs={3}>
                  <Grid container spacing={{ lg: 6, xl: 3 }}>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={(event) => clearFilter(event, 0)}
                      >
                        <RestartAltIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        disableElevation
                        // color="success"
                        style={{ background: "#435185", padding: "6.7px 22px" }}
                        size="large"
                        startIcon={<SearchIcon />}
                        fullWidth
                        onClick={(event) => handleChangePage(event, 0)}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>

        <Paper>
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 370px)",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell>Logo</TableCell>
                  <TableCell style={{ minWidth: "200px" }}>
                    Terminal Name
                  </TableCell>
                  {/* <TableCell style={{ minWidth: "160px" }}>
                    Business category
                  </TableCell> */}
                  <TableCell style={{ minWidth: "120px" }}>
                    POS Device Id
                  </TableCell>
                  <TableCell style={{ minWidth: "120px" }}>IBAN No</TableCell>
                  <TableCell style={{ minWidth: "120px" }}>Cliend Id</TableCell>
                  <TableCell style={{ minWidth: "280px" }}>
                    Client Secret
                  </TableCell>
                  {/* <TableCell style={{ minWidth: "160px" }}>
                    Terminal Name
                  </TableCell> */}
                  <TableCell style={{ minWidth: "160px" }}>
                    Terminal Account No
                  </TableCell>
                  {/* <TableCell style={{ minWidth: "70px" }}>Terminal</TableCell> */}
                  <TableCell style={{ minWidth: "120px" }}>Address</TableCell>

                  <TableCell style={{ minWidth: "120px" }} align="center">
                    {" "}
                    Is Refundable
                  </TableCell>
                  <TableCell> Remarks</TableCell>
                  <TableCell style={{ minWidth: "120px" }}> Status</TableCell>
                  <TableCell style={{ minWidth: "120px" }} align="center">
                    {" "}
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  storeList.length > 0 &&
                  storeList.map((row, i) => (
                    <TableRow
                      key={i}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ width: "50px" }}>
                        <Avatar
                          alt=""
                          src={row.store_logo}
                          sx={{ width: 45, height: 45 }}
                        />
                      </TableCell>

                      <TableCell>{row.store_name}</TableCell>
                      {/* <TableCell>{row.business_category_id}</TableCell> */}
                      <TableCell>{row.pos_device_id}</TableCell>
                      <TableCell>{row.iban}</TableCell>
                      <TableCell>{row.client_id}</TableCell>
                      <TableCell>{row.client_secret}</TableCell>
                      {/* <TableCell>{row.terminal_name}</TableCell> */}
                      <TableCell>{row.terminal_acc_no}</TableCell>
                      {/* <TableCell>{row.terminal}</TableCell> */}
                      <TableCell>{row.address}</TableCell>
                      <TableCell align="center">
                        <img
                          src={
                            row.is_refund_enabled === 1
                              ? "/image/verified.svg"
                              : "/image/unverified.svg"
                          }
                          alt=""
                          height="20px"
                          style={{ position: "relative", top: 5 }}
                        />{" "}
                        {row.is_refund_enabled ? (
                          <span style={{ color: "rgba(129,199,132,1)" }}>
                            Active &nbsp;&nbsp;
                          </span>
                        ) : (
                          <span style={{ color: "rgba(211, 47, 47,1)" }}>
                            Inactive
                          </span>
                        )}
                      </TableCell>
                      <TableCell sx={{ maxWidth: "420px" }}>
                        {row.remarks ? row.remarks : "-------"}
                      </TableCell>
                      <TableCell>
                        {/* <img
                          src={
                            row.status
                              ? "/image/verified.svg"
                              : "/image/unverified.svg"
                          }
                          style={{
                            width: "20px",
                            position: "relative",
                            top: 5,
                          }}
                          alt=""
                        />{" "}
                        &nbsp;
                        {row.status ? "Active" : "Inactive"} */}
                        <Switch
                          color="success"
                          checked={row.status ? true : false}
                          onChange={(e) => {
                            setNewUpdateValue({
                              index: i,
                              id: row.id,
                              value: e.target.checked,
                            });
                            setSelectedForUpdate(row);

                            setSwitchOpen(true);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleClickOpen(row)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                {!loading && storeList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
          {storeList.length > 0 && (
            <div>
              <TablePagination
                style={{ display: "block", borderBottom: "none" }}
                rowsPerPageOptions={[]}
                // count={rows.length}
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </TableContainer>
      <Dialog
        open={openAddStoreDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ position: "relative" }}>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 20, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
          <div
            style={{
              margin: "30px auto 20px",
            }}
            className={classes.titleBox}
          >
            Update POS
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    storeLogoImageFile === null ? uploadImage : storeLogoPreview
                  }
                  alt=""
                  className={classes.imageStyle}
                  onClick={() =>
                    document.getElementById("upload-logo-file").click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    document.getElementById("upload-logo-file").click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload Logo
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="upload-logo-file"
                type="file"
                onChange={(e) => imageProcess(e, "logo")}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="formStoreName"
                label="Terminal Name"
                fullWidth
                size="small"
                variant="outlined"
                value={formStoreName}
                onChange={(e) => setFormStoreName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="businessCategoryId">
                  Business Category
                </InputLabel>
                <Select
                  labelId="demo-status-outlined-label"
                  id="businessCategoryId"
                  label="Business Category"
                  value={businessCategoryId}
                  onChange={(e) => setBusinessCategoryId(e.target.value)}
                >
                  <MenuItem value="None">
                    {businessCategoryeMessage.length > 0 ? (
                      <>{businessCategoryeMessage}</>
                    ) : (
                      "None"
                    )}
                  </MenuItem>
                  {businessCategorylist?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="formPosDeviceId"
                label="POS Device Id"
                fullWidth
                size="small"
                variant="outlined"
                value={formPosDeviceId}
                onChange={(e) => setFormPosDeviceId(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                id="terminalName"
                label="Terminal Name"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                value={terminalName}
                onChange={(e) => setTerminalName(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                id="terminalAccountNo"
                label="Terminal Account No"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">(+964)</InputAdornment>
                  ),
                }}
                value={terminalAccountNo}
                onChange={(e) => setTerminalAccountNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="clientId"
                label="Client Id"
                fullWidth
                size="small"
                variant="outlined"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="clientSecret"
                label="Client Secret"
                fullWidth
                size="small"
                variant="outlined"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="iban"
                label="IBAN"
                fullWidth
                size="small"
                variant="outlined"
                value={iban}
                onChange={(e) => setIban(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                label="Address"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="demo-status-outlined-label">
                  Is Refundable
                </InputLabel>
                <Select
                  labelId="demo-status-outlined-label"
                  id="demo-status-outlined"
                  label="KYC Status"
                  value={isRefundable}
                  onChange={(e) => setIsRefundable(e.target.value)}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Inactive </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Remarks"
                placeholder="Type the reason"
                multiline
                rows={3}
                fullWidth
                value={formReason}
                onChange={(e) => setFormReason(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                color="primary"
                style={{ marginTop: "20px" }}
                disabled={addStoreLoading}
                onClick={onSubmit}
              >
                {addStoreLoading === false && "Update Store"}
                <PulseLoader
                  color={"#00848A"}
                  loading={addStoreLoading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={switchOpen}
        onClose={handleSwitchClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to change status
            </DialogContentText>
            <TextField
              id="outlined-multiline-static"
              placeholder="Type the reason"
              multiline
              rows={4}
              fullWidth
              sx={{ mt: 1 }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSwitchClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateStatus}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default POSList;
