import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../../context/AuthContext";
import TableHead from "@mui/material/TableHead";
import axios from "axios";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableHeadStyle: {
    // background: "#00848A",
    "& th": {
      // color: "#fff",
      fontSize: "16px",
      fontWeight: "600 !important",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  tableBodyStyle: {
    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
}));

const EKYCDocument = ({
  basicInformation,
  KYCInfo,
  loading,
  level,
  levelId,
  getDataAfterStatusUpdate,
  userInfoMessage,
  rollPermission,
}) => {
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [imageDialog, setImageDialog] = useState(false);
  const [imgSrc, setImgSrc] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [statusDialog, setStatusDialog] = useState(false);
  const [temporaryLevelId, setTemporaryLevelId] = useState(levelId);
  const [reason, setReason] = useState("");
  const [statusLoading, setStatusLoading] = useState(false);
  const [delinedLoading, setDelinedLoading] = useState(false);
  const [agentDeclinedDialog, setAgentDeclinedDialog] = useState(false);
  const [agentDeclinedReason, setAgentDeclinedReason] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const statusChange = (value) => {
    setStatusValue(value);
    setStatusDialog(true);
  };
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const openImageDialog = (src) => {
    setImgSrc(src);
    setImageDialog(true);
  };
  const handleClose = () => {
    setImageDialog(false);
    setImgSrc([]);
  };
  const handleStatusClose = () => {
    setStatusDialog(false);
  };
  const handleDelinedDialogClose = () => {
    setAgentDeclinedDialog(false);
  };

  const onStatusSubmit = async () => {
    setStatusLoading(true);
    try {
      let data = {
        user_id: basicInformation.user_id,
        kyc_id: KYCInfo.kyc_id,
        kyc_status: statusValue,
        level: temporaryLevelId,
        reason: reason,
      };
      let response = await axios({
        url: "api/kyc-update",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      if (response.data.code === 200) {
        handleSnakbarOpen(response.data.messages.toString(), "success");
        getDataAfterStatusUpdate();
        setStatusDialog(false);
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setStatusLoading(false);
    }
    setStatusLoading(false);
  };
  const handleDelinedSubmit = async () => {
    console.log(" kyc_id: KYCInfo.kyc_id,", KYCInfo.kyc_id);
    setDelinedLoading(true);
    try {
      let data = {
        user_id: basicInformation.user_id,
        kyc_id: KYCInfo.kyc_id,
        reason: agentDeclinedReason,
      };
      let response = await axios({
        url: "api/physical-kyc/decline",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });
      if (response.data.code === 200) {
        handleSnakbarOpen(response.data.messages.toString(), "success");
        getDataAfterStatusUpdate();
        setAgentDeclinedDialog(false);
      }
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setDelinedLoading(false);
    }
    setDelinedLoading(false);
  };
  const showButtonRow = () => {
    if (
      KYCInfo?.status_update?.can_approve === 1 ||
      KYCInfo?.status_update?.can_decline === 1 ||
      KYCInfo?.status_update?.can_decline_physical_kyc === 1
    ) {
      return (
        <div style={{ textAlign: "right", marginBottom: "20px" }}>
          {/* <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell>Action</TableCell>
            <TableCell> */}

          {KYCInfo?.status_update?.can_approve === 1 && (
            <>
              <Button
                className={classes.approveStatus}
                variant="contained"
                color="success"
                disableElevation
                onClick={() => statusChange("1")}
              >
                Approve
              </Button>
              &nbsp; &nbsp;
            </>
          )}
          {KYCInfo?.status_update?.can_decline === 1 && (
            <Button
              className={classes.pendingStatus}
              variant="contained"
              color="error"
              disableElevation
              onClick={() => statusChange("9")}
            >
              Declined
            </Button>
          )}

          {KYCInfo?.status_update?.can_decline_physical_kyc === 1 && (
            <>
              {rollPermission?.includes("Decline physical kyc") && (
                <>
                  &nbsp; &nbsp;{" "}
                  <Button
                    className={classes.pendingStatus}
                    variant="contained"
                    color="error"
                    disableElevation
                    onClick={() => setAgentDeclinedDialog(true)}
                  >
                    Decline Physical KYC
                  </Button>
                </>
              )}
            </>
          )}

          {/* </TableCell>
          </TableRow> */}
        </div>
      );
    } else {
      return;
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  useEffect(() => {}, []);

  return (
    <>
      {userInfoMessage.length > 0 && (
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 600, textAlign: "center" }}
          component="div"
        >
          {userInfoMessage}
        </Typography>
      )}
      {KYCInfo === null ? (
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 600, textAlign: "center" }}
          gutterBottom
          component="div"
        >
          No Data Found
        </Typography>
      ) : (
        <>
          {showButtonRow()}

          <Table
            aria-label="simple table"
            style={{ border: "1px solid #dcdde1" }}
          >
            {!loading && KYCInfo && Object.entries(KYCInfo).length !== 0 && (
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell style={{ minWidth: "120px" }}>Title</TableCell>
                  <TableCell style={{ minWidth: "120px", width: "350px" }}>
                    Personal Upload
                  </TableCell>
                  <TableCell style={{ minWidth: "120px" }}>
                    Agent Upload
                  </TableCell>
                  <TableCell style={{ minWidth: "120px" }}>Others</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody className={classes.tableBodyStyle}>
              {loading && pageLoading()}

              {!loading && KYCInfo && Object.entries(KYCInfo).length !== 0 && (
                <>
                  {KYCInfo?.kyc_data?.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{item.title}</TableCell>
                      <TableCell>
                        {item?.personal?.length < 1 ? (
                          "N/A"
                        ) : (
                          <>
                            {item?.personal?.value.length < 1
                              ? "N/A"
                              : item?.personal?.value}{" "}
                            {item?.personal?.images?.length > 0 && (
                              <LightTooltip title="Preview" placement="top">
                                <IconButton
                                  onClick={() =>
                                    openImageDialog(item?.personal?.images)
                                  }
                                >
                                  <VisibilityOutlinedIcon color="info" />
                                </IconButton>
                              </LightTooltip>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {item?.agent?.length < 1 ? (
                          "N/A"
                        ) : (
                          <>
                            {item?.agent?.value.length < 1
                              ? "N/A"
                              : item?.agent?.value}{" "}
                            {item?.agent?.images?.length > 0 && (
                              <LightTooltip title="Preview" placement="top">
                                <IconButton
                                  onClick={() =>
                                    openImageDialog(item?.agent?.images)
                                  }
                                >
                                  <VisibilityOutlinedIcon color="info" />
                                </IconButton>
                              </LightTooltip>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {item?.others?.length < 1
                          ? "N/A"
                          : item?.others?.map((el, i) => (
                              <>
                                {el.value.length < 1 ? "N/A" : el.value}{" "}
                                {el?.images?.length > 0 && (
                                  <LightTooltip title="Preview" placement="top">
                                    <IconButton
                                      onClick={() =>
                                        openImageDialog(el?.images)
                                      }
                                    >
                                      <VisibilityOutlinedIcon color="info" />
                                    </IconButton>
                                  </LightTooltip>
                                )}
                                {item?.others?.length - 1 !== i && (
                                  <>
                                    <br />
                                    {/* <br /> */}
                                  </>
                                )}
                              </>
                            ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </>
      )}
      <Dialog
        open={imageDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 20 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div style={{ display: "flex" }}>
            {imgSrc?.map((item, i) => (
              <div key={i}>
                <h4 style={{ marginTop: 0, padding: "0px 10px" }}>
                  Image {i + 1}
                </h4>

                <img
                  src={item}
                  alt=""
                  style={{
                    width: "350px",
                    maxHeight: "300px",
                    padding: "0px 10px",
                  }}
                />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={statusDialog}
        onClose={handleStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent style={{ paddingBottom: 0 }}>
            <DialogContentText id="alert-dialog-description">
              You want to update user status
            </DialogContentText>
            {statusValue === "9" ? (
              ""
            ) : (
              <>
                {rollPermission?.includes("Customer level update") && (
                  <>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.centerSelectStyle}
                      fullWidth
                    >
                      <Select
                        labelId="demo-store-category-outlined-label"
                        // id="demo-store-category-outlined"
                        value={temporaryLevelId}
                        onChange={(e) => {
                          setTemporaryLevelId(e.target.value);
                        }}
                      >
                        {level?.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}
              </>
            )}
            <TextField
              id="outlined-multiline-static"
              placeholder="Type the reason"
              multiline
              rows={4}
              fullWidth
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions style={{ paddingRight: "24px" }}>
            <Button onClick={handleStatusClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={statusLoading}
              onClick={onStatusSubmit}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={statusLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {statusLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={agentDeclinedDialog}
        onClose={handleDelinedDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px", minWidth: "350px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent style={{ paddingBottom: 0 }}>
            <DialogContentText id="alert-dialog-description">
              You want to declined the user
            </DialogContentText>

            <TextField
              id="outlined-multiline-static"
              placeholder="Type the reason"
              multiline
              rows={4}
              fullWidth
              value={agentDeclinedReason}
              onChange={(e) => setAgentDeclinedReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions style={{ paddingRight: "24px" }}>
            <Button onClick={handleDelinedDialogClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={delinedLoading}
              onClick={handleDelinedSubmit}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={delinedLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {delinedLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default EKYCDocument;
