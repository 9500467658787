import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthContext } from "../../context/AuthContext";
import { getDataWithToken } from "../../services/GetDataService";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "50px",
    background: "#fff",
    borderRadius: "10px",
    // textAlign: "center",
    width: "900px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  titleBox: {
    background: "#eaecf3",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
    padding: "5px",
    borderRadius: "8px",
    width: "50%",
    margin: "0px auto 20px",
  },
  imageStyle: {
    height: "90px",
    width: "90px",
    borderRadius: "8px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
}));

const CreateMerchant = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const uploadImage = "/image/noimage2.jpg";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const [businessName, setBusinessName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessMobileNo, setBusinessMobileNo] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [tradeLicenseNo, setTradeLicenseNo] = useState("");
  const [docList, setDocList] = useState([]);
  const [docTypeId, setDocTypeId] = useState("");
  const [docTypeMessage, setDocTypeMessage] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryMessage, setCountryMessage] = useState("");
  const [logoImageFile, setLogoImageFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(uploadImage);
  const [tradeLicenceImageFile, setTradeLicenceImageFile] = useState(null);
  const [tradeLicencePreview, setTradeLicencePreview] = useState(uploadImage);
  const [documentFrontSideImageFile, setDocumentFrontSideImageFile] =
    useState(null);
  const [documentFrontSidePreview, setDocumentFrontSidePreview] =
    useState(uploadImage);
  const [documentBackSideImageFile, setDocumentBackSideImageFile] =
    useState(null);
  const [documentBackSidePreview, setDocumentBackSidePreview] =
    useState(uploadImage);
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const validation = () => {
    let isError = false;
    if (!firstName.trim()) {
      handleSnakbarOpen("Please enter first name", "error");
      document.getElementById("firstName").focus();
      return (isError = true);
    }
    if (!lastName.trim()) {
      handleSnakbarOpen("Please enter last name", "error");
      document.getElementById("lastName").focus();
      return (isError = true);
    }
    // if (!email.trim()) {
    //   handleSnakbarOpen("Please enter email address", "error");
    //   document.getElementById("email").focus();
    //   return (isError = true);
    // } else if (
    //   !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    //     email
    //   )
    // ) {
    //   handleSnakbarOpen("Invalid email address", "error");
    //   document.getElementById("email").focus();
    //   return (isError = true);
    // }
    if (!mobileNo.trim()) {
      handleSnakbarOpen("Please enter mobile number", "error");
      document.getElementById("mobileNo").focus();
      return (isError = true);
    }

    // if (logoImageFile === null) {
    //   handleSnakbarOpen("Please upload your business logo", "error");
    //   return (isError = true);
    // }
    if (!businessName.trim()) {
      handleSnakbarOpen("Please enter business name", "error");
      document.getElementById("businessName").focus();
      return (isError = true);
    }
    // if (!businessEmail.trim()) {
    //   handleSnakbarOpen("Please enter business email address", "error");
    //   document.getElementById("businessEmail").focus();
    //   return (isError = true);
    // } else if (
    //   !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    //     businessEmail
    //   )
    // ) {
    //   handleSnakbarOpen("Invalid business email address", "error");
    //   document.getElementById("businessEmail").focus();
    //   return (isError = true);
    // }
    // if (!businessMobileNo.trim()) {
    //   handleSnakbarOpen("Please enter business mobile number", "error");
    //   document.getElementById("businessMobileNo").focus();
    //   return (isError = true);
    // }
    // if (!docTypeId) {
    //   handleSnakbarOpen("Please select a document type", "error");
    //   document.getElementById("docTypeId").focus();
    //   return (isError = true);
    // }
    // if (!documentNo.trim()) {
    //   handleSnakbarOpen("Please enter document number", "error");
    //   document.getElementById("documentNo").focus();
    //   return (isError = true);
    // }
    // if (!tradeLicenseNo.trim()) {
    //   handleSnakbarOpen("Please enter trade license number", "error");
    //   document.getElementById("tradeLicenseNo").focus();
    //   return (isError = true);
    // }
    // if (!countryId) {
    //   handleSnakbarOpen("Please select a country", "error");
    //   document.getElementById("countryId").focus();
    //   return (isError = true);
    // }
    // if (tradeLicenceImageFile === null) {
    //   handleSnakbarOpen("Please upload your trade licence photo", "error");
    //   return (isError = true);
    // }
    // if (documentFrontSideImageFile === null) {
    //   handleSnakbarOpen(
    //     "Please upload your document front side photo",
    //     "error"
    //   );
    //   return (isError = true);
    // }
    return isError;
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let err = validation();

    if (err) {
      return;
    } else {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("mobile_no", `+964${mobileNo}`);

        formData.append("document_type_id", docTypeId);
        formData.append("document_number", documentNo);
        formData.append("country_id", countryId);
        formData.append("business_name", businessName);
        formData.append("business_email", businessEmail);
        if (businessMobileNo) {
          formData.append("business_mobile", `+964${businessMobileNo}`);
        } 
        // else {
        //   formData.append("business_mobile", null);
        // }
        formData.append("trade_license_number", tradeLicenseNo);
        {
          logoImageFile !== null &&
            formData.append("business_logo", logoImageFile);
        }
        {
          tradeLicenceImageFile !== null &&
            formData.append("trade_license_copy", tradeLicenceImageFile);
        }
        {
          documentFrontSideImageFile !== null &&
            formData.append("document_copy", documentFrontSideImageFile);
        }
        {
          documentBackSideImageFile !== null &&
            formData.append("document_copy_2", documentBackSideImageFile);
        }

        // formData.append("first_name", "Nayon");
        // formData.append("last_name", "hasan");
        // formData.append("email", "nayon45214@gmail.com");
        // formData.append("mobile_no", "+9647511424521");
        // formData.append("fib_client_id", "123658");
        // formData.append("fib_client_secret", "852369");

        let response = await axios({
          url: "api/pos-merchant-onboarding",
          method: "post",
          data: formData,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        if (response.data.code === 200) {
          handleSnakbarOpen(response.data.messages.toString(), "success");
          // setFirstName("");
          // setLastName("");
          // setEmail("");
          // setCountryId("");
          navigate("/merchant-list");
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        if (error.response.data.messages.length < 1) {
          handleSnakbarOpen("Something went wrong", "error");
        }
      }
      setLoading(false);
    }
  };
  const getCountryList = async () => {
    let countries = await getDataWithToken(`api/country-list`);
    console.log("countries", countries);
    if (countries.status === 200) {
      setCountryList(countries.data.data.countries);
    } else {
      setCountryMessage(countries.data.messages.toString());
      if (countries.data.messages.length < 1) {
        setCountryMessage("Something went wrong");
      }
    }
  };
  const getDocType = async () => {
    let allDocType = await getDataWithToken(`api/verification-doc-type-list`);
    if (allDocType.status === 200) {
      setDocList(allDocType.data.data);
    } else {
      setDocTypeMessage(allDocType.data.messages.toString());
      if (allDocType.data.messages.length < 1) {
        setDocTypeMessage("Something went wrong");
      }
    }
  };
  const imageProcess = (e, title) => {
    console.log("imageProcess title", title);
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        if (title === "logo") {
          setLogoImageFile(imageFile);
          setLogoPreview(x.target.result);
        }
        if (title === "trade license") {
          setTradeLicenceImageFile(imageFile);
          setTradeLicencePreview(x.target.result);
        }
        if (title === "document front side") {
          setDocumentFrontSideImageFile(imageFile);
          setDocumentFrontSidePreview(x.target.result);
        }
        if (title === "document back side") {
          setDocumentBackSideImageFile(imageFile);
          setDocumentBackSidePreview(x.target.result);
        }
      };
      reader.readAsDataURL(imageFile);
    } else {
      console.log("imageProcess else");

      if (title === "logo") {
        setLogoImageFile(null);
      }
      if (title === "trade license") {
        setTradeLicenceImageFile(null);
      }
      if (title === "document front side") {
        setDocumentFrontSideImageFile(null);
      }
      if (title === "document back side") {
        setDocumentBackSideImageFile(null);
      }
    }
  };
  useEffect(() => {
    getDocType();
    getCountryList();
  }, []);
  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "80vh" }}
      >
        <div className={classes.form}>
          <Typography
            variant="h5"
            component="div"
            style={{ marginBottom: "20px", textAlign: "center" }}
          >
            Create Merchant
          </Typography>
          <div className={classes.titleBox}>Personal Info</div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="firstName"
                label="First Name *"
                fullWidth
                size="small"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                label="Last Name *"
                fullWidth
                size="small"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Mobile No *"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                id="mobileNo"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">(+964)</InputAdornment>
                  ),
                }}
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
              />
            </Grid>
          </Grid>
          {/* <div
            style={{
              margin: "30px auto 20px",
            }}
            className={classes.titleBox}
          >
            Client Info
          </div> */}
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="clientId"
                label="Client Id"
                fullWidth
                size="small"
                variant="outlined"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="clientSecret"
                label="Client Secret"
                fullWidth
                size="small"
                variant="outlined"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
              />
            </Grid>
          </Grid> */}

          <div
            style={{
              margin: "30px auto 20px",
            }}
            className={classes.titleBox}
          >
            Business Info
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={logoImageFile === null ? uploadImage : logoPreview}
                  alt=""
                  className={classes.imageStyle}
                  onClick={() =>
                    document.getElementById("upload-logo-file").click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    document.getElementById("upload-logo-file").click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload Logo
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="upload-logo-file"
                type="file"
                onChange={(e) => imageProcess(e, "logo")}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                id="businessName"
                label="Business Name *"
                fullWidth
                size="small"
                variant="outlined"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Business Email"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                id="businessEmail"
                value={businessEmail}
                onChange={(e) => setBusinessEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Business Mobile No"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                id="businessMobileNo"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">(+964)</InputAdornment>
                  ),
                }}
                value={businessMobileNo}
                onChange={(e) => setBusinessMobileNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="docTypeId">Doc Type</InputLabel>
                <Select
                  labelId="demo-status-outlined-label"
                  id="docTypeId"
                  label="Doc Type"
                  value={docTypeId}
                  onChange={(e) => setDocTypeId(e.target.value)}
                >
                  <MenuItem value="None">
                    {docTypeMessage.length > 0 ? <>{docTypeMessage}</> : "None"}
                  </MenuItem>
                  {docList?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Document No"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                id="documentNo"
                value={documentNo}
                onChange={(e) => setDocumentNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Trade License No"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                id="tradeLicenseNo"
                value={tradeLicenseNo}
                onChange={(e) => setTradeLicenseNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="demo-issue-outlined-label">
                  Select Country
                </InputLabel>
                <Select
                  labelId="demo-issue-outlined-label"
                  id="countryId"
                  label="Select Country"
                  value={countryId}
                  onChange={(e) => setCountryId(e.target.value)}
                >
                  {countryMessage.length > 0 && (
                    <MenuItem value={countryMessage}>{countryMessage}</MenuItem>
                  )}
                  {countryList?.map((item, i) => (
                    <MenuItem key={i} value={item.country_id}>
                      {item.country_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    tradeLicenceImageFile === null
                      ? uploadImage
                      : tradeLicencePreview
                  }
                  alt=""
                  className={classes.imageStyle}
                  onClick={() =>
                    document.getElementById("upload-trade-license-file").click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    document.getElementById("upload-trade-license-file").click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload Trade License
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="upload-trade-license-file"
                type="file"
                onChange={(e) => imageProcess(e, "trade license")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    documentFrontSideImageFile === null
                      ? uploadImage
                      : documentFrontSidePreview
                  }
                  alt=""
                  className={classes.imageStyle}
                  onClick={() =>
                    document
                      .getElementById("upload-document-front-side-file")
                      .click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    document
                      .getElementById("upload-document-front-side-file")
                      .click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload Document Front Side
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="upload-document-front-side-file"
                type="file"
                onChange={(e) => imageProcess(e, "document front side")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    documentBackSideImageFile === null
                      ? uploadImage
                      : documentBackSidePreview
                  }
                  alt=""
                  className={classes.imageStyle}
                  onClick={() =>
                    document
                      .getElementById("upload-document-back-side-file")
                      .click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    document
                      .getElementById("upload-document-back-side-file")
                      .click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload Document Back Side
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="upload-document-back-side-file"
                type="file"
                onChange={(e) => imageProcess(e, "document back side")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                color="primary"
                style={{ marginTop: "20px" }}
                disabled={loading}
                onClick={onSubmit}
              >
                {loading === false && "Create Murchant"}
                <PulseLoader
                  color={"#00848A"}
                  loading={loading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default CreateMerchant;
