import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  main: {
    background: "#fff",
    borderRadius: "5px",
    textAlign: "center",

    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  form: {
    width: "70%",
  },
  selectStyle: {
    // background: "red",
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },

    [`& fieldset`]: {
      borderTop: "none",
      borderLeft: "none",
      borderBottom: "none",
      // background: "red",
    },
  },
}));
const Transactions = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const validation = () => {
    let isError = false;

    if (!searchText.trim()) {
      handleSnakbarOpen("Please enter mobile number or email address", "error");
      document.getElementById("searchText").focus();
      return (isError = true);
    }

    return isError;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let err = validation();

    if (err) {
      return;
    } else {
      setLoading(true);

      let text = searchText.trim();

      try {
        navigate({
          pathname: `/transactions/${text}`,
        });
      } catch (error) {
        console.log("error", error);

        setLoading(false);
      }
      setLoading(false);
    }
  };
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "calc(100vh - 120px)" }}
      className={classes.main}
    >
      <form onSubmit={onSubmit} className={classes.form}>
        <Typography
          variant="h4"
          component="div"
          style={{ marginBottom: "30px" }}
        >
          Search for the Transactions
        </Typography>

        <FormControl
          sx={{ m: 1, minWidth: "60%" }}
          // size="small"
          variant="outlined"
        >
          <OutlinedInput
            id="searchText"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Enter Mobile Number or Transaction Id"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={loading}
                  // onClick={onSubmit}
                  type="submit"
                  aria-label="toggle password visibility"
                  // onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {loading === false && <SearchIcon />}
                  <PulseLoader
                    color={"#00848A"}
                    loading={loading}
                    size={10}
                    speedMultiplier={0.5}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <br />
        <br />
        <img src="/image/transaction-bg.svg" width="65%" />
      </form>
    </Grid>
  );
};

export default Transactions;
