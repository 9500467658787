import React, { useState } from "react";
import OtpInput from "react-otp-input";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const useStyles = makeStyles((theme) => ({
  newInputStyle: {
    background: "none",
    minWidth: "80px",
    minHeight: "80px",
    fontSize: "24px",
    borderRadius: "3px",
    border: "1px solid green",
  },
  newFocusStyle: {
    // background: "red",
    minWidth: "80px",
    minHeight: "80px",
    borderRadius: "3px",
    border: "2px solid red",
    outline: "none !important",
  },
  contactParent: {
    height: "50px",
    width: "100%",
    background: "wheat",
    position: "absolute",
    top: "0px",
    // transition: "1s",
    background: "black",
  },
  main: {
    width: "50px",
    height: "50px",
    background: "green",
    borderRadius: "50%",
    margin: "auto",
    position: "relative",
  },
  contactStyle: {
    height: "50px",
    width: "20px",
    background: "wheat",
    position: "absolute",
    top: "0px",
    // visibility: "hidden",
    // display: "none",
    transition: "1s",

    // "&:hover": {
    //   top: "-50px",
    // },
  },
}));
const Test = () => {
  const classes = useStyles();
  const [myOTP, setMyOTP] = useState({ otp: "" });
console.log("build checking");
  const handleChange = (otp) => {
    setMyOTP({ otp });
    console.log("otp", otp);
    console.log("myOTP", myOTP.otp);
  };

  let testingStyle = {
    background: "none",
    minWidth: "80px",
    minHeight: "80px",
    borderRadius: "3px",
    border: "1px solid green",
  };
  let testingFocusStyle = {
    outline: "none !important",
    border: "5px solid #f9f9f9 !important",
    // boxShadow: "0 0 10px #719ECE",
    borderRadius: "30px",
  };
  let myDesign;
  let myDesign2;
  function myStopFunction() {
    console.log("myStopFunction");
    clearTimeout(myDesign);
    clearTimeout(myDesign2);
  }
  function myStopFunction2() {
    console.log("myStopFunction");

    clearTimeout(myDesign2);
  }
  const showAnimate = (id, arrow) => {
    console.log("showAnimate");
    document.getElementById(id).style.visibility = "visible";
    // document.getElementById(id).style.display = "block";
    document.getElementById(id).style.background = "#fff";
    document.getElementById(id).style.top = "-70px";
    myDesign = setTimeout(() => {
      document.getElementById(id).style.borderRadius = "5px";
      document.getElementById(id).style.width = "200px";
    }, 1000);

    myDesign2 = setTimeout(() => {
      console.log(arrow);
      document.getElementById(arrow).style.visibility = "visible";
      document.getElementById(arrow).style.bottom = "-30px";
    }, 1500);
  };
  const hideAnimate = (id, arrow) => {
    console.log("hideAnimate");
    myStopFunction();
    myStopFunction2();
    // ======================   Contact Arrow   =================

    document.getElementById(arrow).style.visibility = "hidden";
    document.getElementById(arrow).style.bottom = "-25px";
    // ======================   Contact Child   =================

    document.getElementById(id).style.visibility = "hidden";
    document.getElementById(id).style.width = "50px";
    document.getElementById(id).style.borderRadius = "50%";
    document.getElementById(id).style.top = "0px";
  };
  return (
    <>
      <div>
        <OtpInput
          value={myOTP.otp}
          onChange={handleChange}
          numInputs={6}
          separator={<span>&nbsp; &nbsp; &nbsp;</span>}
          isInputNum={true}
          shouldAutoFocus={true}
          // containerStyle={testingStyle}
          inputStyle={classes.newInputStyle}
          focusStyle={classes.newFocusStyle}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Grid container sx={{ background: "red" }}>
        <Grid item xs={4}>
          <div className={classes.main}>
            <div
              className={classes.contactStyle}
              id="contactChild"
           
            >
              <ArrowDropDownIcon
                id="contactArrow"
                style={{
                  color: "#fff",
                  fontSize: "60px",
                  position: "absolute",
                  bottom: "-25px",
                  left: "-7px",
                  visibility: "hidden",
                  transition: "1s",
                }}
              />
            </div>

            <div
              onMouseOver={() => showAnimate("contactChild", "contactArrow")}
              onMouseLeave={() => hideAnimate("contactChild", "contactArrow")}
              className={classes.contactStyle}
              style={{
                width: "50px",
                height: "50px",
                background: "blue",
                borderRadius: "50%",
              }}
            ></div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              width: "50px",
              height: "50px",
              background: "green",
              borderRadius: "50%",
              margin: "auto",
            }}
          ></div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              width: "50px",
              height: "50px",
              background: "green",
              borderRadius: "50%",
              margin: "auto",
            }}
          ></div>
        </Grid>
      </Grid>
      <div
        style={{ position: "relative", height: "50px", border: "1px solid" }}
      >
        {/* <div className={classes.contactChild} id="contactChild"></div> */}
        <div className={classes.contactParent}></div>
      </div>
    </>
  );
};

export default Test;
