import React, { createContext, useReducer, useEffect } from "react";

import authReducer from "./authReducer";

export const AuthContext = createContext();

export default function AuthContextProvider(props) {
  const [fastpay_support_panel_user, dispatch] = useReducer(
    authReducer,
    {},
    () => {
      const localData = localStorage.getItem("fastpay_support_panel_user");
      return localData ? JSON.parse(localData) : {};
    }
  );

  const login = async (data) => {
    dispatch({
      type: "LOGIN",
      payload: data,
    });
  };

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: {},
    });
  };

  useEffect(() => {
    localStorage.setItem(
      "fastpay_support_panel_user",
      JSON.stringify(fastpay_support_panel_user)
    );
  }, [login]);
  

  return (
    <AuthContext.Provider value={{ login, fastpay_support_panel_user, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
}
