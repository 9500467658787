import React, { useState, useEffect } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSnackbar } from "notistack";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 6px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const KYCRequest = ({ rollPermission }) => {
  const classes = useStyles();
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [docId, setDocId] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [docList, setDocList] = useState([]);
  const [docTypeId, setDocTypeId] = useState("");
  const [status, setStatus] = useState("");
  const [kybRequestList, setKybRequestList] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          {rollPermission?.includes("KYC request details") && (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          )}
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setMobileNumber("");
    setEmail("");
    setDocTypeId("");
    setDocId("");
    setStatus("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);
    let newUrl = `api/kyc-info`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newStartingTime = "";
        let newEndingTime = "";
        if (startingTime !== null) {
          newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
        }
        if (endingTime !== null) {
          newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
        }

        let newdocTypeId = docTypeId;
        let newStatus = status;
        if (status === "None") {
          newStatus = "";
        }
        if (docTypeId === "None") {
          newdocTypeId = "";
        }
        url = `api/kyc-info?mobile_number=${encodeURIComponent(
          mobileNumber.trim()
        )}&status=${newStatus}&email=${encodeURIComponent(
          email.trim()
        )}&document_id=${docId.trim()}&document_type_id=${newdocTypeId}&starting_time=${encodeURIComponent(
          newStartingTime
        )}&ending_time=${encodeURIComponent(newEndingTime)}&page=${newPageNO}`;
      }
      let kybRequests = await getDataWithToken(url);
      if (kybRequests.data.code === 200) {
        setKybRequestList(kybRequests.data.data.data);
        setTotalData(kybRequests.data.data.total);
        if (kybRequests.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(kybRequests.data.messages.toString());
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };

  const getDropdownData = async () => {
    let allDocType = await getDataWithToken(`api/verification-doc-type-list`);
    if (allDocType.status === 200) {
      setDocList(allDocType.data.data);
    } else {
      handleSnakbarOpen(allDocType.data.messages.toString(), "error");
    }
  };
  useEffect(() => {
    getData();
    getDropdownData();
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={9} xl={9}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            KYC Requests
          </Typography>
        </Grid>
        <Grid item lg={3} xl={3} style={{ textAlign: "right" }}>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  id="mobile-no"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Mobile No."
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Doc Type
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Doc Type"
                    value={docTypeId}
                    onChange={(e) => setDocTypeId(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    {docList?.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Doc ID"
                  value={docId}
                  onChange={(e) => setDocId(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  style={{ minWidth: "250px" }}
                  size="small"
                  fullWidth
                >
                  <InputLabel id="demo-status-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="0">Pending</MenuItem>
                    <MenuItem value="1">Approved</MenuItem>
                    <MenuItem value="2">Awaiting Approval</MenuItem>
                    <MenuItem value="9">Declined</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Starting Time"
                    value={startingTime}
                    onChange={(newValue) => {
                      setStartingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Ending Time"
                    value={endingTime}
                    onChange={(newValue) => {
                      setEndingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {/* <Grid item xs={8}></Grid> */}

              <Grid item xs={3}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell colSpan={2} style={{ minWidth: "200px" }}>
                  Basic Info
                </TableCell>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Level
                </TableCell>
                <TableCell style={{ minWidth: "120px" }}>Email</TableCell>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Doc Type
                </TableCell>
                <TableCell align="center" style={{ minWidth: "95px" }}>
                  Doc ID
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  Registration Date
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {" "}
                  Status
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {" "}
                  Remarks
                </TableCell>
                {rollPermission?.includes("KYC request details") && (
                  <TableCell align="center" style={{ minWidth: "90px" }}>
                    {" "}
                    View Details
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                kybRequestList.length > 0 &&
                kybRequestList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ paddingRight: 0, width: "50px" }}>
                      <Avatar
                        alt=""
                        src={row.avatar}
                        sx={{ width: 45, height: 45 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ fontSize: "16px", fontWeight: 500 }}
                      >
                        {row.name}
                      </Typography>
                      {row.mobile_number}
                    </TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">{row.level}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="center">{row.document_type}</TableCell>
                    <TableCell align="center">{row.document_id}</TableCell>
                    <TableCell align="center">{row.uploaded_at}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {row.status === "Verified" ? (
                          <img
                            src="/image/verified.svg"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        ) : row.status === "Pending" ? (
                          <img
                            src="/image/unverified.svg"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        ) : row.status === "Awaiting Approval" ? (
                          <img
                            src="/image/unverified.svg"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        ) : row.status === "Declined" ? (
                          <HighlightOffIcon color="error" />
                        ) : (
                          ""
                        )}
                        &nbsp; {row.status}
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.reason}</TableCell>
                    {rollPermission?.includes("KYC request details") && (
                      <TableCell align="center">
                        <IconButton
                          component={Link}
                          to={`/kyc-detail/${row.id}`}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}

              {!loading && kybRequestList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {kybRequestList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              // count={rows.length}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </TableContainer>
  );
};

export default KYCRequest;
