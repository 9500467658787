import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PulseLoader from "react-spinners/PulseLoader";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },

    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
      fontWeight: 600,
    },
  },
  codeBlockStyle: {
    "& button": {
      display: "none",
    },
    maxWidth: "350px",
    position: "relative",
  },
}));

const CustomerActivityLog = ({
  basicInformation,
  userId,
  userInfoMessage,
  rollPermission,
}) => {
  console.log("basicInformation", basicInformation);
  const classes = useStyles();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [ip, setIp] = useState("");
  const [customerActivityLog, setCustomerActivityLog] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [order, setOrder] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [downloadloading, setDownloadloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setIp("");
    setStartingTime(null);
    setEndingTime(null);
    setPage(0);
    setOrder(true);
    let newData = {
      user_id: userId,
      ip: "",
      from: "",
      to: "",
      order_by_desc: 1,
      page: 1,
    };
    getData("", newData);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };
  const handleOrderChange = (orderBy) => {
    setOrder(orderBy);
    setPage(0);
    let newOrderBy = 1;
    let newStartingTime = "";
    let newEndingTime = "";
    if (orderBy === false) {
      newOrderBy = "";
    }
    if (startingTime !== null) {
      newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (endingTime !== null) {
      newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
    }
    let newData = {
      user_id: userId,
      ip: ip,
      from: newStartingTime,
      to: newEndingTime,
      order_by_desc: newOrderBy,
      page: 1,
    };
    getData("", newData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newData) => {
    if (userId === undefined) {
      setMessage(userInfoMessage);
      setLoading(false);
    } else {
      try {
        setLoading(true);
        let newPageNO;
        let data;
        if (pageNO) {
          newPageNO = pageNO;
        } else {
          newPageNO = 1;
        }
        if (newData) {
          data = newData;
        } else {
          let newOrderBy = 1;
          let newStartingTime = "";
          let newEndingTime = "";
          if (order === false) {
            newOrderBy = "";
          }
          if (startingTime !== null) {
            newStartingTime = moment(startingTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          if (endingTime !== null) {
            newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
          }

          data = {
            user_id: userId,
            ip: ip,
            from: newStartingTime,
            to: newEndingTime,
            order_by_desc: newOrderBy,
            page: newPageNO,
          };
        }

        let allCustomerActivityLog = await axios({
          url: "api/customer-activity-log-data",
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });

        if (allCustomerActivityLog.status === 200) {
          setCustomerActivityLog(allCustomerActivityLog.data.data.data);
          setTotalData(allCustomerActivityLog.data.data.total);
          setRowsPerPage(allCustomerActivityLog.data.data.per_page);
          if (allCustomerActivityLog.data.data.data.length < 1) {
            setMessage("No data found");
          }
        } else {
          setMessage(allCustomerActivityLog.data.messages.toString());
          if (allCustomerActivityLog.data.messages.length < 1) {
            setMessage("Something went wrong");
          }
        }
      } catch (error) {
        console.log("error", error);

        setMessage(error.response.data.messages.toString());
        setLoading(false);
      }

      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadFile = async (type) => {
    setDownloadloading(true);
    try {
      let data;
      let newOrderBy = 1;
      let newStartingTime = "";
      let newEndingTime = "";
      if (order === false) {
        newOrderBy = "";
      }
      if (startingTime !== null) {
        newStartingTime = moment(startingTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (endingTime !== null) {
        newEndingTime = moment(endingTime).format("YYYY-MM-DD HH:mm:ss");
      }

      data = {
        user_id: userId,
        ip: ip,
        from: newStartingTime,
        to: newEndingTime,
        order_by_desc: newOrderBy,
        download: "excel",
        download_type: type,
      };

      let activityLog = await axios({
        url: `api/customer-activity-log-data`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
        responseType: "blob", // important
      });

      if (activityLog.status === 200) {
        const url = window.URL.createObjectURL(new Blob([activityLog.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute(
          "download",
          `Customer(${basicInformation.mobile_number})-Activity-Log.${type}`
        );

        document.body.appendChild(link);
        link.click();

        link.remove();

        handleClose();

        handleSnakbarOpen("File download successfully", "success");
      } else {
        handleSnakbarOpen("Something went wrong", "error");
        handleClose();
      }
      setDownloadloading(false);
    } catch (error) {
      const isJsonBlob = (data) =>
        data instanceof Blob && data.type === "application/json";
      const responseData = isJsonBlob(error.response?.data)
        ? await error.response?.data?.text()
        : error.response?.data || {};
      const responseJson =
        typeof responseData === "string"
          ? JSON.parse(responseData)
          : responseData;
      console.log("error", responseJson);
      handleSnakbarOpen(responseJson?.messages.toString(), "error");
      handleClose();
      setDownloadloading(false);
    }
    setDownloadloading(false);
  };

  const showFilterButtons = () => {
    if (rollPermission?.includes("Download customer activity log")) {
      return (
        // md={4} lg={3.5}
        <Grid item md={3.6} lg={3.6} xl={3}>
          <Grid container>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                // fullWidth
                onClick={(event) => clearFilter(event, 0)}
              >
                <RestartAltIcon />
              </Button>
            </Grid>

            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                disableElevation
                // color="success"
                style={{
                  background: "#435185",
                  padding: "6.7px 22px",
                  zIndex: 1,
                }}
                size="large"
                startIcon={<SearchIcon />}
                onClick={(event) => handleChangePage(event, 0)}
                fullWidth
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <div>
                <Tooltip title="Download File" placement="top">
                  <Button
                    variant="contained"
                    color="success"
                    disableElevation
                    size="large"
                    id="basic-button"
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    disabled={downloadloading}
                    onClick={handleClick}
                    style={{ maxHeight: "40px" }}
                    // fullWidth
                  >
                    {downloadloading === false && <DownloadIcon />}
                    <PulseLoader
                      color={"#00848A"}
                      loading={downloadloading}
                      size={5}
                      speedMultiplier={0.5}
                    />{" "}
                  </Button>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      downloadFile("xlsx");
                    }}
                  >
                    Export Excel File
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      downloadFile("csv");
                    }}
                  >
                    Export CSV File
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item md={3.6} lg={3.6} xl={3}>
          <Grid container spacing={{ md: 4, lg: 4, xl: 1 }}>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                fullWidth
                onClick={(event) => clearFilter(event, 0)}
              >
                <RestartAltIcon />
              </Button>
            </Grid>

            <Grid item xs={9}>
              <Button
                fullWidth
                variant="contained"
                disableElevation
                // color="success"
                style={{ background: "#435185", padding: "6.7px 22px" }}
                size="large"
                startIcon={<SearchIcon />}
                onClick={(event) => handleChangePage(event, 0)}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Grid container columnSpacing={3} style={{ padding: "0 0 16px 0" }}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item md={2.8} lg={2.8} xl={3}>
              <TextField
                id="ip"
                fullWidth
                size="small"
                variant="outlined"
                label="IP"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
              />
            </Grid>

            <Grid item md={2.8} lg={2.8} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Starting Time"
                  value={startingTime}
                  onChange={(newValue) => {
                    setStartingTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={2.8} lg={2.8} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Ending Time"
                  value={endingTime}
                  onChange={(newValue) => {
                    setEndingTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            {/* <Grid item xs={3}>
              <Grid container spacing={{ lg: 6, xl: 3 }}>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={(event) => clearFilter(event, 0)}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    disableElevation
                    style={{ background: "#435185", padding: "6.7px 22px" }}
                    size="large"
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={(event) => handleChangePage(event, 0)}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
            {showFilterButtons()}
          </Grid>
        </Grid>
      </Grid>

      <Paper>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className={classes.tableHeadStyle}>
            <TableRow>
              <TableCell style={{ minWidth: "140px" }}>Description</TableCell>
              <TableCell style={{ width: "140px" }}>IP Address</TableCell>
              <TableCell style={{ width: "180px" }}>
                Performed At
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOrderChange(!order)}
                >
                  {order ? (
                    <KeyboardArrowUpIcon style={{ color: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBodyStyle}>
            {!loading &&
              customerActivityLog.length > 0 &&
              customerActivityLog.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {row.ip !== null ? row.ip : <p>-----</p>}
                  </TableCell>
                  <TableCell>{row.created_at}</TableCell>
                </TableRow>
              ))}

            {!loading && customerActivityLog.length < 1 ? (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                  <strong> {message}</strong>
                </TableCell>
              </TableRow>
            ) : null}
            {loading && pageLoading()}
          </TableBody>
        </Table>

        {customerActivityLog.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </>
  );
};

export default CustomerActivityLog;
