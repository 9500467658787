import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td:first-child": {
      padding: "10px 16px",
    },
    "& td": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& td": {
    //     paddingTop: "12px",
    //     paddingBottom: "12px",
    //     padding: "12px 6px",
    //   },
    //   "& td:nth-child(n+3)": {
    //     paddingLeft: "0px",
    //   },
    // },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
}));

const RoleList = ({ rollPermission }) => {
  const classes = useStyles();
  const [roleName, setRoleName] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [roleListForDropdown, setRoleListForDropdown] = useState([]);
  const [roleListForDropdownMsg, setRoleListForDropdownMsg] = useState("");
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          {rollPermission?.includes("Update role") && (
            <TableCell>
              <Skeleton></Skeleton>
            </TableCell>
          )}
        </TableRow>
      );
    }
    return content;
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    setLoading(true);
    let newPageNO;
    let url;
    if (pageNO) {
      newPageNO = pageNO;
    } else {
      newPageNO = 1;
    }
    if (newUrl) {
      url = newUrl;
    } else {
      let newRoleName = roleName;
      if (roleName === "None") {
        newRoleName = "";
      }
      // url = `api/user-block-list?mobile_number=${encodeURIComponent(
      //   mobileNumber.trim()
      // )}&feature=${newFeature}&page=${newPageNO}`;
      url = `api/role-list?role_name=${encodeURIComponent(
        newRoleName
      )}&page=${newPageNO}`;
    }
    let allRoles = await getDataWithToken(url);
    if (allRoles.status === 200) {
      setRoleList(allRoles.data.data.data);
      setTotalData(allRoles.data.data.total);
      setRowsPerPage(allRoles.data.data.per_page);
      if (allRoles.data.data.data.length < 1) {
        setMessage("No data found");
      }
    } else {
      setMessage(allRoles.data.messages.toString());
      if (allRoles.data.messages.length < 1) {
        setMessage("Something went wrong");
      }
    }

    setLoading(false);
  };

  const getDropdownData = async () => {
    let roles = await getDataWithToken(`api/role-list`);
    if (roles.status === 200) {
      setRoleListForDropdown(roles.data.data.data);
    } else {
      setRoleListForDropdownMsg(roles.data.messages.toString());
      if (roles.data.messages.length < 1) {
        setRoleListForDropdownMsg("Something went wrong");
      }
    }
  };
  useEffect(() => {
    getData();
  }, [roleName]);
  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={4} xl={4}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              Role List
            </Typography>
          </Grid>
          <Grid item lg={4} xl={4}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="demo-status-outlined-label">
                Select Role Name
              </InputLabel>
              <Select
                labelId="demo-status-outlined-label"
                id="demo-status-outlined"
                label="Select Role Name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              >
                <MenuItem value="None">
                  {roleListForDropdownMsg.length > 0 ? (
                    <>{roleListForDropdownMsg}</>
                  ) : (
                    "None"
                  )}
                </MenuItem>

                {roleListForDropdown?.map((item, i) => (
                  <MenuItem key={i} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} xl={4} style={{ textAlign: "right" }}>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              color="info"
              startIcon={<AddIcon />}
              component={Link}
              to="/create-role"
            >
              Create Role
            </Button>
          </Grid>

          <br />
          {/* <Grid item xs={12}>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Select Role Name
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Select Role Name"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  >
                    <MenuItem value="None">
                      {roleListForDropdownMsg.length > 0 ? (
                        <>{roleListForDropdownMsg}</>
                      ) : (
                        "None"
                      )}
                    </MenuItem>

                    {roleListForDropdown?.map((item, i) => (
                      <MenuItem key={i} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

             
            </Grid>
          </Grid> */}
        </Grid>

        <Paper>
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 370px)",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Total User</TableCell>
                  <TableCell align="center">Total Permission</TableCell>
                  {rollPermission?.includes("Update role") && (
                    <TableCell align="center">Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!loading &&
                  roleList.length > 0 &&
                  roleList.map((row, i) => (
                    <TableRow
                      key={i}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {/* <TableCell style={{ paddingRight: 0, width: "30px" }}>
                      
                        <PersonIcon color="info" fontSize="large" />
                      </TableCell> */}
                      <TableCell>
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ fontSize: "16px", fontWeight: 500 }}
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{row.total_user}</TableCell>
                      <TableCell align="center">
                        {row.total_permission}
                      </TableCell>
                      {rollPermission?.includes("Update role") && (
                        <TableCell align="center">
                          <IconButton
                            variant="contained"
                            // color="success"
                            disableElevation
                            component={Link}
                            to={`/update-role/${row.id}`}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                {!loading && roleList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
          {roleList.length > 0 && (
            <div>
              <TablePagination
                style={{ display: "block", borderBottom: "none" }}
                rowsPerPageOptions={[]}
                // count={rows.length}
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </TableContainer>
    </>
  );
};

export default RoleList;
