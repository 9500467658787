import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  centerSelectStyle: {
    [`& .MuiInputLabel-outlined.MuiInputLabel-shrink`]: {
      display: "none",
    },
  },
  tableBodyStyle: {
    [theme.breakpoints.down("xl")]: {
      "& td": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
}));

const EKYCAccountDetails = ({ accountDetails, loading, userInfoMessage }) => {
  const classes = useStyles();
  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 4; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  return (
    <div>
      {userInfoMessage.length > 0 && (
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 600, textAlign: "center" }}
          component="div"
        >
          {userInfoMessage}
        </Typography>
      )}{" "}
      <Table aria-label="simple table" style={{ border: "1px solid #dcdde1" }}>
        <TableBody className={classes.tableBodyStyle}>
          {loading && pageLoading()}
          {!loading &&
            accountDetails &&
            Object.entries(accountDetails).length !== 0 && (
              <>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">Account Type</TableCell>
                  <TableCell>{accountDetails?.account_type}</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">Balance</TableCell>
                  <TableCell>{accountDetails?.balance}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">Account No.</TableCell>
                  <TableCell>{accountDetails?.account_no}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">Account Status</TableCell>
                  <TableCell>
                    <img
                      src={
                        accountDetails?.account_status === 1
                          ? "/image/verified.svg"
                          : "/image/unverified.svg"
                      }
                      style={{ width: "20px", position: "relative", top: 5 }}
                      alt=""
                    />
                    &nbsp;
                    {accountDetails?.account_status === 1
                      ? "Verified"
                      : "Unverified"}
                  </TableCell>
                </TableRow>
              </>
            )}
        </TableBody>
      </Table>
    </div>
  );
};

export default EKYCAccountDetails;
