import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddIcon from "@mui/icons-material/Add";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 16px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 16px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
  },
}));

const AdjustmentList = () => {
  const classes = useStyles();
  const location = useLocation();
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [filterId, setFilterId] = useState("");
  const [status, setStatus] = useState("");
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [txId, setTxId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [successStartingTime, setSuccessStartingTime] = useState(null);
  const [successEndTime, setSuccessEndTime] = useState(null);
  const [faliedStartTime, setFaliedStartTime] = useState(null);
  const [faliedEndTime, setFaliedEndTime] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [createOpen, setCreateOpen] = useState(false);

  const handleClickOpen = () => {
    setCreateOpen(true);
  };

  const handleClose = () => {
    setTxId("");
    setRemarks("");
    setCreateOpen(false);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };
  const validation = () => {
    let isError = false;

    if (!txId) {
      handleSnakbarOpen("Please enter id", "error");
      document.getElementById("tx_Id").focus();

      return (isError = true);
    }
    if (!remarks) {
      handleSnakbarOpen("Please enter a remarks", "error");
      document.getElementById("remarks").focus();
      return (isError = true);
    }

    return isError;
  };
  const onSubmit = async () => {
    let err = validation();

    if (err) {
      return;
    } else {
      setPostLoading(true);
      try {
        const formdata = new FormData();
        formdata.append("tx_unique_id", txId);
        formdata.append("remarks", remarks);

        let response = await axios({
          url: "api/adjustment-store",
          method: "post",
          data: formdata,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        handleSnakbarOpen(response.data.messages.toString(), "success");
        getData(1);
        handleClose();
      } catch (error) {
        console.log("error", error);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        setPostLoading(false);
      }
      setPostLoading(false);
    }
  };
  const clearFilter = (event) => {
    setFilterId("");
    setStatus("");
    setSuccessStartingTime(null);
    setSuccessEndTime(null);
    setFaliedStartTime(null);
    setFaliedEndTime(null);
    setPage(0);
    let newUrl = `api/customer-list`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO) => {
    try {
      setLoading(true);
      let newPageNO;

      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      let url = `api/adjustment-list?page=${newPageNO}`;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }

      let allUser = await getDataWithToken(url);
      // console.log("allUser", allUser);
      if (allUser.status === 200) {
        setUserList(allUser?.data?.data?.data);
        setRowsPerPage(allUser?.data?.data?.per_page);
        setTotalData(allUser?.data?.data?.total);
        if (allUser.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(allUser.data.messages.toString());
        if (allUser.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <TableContainer
      component={Paper}
      style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
    >
      <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
        <Grid item lg={6} xl={6}>
          <Typography variant="h4" color="info" gutterBottom component="div">
            Adjustment List
          </Typography>
        </Grid>
        <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="info"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Create New
          </Button>
          {/* &nbsp;&nbsp;&nbsp;
          <Button
            disableElevation
            variant="outlined"
            size="large"
            color="success"
            // startIcon={<FilterListIcon />}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterListOffIcon /> : <FilterListIcon />}
          </Button> */}
        </Grid>
        <br />
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  id="txId"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="ID."
                  value={filterId}
                  onChange={(e) => setFilterId(e.target.value)}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-status-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-status-outlined-label"
                    id="demo-status-outlined"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value={1}>Success</MenuItem>
                    <MenuItem value={0}>Pending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Success Starting Time"
                    value={successStartingTime}
                    onChange={(newValue) => {
                      setSuccessStartingTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Success Ending Time"
                    value={successEndTime}
                    onChange={(newValue) => {
                      setSuccessEndTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Failed Starting Time"
                    value={faliedStartTime}
                    onChange={(newValue) => {
                      setFaliedStartTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" fullWidth />
                    )}
                    label="Failed Ending Time"
                    value={faliedEndTime}
                    onChange={(newValue) => {
                      setFaliedEndTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={{ lg: 6, xl: 3 }}>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      fullWidth
                      onClick={(event) => clearFilter(event, 0)}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      disableElevation
                      // color="success"
                      style={{ background: "#435185", padding: "6.7px 22px" }}
                      size="large"
                      startIcon={<SearchIcon />}
                      fullWidth
                      onClick={(event) => handleChangePage(event, 0)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Paper>
        <div
          style={{
            overflowX: "auto",
            minWidth: "100%",
            width: "Calc(100vw - 370px)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHeadStyle}>
              <TableRow>
                <TableCell style={{ minWidth: "80px" }}>
                  TRX Unique ID
                </TableCell>

                <TableCell align="center" style={{ minWidth: "90px" }}>
                  Status
                </TableCell>

                <TableCell align="center" style={{ minWidth: "90px" }}>
                  Succeed At
                </TableCell>
                <TableCell align="center" style={{ minWidth: "90px" }}>
                  Failed At
                </TableCell>
                <TableCell align="center" style={{ minWidth: "90px" }}>
                  Created At
                </TableCell>
                <TableCell style={{ minWidth: "10px" }}>Remarks</TableCell>
                <TableCell style={{ minWidth: "100px" }}>
                  Failed Reason
                </TableCell>
                {/* <TableCell align="center" style={{ minWidth: "90px" }}>
                  View Details
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyStyle}>
              {!loading &&
                userList.length > 0 &&
                userList.map((row, i) => (
                  <TableRow
                    key={i}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row?.tx_unique_id}</TableCell>

                    <TableCell align="center">
                      <img
                        src={
                          row.status === 1
                            ? "/image/verified.svg"
                            : "/image/unverified.svg"
                        }
                        alt=""
                        height="20px"
                        style={{ position: "relative", top: 5 }}
                      />{" "}
                      &nbsp;
                      {row.status === 1 ? (
                        <span style={{ color: "rgba(129,199,132,1)" }}>
                          Success
                        </span>
                      ) : (
                        <span style={{ color: "rgba(211, 47, 47,1)" }}>
                          Pending
                        </span>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.succeed_at !== null ? row.succeed_at : "-----"}
                    </TableCell>
                    <TableCell align="center">
                      {row.failed_at !== null ? row.failed_at : "-----"}
                    </TableCell>
                    <TableCell align="center">
                      {row.created_at !== null ? row.created_at : "-----"}
                    </TableCell>
                    <TableCell>
                      {row?.remarks !== null ? row.remarks : "-----"}
                    </TableCell>
                    <TableCell>
                      {row?.failed_reason !== null
                        ? row.failed_reason
                        : "-----"}
                    </TableCell>
                  </TableRow>
                ))}

              {!loading && userList.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    <strong> {message}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {loading && pageLoading()}
            </TableBody>
          </Table>
        </div>
        {userList.length > 0 && (
          <div>
            <TablePagination
              style={{ display: "block", borderBottom: "none" }}
              rowsPerPageOptions={[]}
              // count={rows.length}
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
      <Dialog
        open={createOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Create New"}</DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: "10px" }}
            id="tx_Id"
            fullWidth
            size="small"
            variant="outlined"
            label="TRX Unique ID"
            value={txId}
            onChange={(e) => setTxId(e.target.value)}
          />
          <TextField
            style={{ marginTop: "20px" }}
            id="remarks"
            fullWidth
            size="small"
            variant="outlined"
            label="Remarks"
            multiline
            rows={3}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ padding: "0px 24px 24px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button
            onClick={onSubmit}
            autoFocus
            variant="contained"
            style={{ minWidth: "150px" }}
          >
            Submit
          </Button> */}
          <Button
            variant="contained"
            disableElevation
            style={{ minWidth: "150px" }}
            disabled={postLoading}
            onClick={onSubmit}
            // type="submit"
          >
            {postLoading === false && "Submit"}
            <PulseLoader
              color={"#00848A"}
              loading={postLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default AdjustmentList;
